import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';

import { getPaymentsForOrder } from '../../../services/paymentService.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GetDisplayDateStr, IsFalse, IsTrue } from '../../../js/formHelpers.js';
import authService from '../../../services/authService.js';
import { getCardMachineForUser } from '../../../services/paymentService.js'; 
import { AdjustModal } from '../_modals/AdjustModal';


export function ReceiptsModal({ isOpen, onClose, orderId, orderNum }) {
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAdjustModal, setShowAdjustModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [user, setUser] = useState(null);
    const [cardMachines, setCardMachines] = useState([]);
    
    const valueFormatter = (params) => {
        return params.value != null && params.value !== '' ? params.value : '--';
    };

    const amountFormatter = (params) => {
        return params.value != null ? `$${params.value.toFixed(2)}` : '--';
    };

    const fetchUserData = async () => {
        const userData = authService.getCurrentUser(); 
        setUser(userData);
    }

    const fetchCardMachines = async () => {
        setCardMachines(getCardMachineForUser(user.userId));
    }

    const columnDefs = [
        { headerName: 'Date', field: 'paymentDate', flex: .8, valueFormatter: params => GetDisplayDateStr(params.value) },
        { headerName: 'Name', field: 'paymentName', flex: 1.5, valueFormatter: valueFormatter },
        {
            headerName: 'Type', field: 'paymentType', flex: .6, valueFormatter: params => {
                if (!params.value) return '--';
                return params.value === 'Card' ? 'MC/Visa' : params.value;
            }
        },
        { headerName: 'Last 4 of Card', field: 'last4Digits', flex: .9, valueFormatter: valueFormatter },
        { headerName: 'Check #', field: 'checkNumber', flex: .7, valueFormatter: valueFormatter },
        { headerName: 'Processed By', field: 'processedBy', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Amount', field: 'amount', flex: 1, valueFormatter: amountFormatter },
        { headerName: 'Orders Paid', field: 'ordersPaid', flex: 1, valueFormatter: valueFormatter },
        {
            headerName: '', flex: 1.2,
            className: 'flex gap-1 items-end justify-end',
            cellRenderer: params => {
                return (
                    <div className="flex gap-1 items-end justify-end">
                        {/*ADJUST*/}
                        {IsTrue(params.data.allowAdjustPayment) && IsFalse(params.data.isReversed) && IsFalse(params.data.isVoided) &&
                            <Button
                                size="sm"
                                className="btn btn-sm text-[14px] bg-[#538235] text-white cursor-pointer !p-2"
                                onClick={() => {
                                    setSelectedPayment(params.data);
                                    setShowAdjustModal(true);
                                }}>
                                Adjust
                            </Button>
                        }
                        {/*PRINT*/}
                        <Button size="sm" className="btn-small items-end justify-end"
                            onClick={() => downloadReceiptPDF(params.data.paymentDetailId)}>
                            Print
                        </Button>
                    </div>
                )
            },
        }
    ];

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: (params) => params.value,
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onQuickFilterChanged = useCallback((event) => {
        setQuickFilterText(event.target.value);
        gridApi?.setQuickFilter(event.target.value);
    }, [gridApi]);

    async function fetchData() {
        try {
            setIsLoading(true);
            fetchUserData();
            fetchCardMachines();
            const response = await getPaymentsForOrder(orderId);
            setRowData(response);
        } catch (error) {
            console.error('Error fetching payment receipts:', error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getPaymentsForOrder(orderId);
                setRowData(response);
            } catch (error) {
                console.error('Error fetching payment receipts:', error);
            }
            setIsLoading(false);
        }

        if (isOpen) {
            fetchData();
        }
    }, [orderId, isOpen]);

    //const CustomTooltip = (props) => {
    //    return (
    //        <div className="custom-tooltip bg-white p-2 shadow-lg rounded">
    //            {props.value && props.value !== '--' ? props.value : 'No data available'}
    //        </div>
    //    );
    //};

    const downloadReceiptPDF = async (paymentDetailId) => {
        try {
            setIsLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL + 'payments/GetPaymentReceipt/' + paymentDetailId;
            //console.log('API URL: ' + apiUrl);

            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Receipt-${paymentDetailId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error downloading PDF:', error);
        }
    };

    const onRowDoubleClicked = (params) => {
        downloadReceiptPDF(params.data.paymentDetailId);
    };

    return (
        <>
            <Modal open={isOpen} className={`w-11/12 max-w-[1400px] ${showAdjustModal ? 'min-h-[400px]' : 'h-auto'} p-4 pt-2`}>
                <div style={{ padding: "20px", position: "relative" }}>
                {isLoading && ( 
                  <div className="loading loading-spinner loading-lg text-primary"></div>
                )}
                <div className="flex justify-between items-center pb-2">
                    <Modal.Header className="font-bold m-0">
                        Receipts - Order #{orderNum}
                    </Modal.Header>
                    <Button size="sm" color="ghost" shape="circle" onClick={e => onClose(e)}
                            className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                    >x</Button>
                </div>
                <Modal.Body className="p-0">
                    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                        <AgGridReact
                          columnDefs={columnDefs}
                          rowData={rowData}
                          defaultColDef={defaultColDef}
                          onGridReady={onGridReady}
                          //quickFilterText={quickFilterText}
                          //pagination={true}
                          //paginationPageSize={30}
                          domLayout='autoHeight'
                          tooltipShowDelay={0}
                          tooltipHideDelay={2000}
                          //components={{
                          //customTooltip: CustomTooltip,
                          //}}
                          onRowDoubleClicked={onRowDoubleClicked}
                        />
                    </div>
                </Modal.Body>
            </div>

            {/*Adjust Modal*/}
            {showAdjustModal && (
                <AdjustModal
                    isOpen={showAdjustModal}
                    onClose={(success) => {
                        if (success) {
                            fetchData();
                            setShowAdjustModal(false);
                        }
                    }}
                    paymentDetailId={selectedPayment.paymentDetailId}
                />
            )}
            </Modal>
        </>
    );
}