import React, { useState, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GetDisplayCurrencyStr, GetDisplayDateStr, NotStringEmpty } from '../../../js/formHelpers';

export function BillsList({ bills, handleSelectedRowsChange }) {

    const gridRef = useRef();
    const columnDefs = [
        {
            headerName: '', maxWidth: 50,
            headerCheckboxSelection: true, checkboxSelection: true,
        },
        {
            headerName: 'Vendor', field: '',
            cellRenderer: params => {
                var name = params.data.payee;
                if (NotStringEmpty(params.data.vendorName)) {
                    name = params.data.vendorName;
                }
                else if (NotStringEmpty(params.data.accountName)){
                    name = params.data.accountName;
                }
                return <span>{name}</span>;
            }
        },
        {
            headerName: 'Vendor Type', field: 'billTypeDesc', maxWidth: 200,
        },
        {
            headerName: 'Invoice #', field: 'billNumber', maxWidth: 200,
        },
        {
            headerName: 'Invoice Date', field: 'billDate', maxWidth: 150,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Due Date', field: 'dueDate', maxWidth: 150,
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Amount', field: 'adjustedTotal', maxWidth: 200,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Status', field: 'billStatusDesc', maxWidth: 200,
        },
    ];

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    useEffect(() => {
        //const updateGridHeight = () => {
        //    const windowHeight = window.innerHeight;
        //    const offsetTop = document.querySelector('.ag-theme-alpine')?.offsetTop || 0;
        //    const newHeight = windowHeight - offsetTop - 20; // 20px for some bottom margin
        //    setGridHeight(`${newHeight}px`);
        //};

        //updateGridHeight();
        //window.addEventListener('resize', updateGridHeight);
        //return () => window.removeEventListener('resize', updateGridHeight);
    }, []);


    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        handleSelectedRowsChange(selectedRows);
    }

    return (
        <div className="ag-theme-alpine w-full" style={{ height: '600px' }}>
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={bills}
                gridOptions={gridOptions}          
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationPageSize={20}
            />
        </div>
    );
}