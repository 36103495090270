import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { SetPageTitle } from '../../js/helpers.js';
import { Exists, NotEmpty } from '../../js/formHelpers.js';
import { get } from '../../services/apiService.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css

export function UsersGrid() {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";
    const [showInactive, setShowInactive] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        { headerName: "Id", field: "userId", hide: true },
        {
            headerName: 'Username', field: 'userName',
        },
        {
            headerName: 'First Name', field: 'firstName',
        },
        {
            headerName: "Last Name", field: "lastName",
        },
        {
            headerName: "Store", field: "storeName",
        },
        {
            headerName: "Ext", field: "ext",
        },
        //{
        //    headerName: "Roles", field: "roleNames", //minWidth: 200,
        //},
        //{
        //    headerName: "Registered", field: "isRegistered", maxWidth: 120,
        //},
        {
            headerName: "Inactive", field: "inactive", maxWidth: 100, hide: true
        },
        {
            headerName: "Edit", field: "userId", maxWidth: 80, hide: true,
            cellRenderer: params => {              
                return <div>
                    <a href={"/Redirect?route=UserAddEdit&userId=" + params.data.userId}
                        className="underline">Edit</a>
                </div>;
            }
        },
    ]);

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    async function loadUsersData() {
        showLoading();
        var resp = null;

        const endpoint = 'data/getusers';
        console.log("apiUrl: " + endpoint);

        try {
            const response = await get(endpoint);

            if (response.value) {
                var respData = response.value;
                if (respData && respData.success === true) {
                    resp = true;
                    if (NotEmpty(respData.data)) {
                        // Store all users
                        setAllUsers(respData.data);
                        // Filter out inactive users by default
                        const activeUsers = respData.data.filter(user => !user.inactive);
                        setRowData(activeUsers);
                        hideOverlay();
                    }
                    else {
                        setRowData([]);
                        showNoRows();
                    }
                }
                else {
                    console.error("loadUsersData api error: " + respData.error);
                    console.error("apiUrl: " + endpoint);
                    resp = respData;
                }
            }
            else {
                console.error("loadUsersData api error no response.value: " + response);
                console.error("apiUrl: " + endpoint);
                resp = response;
            }

        } catch (error) {
            console.log('Error: ', error);
            resp = error;
        }

        return resp;
    };

    const onGridReady = useCallback((params) => {
        loadUsersData();
    }, []);

    function handleSearch(e) {        
        var api = gridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    function onRowDoubleClicked(data) {
        if (data) {
            var userId = data.userId;
            navigate('/User', { replace: true, state: { userId } });
        }
    }

    function getMessage() {
        var message = "";
        if (location.state && NotEmpty(location.state.userMessage)) {
            message = location.state.userMessage;
        }
        return message;
    }

    const handleInactiveToggle = (e) => {
        setShowInactive(e.target.checked);
        // Show inactive column
        const newColumnDefs = columnDefs.map(col => {
            if (col.field === 'inactive') {
                return { ...col, hide: !e.target.checked };
            }
            return col;
        });
        setColumnDefs(newColumnDefs);
        
        if (e.target.checked) {
            // Show all users
            setRowData(allUsers);
        } else {
            // Show active users
            const activeUsers = allUsers.filter(user => !user.inactive);
            setRowData(activeUsers);
        }
    };

    // ON LOAD
    useEffect(() => {
        setLoading(false);

        SetDocTitle("Lumina - Users");
        SetPageTitle("Users");
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div style={containerStyle} className="p-2 pt-0 ag-grid-center">
            <div className="ag-theme-alpine ag-grid-center" style={{ height: '750px' }}>

                <div className="pt-2">
                    <div className="float-left">
                        <div className="table-cell">
                            <div className="relative flex items-center">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input id="search" type="search" placeholder="Filter..." 
                                    className="w-[400px] block p-2 mb-4 pl-10 text-sm text-gray-900 
                                        border border-gray-300 rounded-lg
                                        focus:ring-blue-500 focus:border-blue-500"
                                    onChange={handleSearch}
                                    autoFocus
                                />
                                <label className="flex items-center space-x-2 text-sm text-gray-600 ml-4 mb-4">
                                    <input
                                        type="checkbox"
                                        checked={showInactive}
                                        onChange={handleInactiveToggle}
                                        className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                    />
                                    <span>Show Inactive Users</span>
                                </label>
                            </div>
                            <div id="selectedRows" />
                        </div>
                        <div className="text-green text-[1rem] pl-4 table-cell">{getMessage()}</div>
                    </div>
                    <div className="float-right">
                        <div className="mb-2 pointer-events-hand pl-3">
                            <a href="/User" className="btn-submit !m-0">Create User</a>
                        </div>
                    </div>
                </div>
                <div className="clear"></div>

                <AgGridReact
                    masterDetail={true}
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                />
            </div>
        </div>
    );
}