import React, { useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getPaymentsByLastFour } from '../../../services/paymentService';

export const OnFileContent = ({
    setCardholderName,
    setCardNumber,
    setSelectedCardId,
    setPaymentType,
    orderId
}) => {
    const [searchText, setSearchText] = useState('');
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const gridRef = useRef();

    const columnDefs = [
        {
            headerName: "ID",
            field: "id",
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1
        },
        {
            headerName: "Last 4 Digits",
            field: "last4",
            width: 120
        },
        {
            headerName: "Address",
            field: "address",
            flex: 2,
        },
        {
            headerName: "Last Used",
            field: "lastUsed",
            width: 120
        },
        {
            headerName: "",
            field: "",
            width: 100,
            cellRenderer: params => (
                <button
                    type="button"
                    className="btn-grid"
                    onClick={() => handleRowSelect(params.data)}
                >
                    Select
                </button>
            )
        }
    ];

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            fetchLastFour();
        }
    };

    const fetchLastFour = useCallback(async () => {
        try {
            if (searchText.length >= 4) {
                const response = await getPaymentsByLastFour(searchText, orderId);
                console.log('Payments:', JSON.stringify(response));
                setGridData(response);

                if (gridRef.current?.api) {
                    gridRef.current.api.setQuickFilter(searchText);
                }
            }
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    }, [searchText]);

    const handleSearch = () => {
        fetchLastFour();
    };

    const handleRowSelect = (rowData) => {
        setSelectedRow(rowData);
        setCardholderName(rowData.name);
        setCardNumber(rowData.last4);
        setSelectedCardId(rowData.token);
        setPaymentType(rowData.paymentType);
    };

    return (
        <div className="space-y-4">
            <div className="flex gap-2">
                <input
                    type="text"
                    placeholder="Last 4 Digits"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="payment-input w-48"
                    maxLength={4}
                />
                <button
                    type="button"
                    onClick={handleSearch}
                    disabled={searchText.length < 4}
                    className="btn-grid"
                >
                    Search
                </button>
            </div>

            <div
                className="ag-theme-alpine w-full"
                style={{ height: '200px' }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={gridData}
                    columnDefs={columnDefs}
                    rowSelection="single"
                    suppressCellFocus={true}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                    }}
                />
            </div>
        </div>
    );
};
