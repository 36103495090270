import React, { Component } from 'react';

import { SampleCheckoutAddEdit } from './SampleCheckoutAddEdit';

export class SampleCheckoutParent extends Component {
    static displayName = SampleCheckoutParent.name;

    //constructor(props) {
    //    super(props);        
    //}

    render() {
        return (
            <SampleCheckoutAddEdit></SampleCheckoutAddEdit>
        );
    }
}