import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { get } from '../../services/apiService';

import { GetLocationStateId, SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { SetPageTitle } from '../../js/helpers';
import {
    Exists, handleFieldChange, IdExists, IsEmail, IsEmpty, IsPhone, IsTrue,
    NotEmpty, ParseIntSafe, PreventDefault, SetAndClickById,
    enforceZipLength, formatToPhone, formatNumberDecPlaces, NotExists, NotStringEmpty
}
from '../../js/formHelpers';

export const StoreAddEdit = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina Store");

    const [storeId, setStoreId] = useState(GetLocationStateId("storeId"));
    const [storeData, setStoreData] = useState(null);
    const [cardMachines, setCardMachines] = useState([]);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Helpers

    function backToClick(storeMessage) {
        navigate('/Stores', {
            replace: true,
            state: { useSavedFiltersVendor: true, storeMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Methods

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        
        if (data) {
            if (data.name === "address.zip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "address.phone1") {
                data.value = formatToPhone(e);
            }
            if (data.name === "address.phone2") {
                data.value = formatToPhone(e);
            }
            if (data.name === "materialsPhone") {
                data.value = formatToPhone(e);
            }
            setStoreData((prevStoreData) => {
                return setNestedProperty(prevStoreData, data.name, data.value);
            });
        } else {
            updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    const setNestedProperty = (obj, path, value) => {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const lastObj = keys.reduce((obj, key) =>
            obj[key] = obj[key] || {},
            obj
        );
        lastObj[lastKey] = value;
        return { ...obj };
    };

    const loadCardMachines = async () => {
        try {
            const response = await get('/cardmachines/GetAll');
            if (NotEmpty(response)) {
                const activeMachines = response.filter(machine => !machine.inactive);
                setCardMachines(activeMachines);
            }
        } catch (error) {
            console.error("Error loading card machines:", error);
            updateMessage("Failed to load card machines. Please try again.", "text-red");
        }
    };

    // Validate and Post Data

    const validateData = () => {
        var valid = true;
        var msg = "";
        //console.log('Validating storeData:', storeData);

        if (NotStringEmpty(storeData.address.phone1) && !IsPhone(storeData.address.phone1)) {
            msg = "Phone1 must be valid";
            valid = false;
        }

        if (NotStringEmpty(storeData.address.phone2) && !IsPhone(storeData.address.phone2)) {
            msg = "Phone2 must be valid";
            valid = false;
        }

        if (NotStringEmpty(storeData.address.email1) && !IsEmail(storeData.address.email1)) {
            msg = "Valid Email1 required";
            valid = false;
        }
        if (NotStringEmpty(storeData.address.email2) && !IsEmail(storeData.address.email2)) {
            msg = "Email2 must be valid";
            valid = false;
        }

        if (NotEmpty(msg)) {
            updateMessage(msg, "text-red");
            console.log('Validation failed:', msg);
        } else {
            console.log('Validation passed');
        }

        return valid;
    }

    const IsPhone = (phone) => {
        console.log('Validate phone:', phone);
        if (!phone) return true;
        const result = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone.trim());
        console.log('Phone validation result:', result);
        return result;
    };

    function getSaveMsg(storeId) {
        if (IdExists(storeId)) {
            return storeData.storeName + " updated successfully.";
        }
        else {
            return storeData.storeName + " created successfully.";
        }
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    };

    const handleSubmit = async (e) => {
        PreventDefault(e);

        var valid = validateData();
        if (valid === true) {
            var resp = await SaveStorePOST();

            console.log('SaveStorePOST response:', resp);

            var iStoreId = ParseIntSafe(resp);
            if (IdExists(iStoreId)) {
                var msg = getSaveMsg(storeId);
                if (redirectSubmit) {
                    //redirect to Search on Exit
                    backToClick(msg);
                }
                else {
                    if (IdExists(storeId)) {
                        updateMessage(msg, "text-green");
                    }
                    else {
                        // reload on create to load from DB
                        SetAndClickById("lnkReloadPage",
                            "/Redirect?route=Store&id=" + iStoreId + "&message=" + msg);                        
                    }
                }
            }
            else {
                console.error("Store handleSubmit(): ", JSON.stringify(resp));
                alert(defaultErrMsg);
            }
        }
    }

    async function SaveStorePOST() {
        var postModel = JSON.stringify(storeData);
        //console.log('Post Model:', postModel);

        try {
            const response = await StandardDataAndResponsePOST(postModel, '/store/SaveStore', "UpdateStorePOST()");
            //console.log('Response:', response);
            return response;
        }
        catch (error) {
            console.error('Error in SaveStorePOST:', error);
            throw error;
        }
    }

    // Load Data

    const loadData = async () => {

        var data = await StandardIdAndResponseGET(storeId, "store/GetStore/", "loadStoreData()");
        if (Exists(data)) {
            setLoading(false);

            if (NotExists(data.salesTaxPercent) || data.salesTaxPercent === "") {
                data.salesTaxPercent = 0;
            }
            var stp = data.salesTaxPercent * 100;
            data.salesTaxPercent = formatNumberDecPlaces(stp, 2);

            setStoreData(data);

            var docT = "Lumina Create Store";
            var t = "Create Store";

            var iStoreId = ParseIntSafe(data.storeId);
            if (IdExists(iStoreId)) {
                docT = "Lumina Store - " + data.name;
                t = "Store - " + data.name;
            }
            setDocTitle(docT);
            SetPageTitle(t);
        }
        else {
            setError(true);
        }
    };

    function showMessage() {
        if (location.state && NotEmpty(location.state.storeMessage)) {
            updateMessage(location.state.storeMessage, "text-green");
        }
    }

    useEffect(() => {
        loadCardMachines();
        showMessage();
        loadData();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (IsTrue(error))
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="container mx-auto p-6">
            <div className="bg-white rounded-lg shadow-sm">
                {/* Header */}
                <div className="px-6 py-4 border-b">
                    <h2 className="text-xl font-semibold text-gray-900">
                        {IdExists(storeId) ? 'Edit Store' : 'Create Store'}
                    </h2>
                </div>

                {/* Form */}
                <div className="p-6">
                    {!loading && (
                        <form onSubmit={handleSubmit}>
                            <div className="grid md:grid-cols-2 gap-8">
                                {/* Left Column */}
                                <div className="space-y-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                                                Store Name*
                                            </label>
                                            <input type="text" id="name" name="name"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.name || ''}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="displayName" className="block text-sm font-medium text-gray-700 mb-1">
                                                Display Name*
                                            </label>
                                            <input type="text" id="displayName" name="displayName"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.displayName || ''}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">
                                            Company Name
                                        </label>
                                        <input type="text" id="companyName" name="companyName"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={storeData.companyName || ''}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Address */}
                                    <div className="pt-4">
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Address Information</h3>
                                        <div className="space-y-4">
                                            <div className="grid grid-cols-2 gap-4">
                                                <div>
                                                    <label htmlFor="address.address1" className="block text-sm font-medium text-gray-700 mb-1">
                                                        Address*
                                                    </label>
                                                    <input type="text" id="address.address1" name="address.address1"
                                                        className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                        value={storeData.address?.address1 || ''}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="address.address2" className="block text-sm font-medium text-gray-700 mb-1">
                                                        Address (cont.)
                                                    </label>
                                                    <input type="text" id="address.address2" name="address.address2"
                                                        className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                        value={storeData.address?.address2 || ''}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-6 gap-4">
                                                <div className="col-span-3">
                                                    <label htmlFor="address.city" className="block text-sm font-medium text-gray-700 mb-1">
                                                        City*
                                                    </label>
                                                    <input type="text" id="address.city" name="address.city"
                                                        className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                        value={storeData.address?.city || ''}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <label htmlFor="address.state" className="block text-sm font-medium text-gray-700 mb-1">
                                                        State*
                                                    </label>
                                                    <input type="text" id="address.state" name="address.state"
                                                        className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                        value={storeData.address?.state || ''}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="address.zip" className="block text-sm font-medium text-gray-700 mb-1">
                                                        Zip*
                                                    </label>
                                                    <input type="text" id="address.zip" name="address.zip"
                                                        className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                        value={storeData.address?.zip || ''}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Right Column */}
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="cardMachineId" className="block text-sm font-medium text-gray-700 mb-1">
                                            Default Payment Terminal
                                        </label>
                                        <select
                                            className="w-64 h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            name="cardMachineId"
                                            value={storeData?.cardMachineId ?? ""}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select a Card Machine</option>
                                            {cardMachines.map(machine => (
                                                <option key={machine.cardMachineId} value={machine.cardMachineId}>
                                                    {machine.name} - {machine.serialNumber}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Contact */}
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="address.phone1" className="block text-sm font-medium text-gray-700 mb-1">
                                                Phone 1
                                            </label>
                                            <input type="text" id="address.phone1" name="address.phone1"
                                                className="w-48 h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.address?.phone1 || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="address.phone2" className="block text-sm font-medium text-gray-700 mb-1">
                                                Phone 2
                                            </label>
                                            <input type="text" id="address.phone2" name="address.phone2"
                                                className="w-48 h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.address?.phone2 || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="address.email1" className="block text-sm font-medium text-gray-700 mb-1">
                                                Email 1
                                            </label>
                                            <input type="email" id="address.email1" name="address.email1"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.address?.email1 || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="address.email2" className="block text-sm font-medium text-gray-700 mb-1">
                                                Email 2
                                            </label>
                                            <input type="email" id="address.email2" name="address.email2"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={storeData.address?.email2 || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Store Settings */}
                                    <div className="pt-4">
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Store Settings</h3>
                                        <div className="grid grid-cols-2 gap-8">
                                            {/* Store Options */}
                                            <div>
                                                <div className="flex justify-between items-center mb-3">
                                                    <h4 className="text-sm font-medium">Store Options</h4>
                                                </div>
                                                <div className="space-y-2">
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="isOrderStore" name="isOrderStore"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.isOrderStore || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">Allow Orders</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="isWarehouseStore" name="isWarehouseStore"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.isWarehouseStore || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">Allow Warehouse</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="inactive" name="inactive"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.inactive || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">Inactive</span>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* Pricing Types */}
                                            <div>
                                                <h4 className="text-sm font-medium mb-3">Pricing Types</h4>
                                                <div className="space-y-2">
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="isPnw" name="isPnw"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.isPnw || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">PNW</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="isAz" name="isAz"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.isAz || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">AZ</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input type="checkbox" id="isWeb" name="isWeb"
                                                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                            checked={storeData.isWeb || false}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-700">WEB</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="mt-6 flex items-center justify-end space-x-4">
                                <div className={messageClass + " text-sm"}>
                                    {message}
                                </div>
                                <button onClick={e => backToClick()} type="button" 
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                                    Cancel
                                </button>
                                <button type="submit" onClick={(e) => btnSubmitClick(e, true)}
                                    className="px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                                    Save & Close
                                </button>                                    
                            </div>
                        </form>
                    )}
                    <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
                </div>
            </div>
        </div>
    );
}