import React, { useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { get, post } from '../../services/apiService';
import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import { ClickById, SetPageTitle } from '../../js/helpers.js';
import { Exists, GetDateNow, GetDisplayCurrencyStr, GetDisplayDateStr, IsTrue, NotExists } from '../../js/formHelpers';

export function DepositRegisterReport() {
    const [stores, setStores] = useState([]);

    const [selectedStore, setSelectedStore] = useState('');
    const [selectedDate, setSelectedDate] = useState(GetDateNow());

    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selecteRegisterData, setSelecteRegisterData] = useState(null)
    const [rowData2, setRowData2] = useState()

    const onDoubleClickRegister = (e) => {
        console.log(e.data);
        setSelecteRegisterData(e.data);
        setRowData2(e.data.payments);
    }

    const fetchStores = async () => {
        try {
            const response = await get('store/GetAllStores');
            setStores(response);
        } catch (error) {
            console.error('Error fetching stores:', error);
            setError('Failed to fetch stores');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSelecteRegisterData(null);
        setRowData2(null);

        try {
            const response = await post('depositregisters/GetReport', {
                storeId: parseInt(selectedStore),
                date: selectedDate
            });
            setReport(response);
        } catch (error) {
            console.error('Error fetching report:', error);
            setError('Failed to fetch report');
        } finally {
            setLoading(false);
        }
    };

    const columnDefs = [
        {
            headerName: 'Deposit Register', field: 'registerName', flex: 2, hide: true,
        },
        {
            headerName: 'Register Type', field: 'registerType', flex: 2
        },
        {
            headerName: 'Total Collected',
            field: 'totalCollected',
            valueFormatter: params => `$${params.value.toFixed(2)}`,
            cellStyle: { textAlign: 'right' },
            headerClass: 'ag-right-aligned-header',
            flex: 1
        }
    ];
    const columnDefsSelectedRegister = [
        {
            headerName: "Transaction #", field: 'paymentId', flex: 2
        },
        {
            headerName: 'Date', field: 'paymentDate', flex: 1,
            valueFormatter: params => {
                const date = new Date(params.value);
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`; // MM/DD/YYYY
            }
        },  
        {
            headerName: 'Account', field: 'account', flex: 2
        },
        {
            headerName: 'Order #', field: 'order', flex: 1
        },
        {
            headerName: 'Amount', field: 'amount',
            valueFormatter: params => `$${params.value.toFixed(2)}`,
            cellStyle: { textAlign: 'right' },
            headerClass: 'ag-right-aligned-header',
            flex: 1
        },
        {
            headerName: 'Type', field: 'type', flex: 1
        },
        {
            headerName: 'Last 4 CC', field: 'last4Card', flex: 1
        },
        {
            headerName: 'Check #', field: 'checkNumber', flex: 1
        },
        {
            headerName: "Processed By", field: 'processedBy', flex: 2
        }
    ]

    function onBackClick() {
        setRowData2(null);
        ClickById("btnSubmitDepositRegisterReport");
    }

    useEffect(() => {
        SetDocTitle("Lumina - Deposit Register Report");
        SetPageTitle("Deposit Register Report");
        fetchStores();
    }, []);

    return (
        <div className="page-wrapper">
            {report && selecteRegisterData &&
                <div className={Exists(rowData2) ? "" : "hidden"}>
                    <div className={"max-w-3xl mx-auto text-center"}>
                        <h2 className="text-xl font-bold mb-2">Store {report.storeName} - {selecteRegisterData.registerType} Detail </h2>
                        <p className="mb-4 font-semibold">Date: {GetDisplayDateStr(report.date)}</p>
                        <button onClick={() => onBackClick()} className={'underline text-primary mb-5 font-semibold'}>Back</button>
                    </div>
                    <div
                        className={'ag-theme-alpine'} style={{ width: '100%' }}>
                        <AgGridReact rowData={rowData2} columnDefs={columnDefsSelectedRegister}
                            domLayout={'autoHeight'} />
                    </div>
                    <div className="mt-4 text-right font-bold text-black">
                        Total Deposits: {GetDisplayCurrencyStr(selecteRegisterData.totalCollected)}
                    </div>
                </div>
            }
            <div className={Exists(rowData2) ? "hidden" : ""}>
                <div className="max-w-3xl mx-auto">
                    <form onSubmit={handleSubmit} className="mb-4">

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 items-end">
                            <div>
                                <label htmlFor="store" className="label-form !font-bold">Store:</label>
                                <select
                                    id="store"
                                    value={selectedStore}
                                    onChange={(e) => setSelectedStore(e.target.value)}
                                    className="label-form-input w-full"
                                    required
                                >
                                    <option value="">Select a store</option>
                                    {stores.map((store) => (
                                        <option key={store.storeId} value={store.storeId}>
                                            {store.displayName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="date" className="label-form !font-bold">Date:</label>
                                <input
                                    type="date"
                                    id="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    className="label-form-input w-full"
                                    required
                                />
                            </div>
                            <div>
                                <button id="btnSubmitDepositRegisterReport" type="submit" className="btn-order !py-1 w-full mb-1">
                                    Generate Report
                                </button>
                            </div>
                        </div>
                    </form>

                    {error && <p className="text-red-500">{error}</p>}
                    {report && selecteRegisterData == null && (
                        <div>
                            <div className="text-center">
                                <h2 className="text-xl font-bold mb-2">Store {report.storeName}</h2>
                                <p className="mb-4 font-semibold">Date: {GetDisplayDateStr(report.date)}</p>
                            </div>
                            <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    onRowDoubleClicked={onDoubleClickRegister}
                                    rowData={report.registerDetails}
                                    defaultColDef={{
                                        minWidth: 100,
                                        sortable: true,
                                        resizable: true
                                    }}
                                    domLayout='autoHeight'
                                />
                            </div>
                            <div className="mt-4 text-right font-bold text-black">
                                Total Deposits: {GetDisplayCurrencyStr(report.totalDeposits)}
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
}