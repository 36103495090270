import React, { useState } from 'react';
import axios from 'axios';

import { Exists, handleFieldChange, IdExists, IsFalse, IsTrue, NotExists, NotStringEmpty } from '../../js/formHelpers';
import { SetValueById } from '../../js/helpers';

export const FileUploader = (props) => {
    const handleCloseFunc = props.handleClose ?? null;
    const accountId = props.accountId ?? 0;
    const isPriceAdmin = IsTrue(props.isPriceAdmin);

    // set conditions here for additional upload types
    const showDescription = IdExists(accountId);
    const allowXls = IsTrue(props.isPriceAdmin);
    const allowAllFileTypes = IsFalse(isPriceAdmin);    

    const [description, setDescription] = useState(null);
    const [file, setFile] = useState(null);

    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            if (data.name === "description") {
                setDescription(data.value);
            }
            if (data.name === "uploadedFile") {
                if (e.target.files) {
                    setFile(e.target.files[0]);
                    //setFile(e.target.files);
                }
            }
        }
        else {
            alert(defaultErrMsg);
        }
    };

    const handleUpload = async () => {
        if (Exists(file)) {

            const formData = new FormData();
            var endpoint = "";            

            if (IdExists(accountId)) {
                endpoint = "data/UploadAccountAttachmentToDb";
                formData.append("accountId", accountId);
            }
            else if (isPriceAdmin) {
                endpoint = "product/ImportProductPrices";
            }

            formData.append("description", description);
            formData.append("file", file, file.name);
            
            try {
                var config = {};
                config.headers = {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                };

                var userData = JSON.parse(localStorage.getItem('userData'));
                if (userData) {
                    const userId = userData.userId ?? 0;
                    const storeId = userData.user?.defaultStoreId ?? 0;
                    config.headers['X-UserId'] = userId;
                    config.headers['X-StoreId'] = storeId;
                    //console.log('Adding X-UserId = ' + userId + ' to header');
                } else {
                    console.log('No userData');
                }

                if (NotStringEmpty(endpoint)) {
                    const resp = await axios.post(process.env.REACT_APP_API_URL + endpoint, formData, config);
                    setDescription("");
                    setFile(null);
                    SetValueById("uploadedFile", null);

                    if (handleCloseFunc) {
                        handleCloseFunc();
                    }
                }
                else {
                    alert(defaultErrMsg);
                }
            } catch (error) {
                console.error(error);
                alert(defaultErrMsg);
            }
        }
    };

    function cancelClick() {
        if (handleCloseFunc) {
            if (isPriceAdmin) {
                handleCloseFunc(true);
            }
            else {
                handleCloseFunc();
            }
        }
    }

    return (
        <div className="p-2">

            {isPriceAdmin &&
                <div className="pb-2 text-center text-lg font-bold underline">Import Product Pricing xlsx</div>
            }

            {showDescription &&
                <div className="details-form-field !w-full">
                    <input id="description" name="description" type="text" placeholder="Description"
                        className="inp-text !text-lg"
                        value={description}
                        onChange={handleChange}
                    />
                </div>
            }

            <div className="details-form-field !w-full !pt-4">
                {allowAllFileTypes &&
                    <input id="uploadedFile" name="uploadedFile" type="file"
                        className="w-full"
                        onChange={handleChange} />
                }
                {allowXls &&
                    <input id="uploadedFile" name="uploadedFile" type="file"
                        className="w-full"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        //accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleChange} />
                }
            </div>
            <div className="details-form-field !w-full text-right !pt-8">
                <button id="fileUploadClose" type="button" className="btn-cancel" onClick={(e) => cancelClick()}>
                    Cancel
                </button>
                <button className="btn-submit" onClick={handleUpload} disabled={NotExists(file)}>
                    {allowAllFileTypes &&
                        <span>Upload File</span>
                    }
                    {isPriceAdmin &&
                        <span>Upload .xlsx File</span>
                    }
                </button>                
            </div>
        </div>
    );
}