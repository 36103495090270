import axios from 'axios';
import { get, post } from './apiService.js';

const API_BASE_URL = 'https://testlink.c9pg.com:11911/Payment';

// Default config as fallback
const DEFAULT_TERMINAL_CONFIG = {
    GMID: "1110102975",
    GTID: "GT1120044415",
    GMPW: "GMPW3010022428"
};

// Echeck testing config
const ECHECK_CONFIG = {
    EleCheckRoutingNumber: "123456780",
    EleCheckAccountNumber: "1071642307",
}

// ACH testing config
const DEFAULT_ACH_CONFIG = {
    GMID: "1110103080",
    GTID: "GT1120045132",
    GMPW: "GMPW3010022535"
};

const getApiEndpoints = (config) => ({
    SALE: `${API_BASE_URL}/Sale#Sale?TerminalType=None&NeedSwipeCard=N&GMID=${config.GMID}`,
    TOKENIZATION: `${API_BASE_URL}/Sale#Tokenization?TerminalType=None&NeedSwipeCard=N&GMID=${config.GMID}`,
    ADJUST: `${API_BASE_URL}/Payment#Modify_Adjust_AddTip?TerminalType=None&NeedSwipeCard=N&GMID=${config.GMID}`,
    REVERSE: `${API_BASE_URL}/Payment#Void_Reverse_Cancel?TerminalType=None&NeedSwipeCard=N&GMID=${config.GMID}`,
    ACH: `https://testlink.c9pg.com:11911/Payment/Auth#ACHDebit?TerminalType=None&NeedSwipeCard=N&GMID=${config.GMID}`
});

// Get User Card Machine(s)
export const getCardMachinesForUser = async (userId) => {
    try {
        const response = await get(`/cardmachines/GetByUserId/${userId}`);
        return response;
    } catch (error) {
        console.error('Error fetching card machines:', error);
        throw error;
    }
};

// Credit Card Payment
export const makePayment = async (paymentRequest, cardMachine = null) => {
    try {
        console.log('payment cardmachine:', cardMachine);
        // Use card machine config if available, otherwise use default
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        console.log('Payment Request', paymentRequest);

        const request = {
            ...paymentRequest,
            ...terminalConfig,
            TransType: "Sale"
        };

        const endpoints = getApiEndpoints(terminalConfig);
        const response = await axios.post(endpoints.SALE, request);
        return response.data;
    } catch (error) {
        console.error('Payment failed:', error);
        throw error;
    }
};

// Get Token for Card
export const getCardToken = async (tokenRequest, cardMachine = null) => {
    try
    {
        // Use card machine config if available, otherwise use default
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        const request = {
            ...tokenRequest,
            ...terminalConfig,
            TransType: "CreateCardToken"
        };

        const endpoints = getApiEndpoints(terminalConfig);
        const response = await axios.post(endpoints.TOKENIZATION, request);
        return response.data;
    }
    catch (error) {
        console.error('Token creation failed:', error);
        throw error;
    }
}

// Adjust
export const adjustPayment = async (paymentId, gtrc, amount, cardMachine = null) => {
    try {
        console.log('AdjustPayment: ', paymentId, amount, gtrc);
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        const amountInCents = Math.round(parseFloat(amount) * 100);

        // C9G
        const c9gRequest = {
            ...terminalConfig,
            TransType: "Modify",
            GTRC: gtrc,
            Medium: "Credit",
            MainAmt: amountInCents,
        };

        console.log('C9G adjust request:', c9gRequest);
        const endpoints = getApiEndpoints(terminalConfig);
        const c9gResponse = await axios.post(endpoints.ADJUST, c9gRequest);
        
        if (c9gResponse.data.ErrorCode !== '000') {
            throw new Error(c9gResponse.data.ErrorText || 'Failed to adjust payment with C9G');
        }

        // Transaction
        const transactionModel = {
            transactionIdentifier: c9gResponse.data.TransactionIdentifier,
            transactionResponse: JSON.stringify(c9gResponse.data),
            responseMessage: c9gResponse.data.ErrorText || '',
            cardToken: '',
            status: c9gResponse.data.ResultText || 'Success',
            userId: 0, // Handled in backend
            transactionDate: new Date().toISOString(),
            GTRC: c9gResponse.data.GTRC,
            transactionType: 1 // Adjustment

        };
        console.log('Transaction model:', transactionModel);

        // 
        const adjustRequest = {
            paymentId: paymentId,
            amount: amount,
            transaction: transactionModel
        };

        const adjustResponse = await post('/payments/AdjustPayment', adjustRequest);
        return {
            ...c9gResponse.data,
            adjustmentResult: adjustResponse
        };
    } catch (err) {
        console.error('Error adjusting payment:', err);
        throw err;
    }
}

// Reverse/Cancel
export const reversePayment = async (paymentId, gtrc, amount, cardMachine = null, isAch = false) => {
    try {
        console.log('ReversePayment: ', paymentId, amount, gtrc);
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        const amountInCents = Math.round(parseFloat(amount) * 100);

        // C9G
        const request = {
            ...terminalConfig,
            TransType: "Void",
            GTRC: gtrc,
            //Medium: "Credit",
            MainAmt: amountInCents,
        };

        // ACH 

        const endpoints = getApiEndpoints(terminalConfig);
        const c9gResponse = await axios.post(endpoints.REVERSE, request);

        if (c9gResponse.data.ErrorCode !== '000') {
            throw new Error(c9gResponse.data.ErrorText || 'Failed to reverse payment with C9G');
        }

        // Transaction
        const transactionModel = {
            transactionIdentifier: c9gResponse.data.TransactionIdentifier,
            transactionResponse: JSON.stringify(c9gResponse.data),
            responseMessage: c9gResponse.data.ErrorText || '',
            cardToken: '',
            status: c9gResponse.data.ResultText || 'Success',
            userId: 0, // Handled by backend
            transactionDate: new Date().toISOString(),
            GTRC: c9gResponse.data.GTRC,
            transactionType: 2 // Reversal/Void
        };
        console.log('Transaction model:', transactionModel);

        // Backend
        const reverseRequest = {
            paymentId: paymentId,
            amount: amount,
            transaction: transactionModel
        };

        const reverseResponse = await post('/payments/ReversePayment', reverseRequest);
        return {
            ...c9gResponse.data,
            reversalResult: reverseResponse
        };
    } catch (err) {
        console.error('Error reversing payment:', err);
        throw err;
    }
}

// Card Token
export const makePaymentByToken = async (token, amount, cardMachine = null) => {
    try {
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        const amountInCents = Math.round(parseFloat(amount) * 100);

        const tokenRequest = {
            ...terminalConfig,
            TransType: "Sale",
            Medium: "Credit",
            MainAmt: amountInCents,
            CardToken: token || ''
        };
        const endpoints = getApiEndpoints(terminalConfig);
        const response = await axios.post(endpoints.TOKENIZATION, tokenRequest);
        return response.data;
    } catch (error) {
        console.error('Token payment failed:', error);
        throw error;
    }
}

// ACH Token
export const getAchToken = async (achInfo, cardMachine = null) => {
    try {
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_TERMINAL_CONFIG;

        const tokenRequest = {
            ...terminalConfig,
            TransType: "CreateCardToken",
            Medium: "ElectronicCheck",
            EntryModel: "Manual",
            // Test numbers - TODO: Remove for production
            EleCheckRoutingNumber: ECHECK_CONFIG.EleCheckRoutingNumber,
            EleCheckAccountNumber: ECHECK_CONFIG.EleCheckAccountNumber,
            AccountType: achInfo.accountType
        };

        const endpoints = getApiEndpoints(terminalConfig);
        const response = await axios.post(endpoints.ACH, tokenRequest);
        return response.data;
    } catch (error) {
        console.error('ACH token creation failed:', error);
        throw error;
    }
}

// ACH Payment
export const makeAchPayment = async (achInfo, cardMachine = null) => {
    try {
        console.log('makeAchPayment:', achInfo);
        const terminalConfig = cardMachine ? {
            GMID: cardMachine.gmid,
            GTID: cardMachine.gtid,
            GMPW: cardMachine.gmpw
        } : DEFAULT_ACH_CONFIG;

        const request = {
            ...achInfo,
            ...terminalConfig,
            Medium: "ElectronicCheck",
            TransType: "Sale",
            EntryModel: "Manual",
            EleCheckServiceProvider: "ElecCheckWEB",
            EleCheckTransactionType: "EleCheckConversion",
            MainAmt: achInfo.MainAmt,
            // Test numbers - TODO: Remove for production
            GMID: "1110103080",
            GTID: "GT1120045132",
            GMPW: "GMPW3010022535",
            EleCheckRoutingNumber: "102000021",
            EleCheckAccountNumber: "125401754499",
        };

        console.log('ACH request:', request);
        const endpoints = getApiEndpoints(terminalConfig);
        const response = await axios.post(endpoints.ACH, request);
        return response.data;
    } catch (error) {
        console.error('ACH payment failed:', error);
        throw error;
    }
}

// Get Card Machine
export const getCardMachineForUser = async (userId, storeId = null) => {
    try {
        let url = `/card/GetByUserId/${userId}`;
        if (storeId) {
            url += `?storeId=${storeId}`;
        }

        const response = await get(url);
        if (!response) return null;

        return {
            GMID: response.GMID,
            GTID: response.GTID,
            GMPW: response.GMPW
        };
    } catch (error) {
        console.error('Error fetching card machine for user:', error);
        return null;
    }
}

// Database Operations
export const postPaymentToDatabase = async (paymentInfo) => {
    try {
        const response = await post('/payments/CreatePayment', paymentInfo);
        return response;
    } catch (error) {
        console.error('Error posting payment:', error);
        throw error;
    }
}

export const postTransactionToDatabase = async (transactionInfo) => {
    try {
        const response = await post('/payments/SaveTransaction', transactionInfo);
        return response;
    } catch (error) {
        console.error('Error saving transaction:', error);
        throw error;
    }
}

export const getPaymentsForOrder = async (orderId) => {
    try {
        const response = await get(`/payments/GetPaymentsForOrder/${orderId}`);
        return response;
    } catch (error) {
        console.error('Error fetching order payments:', error);
        throw error;
    }
}

export const getPaymentsByLastFour = async (lastFour, orderId) => {
    try {
        const response = await get(`/payments/GetPaymentsByLastFour?lastFour=${lastFour}&orderId=${orderId}`);
        return response;
    } catch (error) {
        console.error('Error fetching payments by last four:', error);
        throw error;
    }
}