import React, { useState, useEffect } from 'react';
import { handleFieldChange, IsTrue } from '../../js/formHelpers';
import { get, post } from '../../services/apiService.js'; 

export function EditProductCodeForm({ productCodeData, onProductCodeUpdated, onCancel }) {
    const [formData, setFormData] = useState({
        productCategoryId: null,
        productCode: '',
        name: '',
        pcWeight: 0,
        isRoll: false,
        isItem: false,
        isSamples: false
    });

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');

    useEffect(() => {
        if (productCodeData) {
            setFormData({
                productCategoryId: productCodeData.productCategoryId,
                productCode: productCodeData.productCode || '',
                name: productCodeData.name || '',
                pcWeight: productCodeData.pcWeight || 0,
                isRoll: productCodeData.isRoll || false,
                isItem: productCodeData.isItem || false,
                isSamples: productCodeData.isSamples || false
            });
            setLoading(false);
        }
    }, [productCodeData]);

    const handleChange = (e) => {
        const data = handleFieldChange(e);
        if (data) {
            setFormData(prevState => ({
                ...prevState,
                [data.name]: data.value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.productCategoryId) {
            updateMessage('Invalid Product Code ID', 'text-red-600');
            return;
        }

        try {
            await post('productCode/UpdateProductCode', JSON.stringify(formData));
            updateMessage('Product Category Updated Successfully', 'text-green-600');
            onProductCodeUpdated();
        } catch (error) {
            console.log('Error updating Product Code:', error);
            updateMessage('Error updating Product Category', 'text-red-600');
        }
    };

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
        setTimeout(() => {
            setMessage('');
            setMessageClass('');
        }, 3000);
    };

    if (IsTrue(loading)) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="act-form">
                <div className="details-form-field">
                    <label htmlFor="productCode" className="lbl-text1">Code</label>
                    <input
                        type="text"
                        id="productCode"
                        name="productCode"
                        className="inp-text"
                        disabled
                        value={formData.productCode}
                    />
                </div>
                <div className="details-form-field">
                    <label htmlFor="name" className="lbl-text1">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="inp-text"
                        placeholder="Category Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="details-form-field">
                    <label htmlFor="pcWeight" className="lbl-text1">PC Weight</label>
                    <input
                        type="number"
                        id="pcWeight"
                        name="pcWeight"
                        placeholder="0.00"
                        className="inp-text"
                        value={formData.pcWeight}
                        onChange={handleChange}
                    />
                </div>
                <div className="details-form-field">
                    <label className="lbl-text1">Product Type</label>
                    <div className="flex space-x-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isRoll"
                                name="isRoll"
                                className="inp-cb mr-2"
                                checked={formData.isRoll}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Roll</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isItem"
                                name="isItem"
                                className="inp-cb mr-2"
                                checked={formData.isItem}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Item</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="isSamples"
                                name="isSamples"
                                className="inp-cb mr-2"
                                checked={formData.isSamples}
                                onChange={handleChange}
                            />
                            <span className="lbl-cb">Samples</span>
                        </label>
                    </div>
                </div>
                <div className="w-full text-right mt-4">
                    <div className={`inline-block mr-1 ${messageClass}`}>
                        {message}
                    </div>
                    <button type="button" className="btn-cancel mr-2" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="submit" className="btn-submit">
                        Save and Exit
                    </button>
                </div>
            </form>
        </div>
    );
}

