import React, {useState, useRef, useMemo, useCallback, useEffect} from 'react';

import {initFlowbite} from "flowbite";
import {Table} from 'flowbite-react';
import {Modal, Button} from 'react-daisyui';
import authService from '../../services/authService';

import {SetDocumentTitle} from "../_reactHelpers/ReactHelpers";
import {
  SetPageTitle, GetValueById, SetValueById, ClickById,
  AddClassById, RemoveClassByClass,
}
  from '../../js/helpers.js';
import {
  formatCurrencyDecStr, CheckEnterKeyPress, PreventDefault,
  IsTrue, ParseIntSafe, Exists, IsFalse, IdExists, NotEmpty, defaultErrMsg, GetDisplayCurrencyStr
}
  from '../../js/formHelpers.js';

import {Equals, GreaterThan} from '../../js/calculationHelpers';
import {fetchHeaders, get} from '../../services/apiService';
import {AgGridServerSideResponseGET, GetStoreSelectList} from '../../js/dataHelpers';

import "../Products/_productCustom.css";
import {ProductAddEdit} from '../Products/ProductAddEdit';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {ModuleRegistry} from '@ag-grid-community/core';
import {MasterDetailModule} from 'ag-grid-enterprise'; //'@ag-grid-enterprise/master-detail';
ModuleRegistry.registerModules([MasterDetailModule]);

export function ProductSalesGrid(props) {
    const [docTitle, setDocTitle] = SetDocumentTitle("");

    //const propsOrderStoreId = ParseIntSafe(props.orderStoreId ?? 2);
    const propsOrderId = ParseIntSafe(props.orderId);
    const propsIsInventoryOrder = props.isInventoryOrder ?? false;

    const propsPageType = props.pageType ?? "";
    const IsPricingPageType = false;
    const IsAdminPageType = false;
    const IsSalesPageType = Equals(propsPageType, "sales");
    const IsOrderPageType = Equals(propsPageType, "order");

    const gridHeight = getGridHeight();
    const productModalClass = getProductModalClass();

    const [currentStoreId, setCurrentStoreId] = useState(0);
    const [storeQtyHdr, setStoreQtyHdr] = useState("");
    const [isStockProduct, setIsStockProduct] = useState(false);

    const minSearchChars = 2;
    const pageRows = 50;
    var rowModelType = 'serverSide';

    const productEditModalRef = useRef();
    const [productEditModalContent, setProductEditModalContent] = useState(null);

    const [productId, setProductId] = useState(0);
    const [detailsTab, setDetailsTab] = useState(null);
    const [productLoading, setProductLoading] = useState(true);
    const [productError, setProductError] = useState(false);

    const productStockModalRef = useRef();
    const [productStockModalContent, setProductStockModalContent] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [selectedHasBundles, setSelectedHasBundles] = useState([]);

    //const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    // Methods

    function getGridHeight() {
        if (IsAdminPageType) {
            return "600px";
        } else if (IsSalesPageType) {
            return "600px";
        } else if (IsOrderPageType) {
            return "500px";
        }
    }

    function getProductModalClass() {
        if (IsAdminPageType) {
            return "product-admin-modal-content";
        } else if (IsSalesPageType) {
            return "product-sales-modal-content";
        } else if (IsOrderPageType) {
            return "product-order-modal-content";
        }
    }

    // Product grid
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const columnDefs = [
        {
            headerName: 'ProductId', field: 'productId', hide: true,
        },
        {
            headerName: 'Style', field: 'style', width: 300, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[300px]">{params.data.style}</div>;
            },
        },
        {
            headerName: "Style #", field: 'styleNumber', maxWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200]">{params.data.styleNumber}</div>;
            },
        },
        {
            headerName: "Category", field: 'categoryName', maxWidth: 160, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data.categoryName}</div>;
            },
        },
        {
            headerName: 'Vendor', field: 'vendorName', maxWidth: 210, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.vendorName}</div>;
            },
        },
    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    //Color grid
    const gridRefOpts = useRef();
    const [productOptionsData, setProductOptionsData] = useState([]);
    const [productOptionsPriceData, setProductOptionsPriceData] = useState([]);

    //const containerStyle2 = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const [gridHeight2, setGridHeight2] = useState("650px");
    const columnDefsOpt = [
        {
            field: 'productPriceId', headerName: "PriceId", hide: true
        },
        {
            field: 'productId', headerName: "ProductId", hide: true
        },
        {
            headerName: storeQtyHdr, field: "ProductId", maxWidth: 130,            
            hide: IsFalse(isStockProduct),
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">
                    {getStoreQty(params.data)}
                </div>;
            }
        },
        {
            headerName: "Color", field: 'color', resizable: true, width: 300,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[300px]">{params.data.color}</div>;
            },
        },
        {
            headerName: "Color #", field: 'colorNum', resizable: true, maxWidth: 220,
            editable: true
        },
        {
            headerName: "Sku", field: 'sku', maxWidth: 150,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data.sku}</div>;
            },
        },
        {
            headerName: "Other Stores", field: 'productPriceId', maxWidth: 120,
            hide: IsFalse(isStockProduct),
            cellRenderer: params => {
                var hasStock = NotEmpty(params.data.productStockModels);
                return <div className="leading-[20px] pt-[11px] min-w-[120px]">
                    {hasStock &&
                        <span onClick={e => onOpenStockModal(e, params.data)} className="underline">View</span>
                    }
                </div>
            }
        },
        //{
        //    headerName: "PNW", field: 'isPnw', maxWidth: 70, hide: true //true IsOrderPageType
        //},
        //{
        //    headerName: "AZ", field: 'isAz', maxWidth: 70, hide: true //true IsOrderPageType
        //},
        //{
        //    headerName: "Web", field: 'isWeb', maxWidth: 70, hide: true //true IsOrderPageType
        //}
    ];

    const gridOptions2 = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    const [showCost, setShowCost] = useState(false);
    const columnsPricingLayout = [
        {
            headerName: 'MSRP', field: 'msrp', cellClass: ["font-bold"],
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
},
        {
            headerName: 'BC', field: 'priceBc', cellClass: ["font-bold"],
            valueFormatter: params => GetDisplayCurrencyStr(params.value) },
        {
            headerName: 'BC5', field: 'bc5',
            valueFormatter: params => GetDisplayCurrencyStr(params.value) },
        {
            headerName: 'BC1', field: 'bc1',
            valueFormatter: params => GetDisplayCurrencyStr(params.value) },
        {
            headerName: 'BC2', field: 'bc2',
            valueFormatter: params => GetDisplayCurrencyStr(params.value) },
        {
            headerName: 'BC3', field: 'bc3',
            valueFormatter: params => GetDisplayCurrencyStr(params.value) },
        {
            headerName: 'Ctn Qty', field: 'serialNo', cellClass: ["font-bold"]
        },
        {
            headerName: 'Cost', field: 'cost',
            maxWidth: 70,
            cellRenderer: params => {
                return (
                    <>
                        {showCost ?
                            (<span>{GetDisplayCurrencyStr(params.value)}</span>)
                            : (
                                <span onClick={(e) => { e.preventDefault(); setShowCost(true); }}
                                    className="text-primary underline">Show</span>
                            )}
                    </>
                );
            }
        },
        { headerName: 'Other Fees', field: 'otherFees' },
    ]

    const gridOptionsPricingLayout = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: true,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Add/Edit Modal

    function returnProductAddEdit(productId, productPriceId, productSkuId) {
        return <ProductAddEdit
            pageType={propsPageType}
            isPricingPageType={IsPricingPageType} isAdminPageType={IsAdminPageType}
            isSalesPageType={IsSalesPageType} isOrderPageType={IsOrderPageType}
            productId={productId} productPriceId={productPriceId} orderId={propsOrderId}
            productSkuId={productSkuId} onCloseModal={onCloseProductEditModal}>
        </ProductAddEdit>;
    }

    function onOpenProductEditModal(productId, productPriceId, productSkuId) {
        setProductEditModalContent(returnProductAddEdit(productId, productPriceId, productSkuId));
        productEditModalShow();
    }

    function onCloseProductEditModal(msg) {
        //PreventDefault(e);
        updateMessage(msg, "text-green");
        setProductEditModalContent(null);
        productEditModalHide();
    }

    const productEditModalShow = useCallback(() => {
        productEditModalRef.current?.showModal();
    }, [productEditModalRef]);

    const productEditModalHide = useCallback(() => {
        productEditModalRef.current?.close();
    }, [productEditModalRef]);


    // Stock
    async function getStoreQtyHeader() {
        var hdr = "Qty";

        var cStoreId = await authService.getCurrentStoreId();
        var iStoreId = ParseIntSafe(cStoreId);
        if (IdExists(iStoreId)) {
            setCurrentStoreId(iStoreId);

            var dataList = await GetStoreSelectList(1);
            dataList.forEach((item, index) => {
                if (iStoreId === item.value) {
                    hdr = item.text + " Qty";
                }
            });
            setStoreQtyHdr(hdr);
        }
        else {
            setCurrentStoreId(0);
            setStoreQtyHdr("");
        }
    }

    function getStoreQty(data) {
        var qty = "";
        if (IsTrue(data.isStock) && data.color !== "To Be Determined") {
            qty = 0;
        }
        if (NotEmpty(data.productStockModels)) {

            data.productStockModels.forEach((sm, index) => {
                if (currentStoreId === sm.storeId) {
                    qty = sm.quantityAvailable ?? 0;
                }
            });
        }
        return qty;
    }
    function onOpenStockModal(e, data) {
        PreventDefault(e);
        setProductStockModalContent(getStockModalContent(data));
        productStockModalShow();
    }

    function onCloseStockModal(e, selectStockProduct) {
        PreventDefault(e);
        setProductStockModalContent(null);
        productStockModalHide();

        if (IsTrue(selectStockProduct)) {
            ClickById("btnSelectProduct");
        }
    }

    const productStockModalShow = useCallback(() => {
        productStockModalRef.current?.showModal();
    }, [productStockModalRef]);

    const productStockModalHide = useCallback(() => {
        productStockModalRef.current?.close();
    }, [productStockModalRef]);

    function getStockModalContent(data) {
        return <div>
            <div className="font-bold text-secondary text-center mb-2">
                <div>
                    {selectedProduct && selectedProduct.style}
                </div>
                <div>
                    {data.color}
                </div>
            </div>
            <div className="clear rounded border-gray-200 border">
                <Table className="tbl-order-lines">
                    <Table.Head>
                        <Table.HeadCell>Store</Table.HeadCell>
                        <Table.HeadCell>Qty Available</Table.HeadCell>
                        {/*<Table.HeadCell>Price</Table.HeadCell>*/}
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {getStockLines(data)}
                    </Table.Body>
                </Table>
            </div>
        </div>;
    }

    function getStockLines(data) {
        var lineList = [];
        var defSelected = false;
        if (data.productStockModels && data.productStockModels.length > 0) {
            data.productStockModels.forEach((sm, index) => {
                //only show Stock with Available Qty
                if (GreaterThan(sm.quantityAvailable, 0)) {

                    var rowCssClass = "";
                    if (IsOrderPageType && defSelected === false) {
                        //select stock for current order store
                        if (currentStoreId === sm.storeId) {
                            defSelected = true;
                            rowCssClass = "ag-row-selected-bg cursor-pointer";
                            setHfProductIds(sm.productId, sm.productPriceId, sm.productStockId, sm.productSkuId, false);
                        }
                    }

                    var i = index + 1;
                    var rowId = "prodStockRowId-" + i;
                    lineList.push(
                        <Table.Row key={i} id={rowId} className={"prod-stock-row " + rowCssClass}
                            onDoubleClick={e =>
                                updateSelectedProductStock(e, rowId, sm.productId, sm.productPriceId, sm.productStockId, sm.productSkuId)}>
                            <Table.Cell>
                                {sm.storeDisplayName}
                            </Table.Cell>
                            <Table.Cell>
                                {sm.quantityAvailable}
                            </Table.Cell>
                            {/*<Table.Cell>*/}
                            {/*    {formatCurrencyDecStr(data.priceBc)}*/}
                            {/*</Table.Cell>*/}
                        </Table.Row>
                    );
                } else {
                    var dev = true;
                }
            });
        }
        return lineList;
    }

    function updateSelectedProductStock(e, rowId, productId, productPriceId, productStockId, productSkuId) {
        //set to use when adding line in order
        if (IsOrderPageType) {
            //higlight row
            RemoveClassByClass("prod-stock-row", "ag-row-selected-bg");
            AddClassById(rowId, "ag-row-selected-bg");

            setHfProductIds(productId, productPriceId, productStockId, productSkuId, false);
            onCloseStockModal(e, true);
        }
    }

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: params => {
                var pageNum = params.request.endRow / pageRows;
                var page = pageNum - 1;
                var searchStr = GetValueById("tbSearch");

                //var productTypeStr = "";
                //if (IsOrderPageType) {
                //    GetSelectedRadioValueByName("productTypes");
                //}
                //+ '&sProductType=' + productTypeStr;

                var apiUrl = process.env.REACT_APP_API_URL + 'product/GetSalesProductsPaged' +
                    '?numRows=' + pageRows + '&page=' + page + '&sText=' + searchStr +
                    '&isInventoryOrder=' + propsIsInventoryOrder;

                if (searchStr.length >= minSearchChars) {
                    //console.log("server side apiUrl: " + apiUrl);

                    var config = {};
                    config.headers = {
                        //"Content-Type": "multipart/form-data",
                        //Accept: "application/json",
                    };

                    fetchHeaders(apiUrl)
                        .then((response) => response.json())
                        .then((rowData) => {
                            if (Exists(rowData) && NotEmpty(rowData.dataRows)) {

                                params.success({
                                    rowData: rowData.dataRows
                                });
                                selectFirstProductRow(page);
                                hideOverlay();
                                //console.log("total Product rows: " + dataRows.length);
                            } else {
                                params.success({ rowData: [] });
                                console.log("total Product rows: no data or end of data");
                            }
                        })
                        .catch((error) => {
                            params.success({ rowData: [] });
                            showNoRows();
                            setError(error);
                            console.error('loadProductsData Error:', error);
                        });
                } else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("total Product rows: load none");
                }
            }
        };
    }

    const refreshCache = useCallback((e) => {
        setSelectedProduct([]);
        setSelectedProductOptions([]);
        setProductOptionsData([]);
        gridRef.current.api.refreshServerSide({ purge: true });
    }, []);

    const cellClicked = useCallback((params) => {
        SetSelectedProduct(params.node, params.data);
        setShowCost(false);
    }, []);

    function selectFirstProductRow(page) {
        //autoselect first result after search
        if (page === 0) {
            var nodes = gridRef.current.api.getRenderedNodes();
            SetSelectedProduct(nodes[0], nodes[0] && nodes[0].data);
        }
    }

    function SetSelectedProduct(node, data) {
        if (node && data) {
            node.setSelected(true);
            setSelectedProduct(data);
            //options            
            loadProductOptions(data.productId);
        } else {
            setSelectedProduct([]);
            setSelectedProductOptions([]);
        }
    }

    function onProductRowDoubleClicked(data) {
        if (data) {
            var productId = data.productId;
            var productPriceId = 0;
            var productSkuId = 0;
            var productStockId = 0;
            //var productPriceId = data.productPriceId;
            //var productStockId = getOrderStoreProductStockId(data);

            if (IsFalse(IsOrderPageType)) {
                //loadProductData(productId);
                onOpenProductEditModal(productId, productPriceId, productSkuId);
            }
        }
    }

    //function handleSearch(e) {
    //    const value = e.target.value.toLowerCase();
    //    gridRef.current.api.setQuickFilter(value);
    //};

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    //Product Options
    async function loadProductOptions(productId) {
        var success = false;
        setProductOptionsData([]);
        setSelectedProductOptions([]);

        if (productId && productId > 0) {

            const endpoint = 'product/GetProductOptions/' + productId + '/0/0/0';
            try {
                const response = await get(endpoint);
                if (Exists(response)) {

                    if (NotEmpty(response.productPrices)) {
                        setIsStockProduct(IsTrue(response.productPrices[0].isStock));
                    }

                    setProductOptionsData(response.productPrices);
                    setProductOptionsPriceData([...new Map(response.productPrices.map(item => [item.productId, item])).values()]);
                    success = true;

                    //wait for data load                    
                    setTimeout(() => {
                        selectFirstColorRow();
                    }, 500);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        if (IsFalse(success)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    function handleSearchOpts(e) {
        var api = gridRefOpts.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    const cellClickedProd = useCallback((params) => {
        SetSelectedProductOptions(params.node, params.data);
    }, []);

    function selectFirstColorRow() {
        //autoselect first result after search
        var nodesOpts = gridRefOpts.current.api.getRenderedNodes();
        SetSelectedProductOptions(nodesOpts[0], nodesOpts[0] && nodesOpts[0].data);
    }

    function SetSelectedProductOptions(node, data) {
        var productId = 0;
        var productPriceId = 0;
        var productSkuId = 0;
        var hasBundles = false;

        if (node && data) {
            productId = data.productId;
            productPriceId = data.productPriceId;
            productSkuId = data.productSkuId;
            hasBundles = data.hasBundles;

            node.setSelected(true);
            setSelectedProductOptions(data);
        } else {
            setSelectedProductOptions([]);
        }

        //set to use when adding line in order
        if (IsOrderPageType) {
            var productStockId = getOrderStoreProductStockId(data);
            setHfProductIds(productId, productPriceId, productStockId, productSkuId, hasBundles);
        }
    }

    function onOptsRowDoubleClicked(data) {
        if (data) {
            var productId = data.productId;
            var productPriceId = data.productPriceId;
            var productSkuId = data.productSkuId;
            var productStockId = getOrderStoreProductStockId(data);
            var hasBundles = data.hasBundles;

            if (IsOrderPageType) {
                setHfProductIds(productId, productPriceId, productStockId, productSkuId, hasBundles);
                ClickById("btnSelectProduct");

                //var submit = true;
                //if (data.hasBundles) {
                //    if (window.confirm("Add Products from Bundles?")) {
                //        submit = false;
                //        onOpenProductEditModal(productId, productPriceId, productSkuId);
                //    }
                //}
                //if (IsTrue(submit)) {
                //    ClickById("btnSelectProduct");
                //}
            } else {
                //loadProductData(productId);
                onOpenProductEditModal(productId, productPriceId, productSkuId);
            }
        }
    }

    function addProduct() {
        //var submit = true;
        var productId = GetValueById("hfSelectProductId");
        var productPriceId = GetValueById("hfSelectProductPriceId");
        var productSkuId = GetValueById("hfSelectProductSkuId");

        //var productStockId = GetValueById("hfSelectProductStockId");
        //SetValueById("hfSelectBundleProductPriceIds", "");

        //var nodes = gridRefOpts.current.api.getRenderedNodes();
        //if (NotEmpty(nodes)) {

        //    var data = nodes[0].data;
        //    if (Exists(data) && IsTrue(data.hasBundles)) {

        //        if (window.confirm("Add Products from Bundles?")) {
        //            submit = false;
        //            onOpenProductEditModal(productId, productPriceId, productSkuId);
        //        }
        //    }
        //}

        if (IdExists(productId) && IdExists(productPriceId) && IdExists(productSkuId)) {
            ClickById("btnSelectProduct");
        }
    }

    function setHfProductIds(productId, productPriceId, productStockId, productSkuId, productHasBundles) {
        SetValueById("hfSelectProductId", productId);
        SetValueById("hfSelectProductPriceId", productPriceId);
        SetValueById("hfSelectProductSkuId", productSkuId);
        SetValueById("hfSelectProductStockId", productStockId);
        SetValueById("hfSelectProductHasBundles", productHasBundles);
    }

    function getOrderStoreProductStockId(prodPriceData) {
        var productStockId = 0;
        if (currentStoreId !== 0 && prodPriceData && prodPriceData.productStockModels) {
            var psArr = prodPriceData.productStockModels.filter((element) => {
                return element.storeId === currentStoreId;
            });

            if (psArr && psArr.length === 1) {
                var ps = psArr[0];
                if (Exists(ps)) {
                    productStockId = ParseIntSafe(ps.productStockId);
                }
            }
        }
        return productStockId;
    }


    /** Page Load **/
    useEffect(() => {
        initFlowbite();
        getStoreQtyHeader()

        if (IsSalesPageType) {
            //dont set titles for embedded page
            setDocTitle("Lumina - Product Pricing");
            SetPageTitle("Product Pricing");
        }
        //console.log(productOptionsData)
    }, []);


    if (error === true)
        return <div className="text-red-500 text-center py-[10%] text-xl">{defaultErrMsg()}</div>;

    return (
        <div className="w-full">
            <div>
                <div>
                    <div className="float-left">
                        <div className="table-cell">
                            <label for="search"
                                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input autoFocus type="text" id="tbSearch" placeholder="Search"
                                    onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                    className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                             border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"></input>
                                <div id="selectedRows" />
                            </div>
                            <div className="mt-1 mb-1 text-sm">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>
                        <div className="mb-2 pointer-events-hand pl-3 table-cell">
                            <button id="btnProductSearch" onClick={(e) => refreshCache(e)}
                                type="button" className="btn-search !m-0">
                                Search {IsTrue(propsIsInventoryOrder) ? " Stock" : " Products"}
                            </button>
                        </div>
                    </div>
                    {IsOrderPageType === true &&
                        <div className="float-right">
                            <button type="button" id="btnCreateCustomItem" className="btn-search table-cell pl-2"
                                onClick={(e) => ClickById("btnCreateCustom")}>
                                Create Custom Item
                            </button>
                        </div>
                    }
                </div>
            </div>

            <div className="grid grid-cols-2 gap-2 clear">
                <div className="ag-theme-alpine ag-grid-act" style={{ height: gridHeight, width: '100%', }}>

                    <AgGridReact id="gridProducts"
                        rowData={rowData}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        onCellClicked={cellClicked}
                        rowModelType={rowModelType}
                        cacheBlockSize={pageRows}
                        onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}
                    //includeHiddenColumnsInQuickFilter={true}
                    />
                </div>

                <div>
                    <div className="ag-theme-alpine ag-grid-act" style={{ height: gridHeight, width: '100%', }}>
                        <AgGridReact id="gridOptions"
                            rowData={productOptionsData}
                            ref={gridRefOpts}
                            gridOptions={gridOptions2}
                            columnDefs={columnDefsOpt}
                            onCellClicked={cellClickedProd}
                            onRowDoubleClicked={(e) => onOptsRowDoubleClicked(e.data)} />
                    </div>

                    <div className="ag-theme-alpine ag-pricing-layout ag-grid-act"
                        style={{ height: '83px', width: '100%', }}>
                        <AgGridReact id="pricingLayout"
                            rowData={productOptionsPriceData}
                            columnDefs={columnsPricingLayout}
                            gridOptions={gridOptionsPricingLayout}
                        />
                    </div>
                </div>
            </div>

            {/*<div className="clear">*/}
            {/*  <div className="inline-block w-[55%] pr-2">*/}
            {/*    <div style={containerStyle}>*/}
            {/*      <div className="ag-theme-alpine" style={{height: gridHeight, width: '100%',}}>*/}

            {/*        /!*<AgGridReact id="gridProducts"*!/*/}
            {/*        /!*             rowData={rowData}*!/*/}
            {/*        /!*             ref={gridRef}*!/*/}
            {/*        /!*             gridOptions={gridOptions}*!/*/}
            {/*        /!*             columnDefs={columnDefs}*!/*/}
            {/*        /!*             onGridReady={onGridReady}*!/*/}
            {/*        /!*             onCellClicked={cellClicked}*!/*/}
            {/*        /!*             rowModelType={rowModelType}*!/*/}
            {/*        /!*             cacheBlockSize={pageRows}*!/*/}
            {/*        /!*             onRowDoubleClicked={(e) => onProductRowDoubleClicked(e.data)}*!/*/}
            {/*        /!*          //includeHiddenColumnsInQuickFilter={true}*!/*/}
            {/*        /!*          />*!/*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="inline-block w-[45%]">*/}
            {/*    <div style={containerStyle} className="">*/}
            {/*      <div className="ag-theme-alpine" style={{height: gridHeight, width: '100%',}}>*/}
            {/*        /!*Quick Filter Search*!/*/}
            {/*        /!*Per CFM: dont show search for colors grid*!/*/}
            {/*        {false &&*/}
            {/*          <div>*/}
            {/*            <div className="table-cell">*/}
            {/*              <label for="search"*/}
            {/*                     className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>*/}
            {/*              <div className="relative">*/}
            {/*                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
            {/*                  <svg className="w-4 h-4 text-gray-500" aria-hidden="true"*/}
            {/*                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">*/}
            {/*                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                          stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>*/}
            {/*                  </svg>*/}
            {/*                </div>*/}

            {/*                <input onChange={handleSearchOpts} type="search" id="search" placeholder="Search..."*/}
            {/*                       className="orders-filter-search @apply block p-2 mb-2 pl-10 text-sm text-gray-900 */}
            {/*                                              border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;"></input>*/}
            {/*                <div id="selectedRows"/>*/}
            {/*              </div>*/}
            {/*              <div className="mt-1 mb-1">*/}
            {/*                &nbsp;*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        }*/}

            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {IsOrderPageType &&
                <div className="btnsAddProductsSalesGrid">
                    <button type="button" id="btnSalesAddProductsBtn" className="btn-submit-modal mr-2"
                        onClick={(e) => addProduct(e)}>
                        Select Product
                    </button>
                </div>
            }

            <Modal ref={productStockModalRef} className="product-modal min-w-[600px] py-3 px-4">
                <Modal.Body>
                    <div className="min-h-[200px]">
                        {productStockModalContent}
                    </div>
                    <div className="align-bottom text-right pt-[10px]">
                        {/*<div className={"inline-block mr-3 " + messageClass}>*/}
                        {/*    {message}*/}
                        {/*</div>*/}
                        <div className="inline-block pb-4">
                            <button type="button" className="btn-close-modal"
                                onClick={(e) => onCloseStockModal(e)}>
                                Close
                            </button>
                            {IsOrderPageType &&
                                <button type="button" id="btnSelectStockProduct" className="btn-submit-modal ml-2"
                                    onClick={(e) => onCloseStockModal(e, true)}>
                                    Select Stock
                                </button>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal ref={productEditModalRef} className={productModalClass}>
                <Button size="sm" color="ghost" shape="circle"
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                    onClick={e => onCloseProductEditModal("")}
                >x</Button>
                <Modal.Body>
                    {productEditModalContent}
                </Modal.Body>
            </Modal>
        </div>
    );
}