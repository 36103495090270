import React from "react";
import {Dropdown} from "flowbite-react";

export default function DropDownButtonDetail({ disable , name, hexColor, dropdownItems, classNames}) {
  return (
    <Dropdown
      label=" " // Puedes intentar con un espacio en blanco
      dismissOnClick={true}
      disabled={disable}
      renderTrigger={() => (
        <button
          style={{backgroundColor: hexColor}}
            className={`flex items-center justify-center px-6 py-0 text-white font-medium rounded-md ` + classNames}>
          {name}
          <svg
            className="ml-2 h-4 w-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      )}
    >
      {dropdownItems}
    </Dropdown>
  )
}