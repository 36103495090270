import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { get, post } from '../../services/apiService.js';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsTrue, NotEmpty, ParseIntSafe, PreventDefault, SetAndClickById
} from '../../js/formHelpers';
import { GetLocationStateId, SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { SetPageTitle } from '../../js/helpers';

export const UserAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina User");
    const location = useLocation();
    const navigate = useNavigate();

    const [userId, setUserId] = useState(GetLocationStateId("userId"));
    const [userData, setUserData] = useState({});
    const [stores, setStores] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesLoading, setRolesLoading] = useState(true);
    const [cardMachines, setCardMachines] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [hireDateDisplay, setHireDateDisplay] = useState('');
    const [birthDateDisplay, setBirthDateDisplay] = useState('');

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const positions = [
        { id: 7, name: 'Accounting' },
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Cabinets' },
        { id: 3, name: 'Expeditor' },
        { id: 4, name: 'Manager' },
        { id: 5, name: 'Sales' },
        { id: 6, name: 'Warehouse' }
    ];

    useEffect(() => {
        if (confirmPassword || userData?.tempPassword) {
            setPasswordsMatch(confirmPassword === userData?.tempPassword);
        } else {
            setPasswordsMatch(true);
        }
    }, [confirmPassword, userData?.tempPassword]);

    useEffect(() => {
        if (userData.hireDate) {
            setHireDateDisplay(formatDateToMMDDYYYY(userData.hireDate));
        }
        if (userData.birthDate) {
            setBirthDateDisplay(formatDateToMMDD(userData.birthDate));
        }
    }, [userData.hireDate, userData.birthDate]);

    // Helpers
    function backToClick(userMessage) {
        navigate('/Users', {
            replace: true,
            state: { useSavedFiltersVendor: true, userMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Methods
    const loadCardMachines = async (storeId) => {
        try {
            if (!storeId) {
                setCardMachines([]);
                return;
            }
            const data = await get(`/cardmachines/GetByStoreId/${storeId}`);
            if (NotEmpty(data)) {
                // Filter out inactive machines
                const activeMachines = data.filter(machine => !machine.inactive);
                setCardMachines(activeMachines);
            }
        } catch (error) {
            console.error("Error loading card machines:", error);
        }
    };

    const loadStores = async () => {
        try {
            const data = await get('/store/GetAllStores');
            if (NotEmpty(data)) {
                // Filter out inactive and deleted stores
                const activeStores = data.filter(store => !store.Inactive && !store.Deleted);
                setStores(activeStores);
                console.log('Stores:', activeStores);
            } else {
                updateMessage("Failed to load stores. Please try again.", "text-red");
            }
        } catch (error) {
            console.error("Error loading stores:", error);
            updateMessage("Failed to load stores. Please try again.", "text-red");
        }
    };

    const formatDateToMMDD = (dateStr) => {
        if (!dateStr) return '';
        try {
            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return '';
            return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        } catch {
            return '';
        }
    };

    const parseMMDDToDate = (mmdd) => {
        if (!mmdd || mmdd.length !== 5) return null;
        const [month, day] = mmdd.split('/').map(num => parseInt(num, 10));
        if (!month || !day || month < 1 || month > 12 || day < 1 || day > 31) return null;
        const currentYear = new Date().getFullYear();
        return new Date(currentYear, month - 1, day).toISOString();
    };

    const formatDateToMMDDYYYY = (dateStr) => {
        if (!dateStr) return '';
        try {
            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return '';
            return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        } catch {
            return '';
        }
    };

    const parseMMDDYYYYToDate = (mmddyyyy) => {
        if (!mmddyyyy || mmddyyyy.length !== 10) return null;
        const [month, day, year] = mmddyyyy.split('/').map(num => parseInt(num, 10));
        if (!month || !day || !year || month < 1 || month > 12 || day < 1 || day > 31) return null;
        return new Date(year, month - 1, day).toISOString();
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        let newValue = value.replace(/\D/g, '');

        if (name === 'birthDate') {
            if (newValue.length > 4) {
                newValue = newValue.slice(0, 4);
            }

            let displayValue = newValue;
            if (newValue.length >= 2) {
                displayValue = newValue.slice(0, 2) + '/' + newValue.slice(2);
            }

            setBirthDateDisplay(displayValue);
            if (newValue.length === 4) {
                const dateValue = parseMMDDToDate(displayValue);
                if (dateValue) {
                    setUserData(prev => ({ ...prev, birthDate: dateValue }));
                }
            }
        } else if (name === 'hireDate') {
            if (newValue.length > 8) {
                newValue = newValue.slice(0, 8);
            }

            let displayValue = newValue;
            if (newValue.length >= 2) {
                displayValue = newValue.slice(0, 2) + '/';
                if (newValue.length >= 4) {
                    displayValue += newValue.slice(2, 4) + '/';
                    if (newValue.length > 4) {
                        displayValue += newValue.slice(4);
                    }
                } else {
                    displayValue += newValue.slice(2);
                }
            }

            setHireDateDisplay(displayValue);
            if (newValue.length === 8) {
                const dateValue = parseMMDDYYYYToDate(displayValue);
                if (dateValue) {
                    setUserData(prev => ({ ...prev, hireDate: dateValue }));
                }
            }
        }
    };

    const handleChange = (e) => {
        const data = handleFieldChange(e);

        if (data) {
            if (data.name === 'defaultStoreId') {
                // Load machines for store
                setUserData((prevData) => ({
                    ...prevData,
                    [data.name]: data.value,
                    cardMachineId: null
                }));
                loadCardMachines(data.value);
            } else if (data.name === 'roles') {
                const roleId = parseInt(e.target.value, 10);
                const isChecked = e.target.checked;

                setUserData((prevData) => {
                    const updatedUserRoles = isChecked
                        ? [...(prevData.userRoles || []), { roleId, user: prevData, role: { roleId } }]
                        : (prevData.userRoles || []).filter((role) => role.roleId !== roleId);

                    const updatedRoleIds = isChecked
                        ? [...(prevData.roleIds || []), roleId]
                        : (prevData.roleIds || []).filter((id) => id !== roleId);

                    return {
                        ...prevData,
                        userRoles: updatedUserRoles,
                        roleIds: updatedRoleIds,
                    };
                });
            } else if (data.name === 'cardMachineId') {
                const value = data.value === '' ? null : data.value;
                setUserData((prevData) => ({
                    ...prevData,
                    [data.name]: value
                }));
            } else if (data.name === 'userPositionId') {
                const value = data.value === '' ? null : parseInt(data.value, 10);
                console.log('Position:', value);
                setUserData((prevData) => {
                    const newData = {
                        ...prevData,
                        userPositionId: value,
                        position: value !== null ? value : null
                    };
                    console.log('Updated User Data:', newData);
                    return newData;
                });
            } else {
                setUserData((prevData) => ({
                    ...prevData,
                    [data.name]: data.value,
                }));
            }
        } else {
            updateMessage(defaultErrMsg, 'text-red', 'null handleChange data');
        }
    };

    // Validate and Post Data
    const validateData = () => {
        var valid = true;
        var msg = "";

        // Validate passwords if has a value
        if (confirmPassword || userData?.tempPassword) {
            if (!passwordsMatch) {
                valid = false;
                msg = "Passwords do not match";
            }
        }

        if (NotEmpty(msg)) {
            updateMessage(msg, "text-red");
        }
        return valid;
    };

    function saveUserMsg(userId) {
        if (IdExists(userId)) {
            return userData.userName + " updated successfully.";
        }
        else {
            return userData.userName + " created successfully.";
        }
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    };

    const handleUserSubmit = async (e) => {
        PreventDefault(e);

        var valid = validateData();
        if (valid === true) {
            var resp = await UpdateUserPOST();

            var iUserId = ParseIntSafe(resp);
            if (IdExists(iUserId)) {
                var msg = saveUserMsg(userId);
                SetAndClickById("lnkReloadPage", "/Redirect?route=Users&id=" + iUserId + "&message=" + msg);
            }
            else {
                console.error("handleUserSubmit(): ", JSON.stringify(resp));
                alert(defaultErrMsg);
            }
        }
    }

    async function UpdateUserPOST() {
        // Selected RoleIds
        const roleIds = userData.userRoles.map(role => role.roleId) || [];
        
        const { position, ...userDataWithoutPosition } = userData;
        
        const postData = {
            ...userDataWithoutPosition,
            RoleIds: roleIds,
            userPositionId: userData.userPositionId
        };

        var postModel = JSON.stringify(postData);
        var response = await StandardDataAndResponsePOST(postModel, "user/SaveUser", "UpdateUserPOST()");
        return response;
    }

    // Load Data
    const loadRoles = async () => {

        setRolesLoading(true);
        var data = await StandardIdAndResponseGET("", '/user/getroles', "User - loadRoles()");
        if (NotEmpty(data)) {
            setRoles(data);
        }
        else {
            updateMessage("Failed to load roles. Please try again.", "text-red");
        }
        setRolesLoading(false);
    }

    const loadUserData = async () => {
        if (!userId) {
            // Default values for new user
            setLoading(false);
            setUserData({
                isRegistered: true,
                inactive: false,
                userRoles: [],
                roleIds: [],
                userPositionId: null  // Set to null for new users
            });

            setDocTitle("Lumina Create User");
            SetPageTitle("Create User");
            return;
        }

        var data = await StandardIdAndResponseGET(userId, "user/GetUser/", "loadUserData()");
        console.log('UserData:', data);
        if (Exists(data)) {
            setLoading(false);
            setUserData(data);

            var docT = "Lumina Create User";
            var t = "Create User";

            var iUserId = ParseIntSafe(data.userId);
            if (IdExists(iUserId)) {
                docT = "Lumina User - " + data.userName;
                t = "User - " + data.userName;
            }
            setDocTitle(docT);
            SetPageTitle(t);
        }
        else {
            setError(true);
        }
    }

    function showMessage() {
        if (location.state && NotEmpty(location.state.userMessage)) {
            updateMessage(location.state.userMessage, "text-green");
        }
    }

    useEffect(() => {
        showMessage();
        loadUserData();
        loadRoles();
        loadStores();
    }, []);

    useEffect(() => {
        if (userData?.defaultStoreId) {
            loadCardMachines(userData.defaultStoreId);
        }
    }, [userData]);

    if (IsTrue(loading) || rolesLoading) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    if (IsTrue(error)) {
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;
    }

    return (
        <div className="container mx-auto p-6">
            {userData && (
                <form onSubmit={handleUserSubmit}>
                    <div className="bg-white rounded-lg shadow-sm">
                        {/* Header */}
                        <div className="px-6 py-4 border-b">
                            <h2 className="text-xl font-semibold text-gray-900">
                                {IdExists(userId) ? 'Edit User' : 'Create User'}
                            </h2>
                        </div>

                        {/* Form Content */}
                        <div className="p-6">
                            <div className="grid grid-cols-3 gap-8">
                                {/* Left Column - Text Inputs */}
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="userName" className="block text-sm font-medium text-gray-700 mb-1">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userName"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.userName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="ext" className="block text-sm font-medium text-gray-700 mb-1">
                                            Extension
                                        </label>
                                        <input
                                            type="text"
                                            id="ext"
                                            name="ext"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.ext}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="tempPassword" className="block text-sm font-medium text-gray-700 mb-1">
                                            Password
                                        </label>
                                        <input
                                            type="text"
                                            id="tempPassword"
                                            name="tempPassword"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.tempPassword}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                                            Confirm Password
                                        </label>
                                        <input
                                            type="text"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            className={`w-full h-9 px-3 rounded border ${!passwordsMatch ? 'border-red-500' : 'border-gray-300'
                                                } focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent`}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        {!passwordsMatch && (
                                            <p className="text-red-500 text-sm mt-1">Passwords do not match</p>
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="hireDate" className="block text-sm font-medium text-gray-700 mb-1">
                                            Hire Date
                                        </label>
                                        <input
                                            type="text"
                                            id="hireDate"
                                            name="hireDate"
                                            placeholder="MM/DD/YYYY"
                                            maxLength="10"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={hireDateDisplay}
                                            onChange={handleDateChange}
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="birthDate" className="block text-sm font-medium text-gray-700 mb-1">
                                            Birth Date
                                        </label>
                                        <input
                                            type="text"
                                            id="birthDate"
                                            name="birthDate"
                                            placeholder="MM/DD"
                                            maxLength="5"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={birthDateDisplay}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>

                                {/* Middle Column - Dropdowns and Checkboxes */}
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="userPositionId" className="block text-sm font-medium text-gray-700 mb-1">
                                            Position
                                        </label>
                                        <select
                                            id="userPositionId"
                                            name="userPositionId"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.userPositionId || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Position</option>
                                            {positions.map(position => (
                                                <option key={position.id} value={position.id}>
                                                    {position.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="defaultStoreId" className="block text-sm font-medium text-gray-700 mb-1">
                                            Default Store
                                        </label>
                                        <select
                                            id="defaultStoreId"
                                            name="defaultStoreId"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.defaultStoreId || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Store</option>
                                            {stores.map(store => (
                                                <option key={store.storeId} value={store.storeId}>
                                                    {store.displayName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="cardMachineId" className="block text-sm font-medium text-gray-700 mb-1">
                                            Assigned Payment Terminal
                                        </label>
                                        <select
                                            id="cardMachineId"
                                            name="cardMachineId"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.cardMachineId || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="">-Unassigned-</option>
                                            {cardMachines.map(machine => (
                                                <option key={machine.cardMachineId} value={machine.cardMachineId}>
                                                    {machine.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="space-y-2 pt-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                id="isRegistered"
                                                name="isRegistered"
                                                checked={userData.isRegistered}
                                                onChange={handleChange}
                                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                            />
                                            <span className="ml-2 text-sm font-medium text-gray-700">Registered</span>
                                        </label>

                                        <div>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="checkbox"
                                                    id="inactive"
                                                    name="inactive"
                                                    checked={userData.inactive}
                                                    onChange={handleChange}
                                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                                />
                                                <span className="ml-2 text-sm font-medium text-gray-700">Inactive</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Right Column - Roles */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Roles
                                    </label>
                                    <div className="grid grid-cols-1 gap-1">
                                        {roles.length > 0 ? (
                                            roles.map(role => (
                                                <label
                                                    key={role.roleId}
                                                    className="inline-flex items-center py-0.5"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={`role-${role.roleId}`}
                                                        name="roles"
                                                        value={role.roleId}
                                                        checked={userData.userRoles?.some(userRole => userRole.roleId === role.roleId) || false}
                                                        onChange={handleChange}
                                                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                                    />
                                                    <span className="ml-2 text-sm text-gray-700">{role.roleDescription}</span>
                                                </label>
                                            ))
                                        ) : (
                                            <div className="text-sm text-gray-500">No roles available</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="px-6 py-4 bg-gray-50 border-t rounded-b-lg flex items-center justify-between">
                            <div className={messageClass}>{message}</div>
                            <div className="flex space-x-2">
                                <button
                                    type="button"
                                    onClick={() => backToClick()}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={(e) => btnSubmitClick(e, true)}
                                    className="px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
        </div>
    );
}