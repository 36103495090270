import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal } from 'react-daisyui';

import { GetStoreSelectList, GetProductCategorySelectList, GetVendorSelectList, GetUnitsSelectList }
    from '../../../js/dataHelpers.js';
import { NotStringContains, StringContains } from '../../../js/helpers.js';
import {
    formatCurrencyDecimal, SetFocusById, handleFieldChange, Exists,
    isSelectedDropdown, FormatStrLength, PreventDefault, NotEmpty,
    IsEmpty, ParseIntSafe, IdExists, IsTrue, IsFalse, EnforceNumDecimalPlaces,
    NotStringEmpty, GetInputDecimalStr
} from '../../../js/formHelpers.js';
import {
    GetQuantityFromWidthLength, GetLengthFromWidthQuantity, GetNewQuantityForMin,
    GetRemainder, GetMultDec, GreaterThan, LessThanEq, Equals
} from '../../../js/calculationHelpers.js';

import { DoMinQtyCheck, GetBoxQtyStr, createAddOlModel } from '../_js/_orderHelpers.js';
import { MinQtyModal } from './MinQtyModal';
import { get, post } from '../../../services/apiService.js';

export const CreateOrderLineContent = (props) => {
    const qtyModal = useRef();
    const loadModalContent = props.loadModalContent;
    const onAddBundles = props.onAddBundles;
    const onCloseModal = props.onCloseModal;

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const propsOrderId = props.orderId ?? 0;
    const propsOrderTypeStr = props.orderTypeStr ?? "";
    const propsStoreId = props.storeId ?? 0;
    const propsPriceLevelStr = props.priceLevelStr ?? "";
    const propsBcMultiplier = props.bcMultiplier ?? 0;
    const propsIsSamplesOrder = props.isSamplesOrder ?? false;

    const propsProductId = props.productId ?? 0;
    const propsProductSkuId = props.productSkuId ?? 0;
    const propsProductPriceId = props.productPriceId ?? 0;
    const propsProductStockId = props.productStockId ?? 0;
    const propsProductType = props.productType ?? "";
    const propsProductHasBundles = props.productHasBundles ?? false;

    const IsProduct = propsProductType === "Products";
    const IsCustomProduct = propsProductType === "Custom";

    const [storeList, setStoreList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);

    const [isEditable, setIsEditable] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [priceDisabled, setPriceDisabled] = useState(false);    
    const [quantityDisabled, setQuantityDisabled] = useState(false);

    const [modalTitle, setModalTitle] = useState(false);
    const [unitsDisabled, setUnitsDisabled] = useState(false);
    const [boxQtyStr, setBoxQtyStr] = useState(false);

    const [isRoll, setIsRoll] = useState(false);    
    const [boxQty, setBoxQty] = useState(0);
    const [stdLength, setStdLength] = useState(0);
    const [units, setUnits] = useState("");
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [quantity, setQuantity] = useState(0);

    //used for min qty adjustment
    const [qtyModalContent, setQtyModalContent] = useState(null);
    const [allowQtyOverride, setAllowQtyOverride] = useState(false);

    const [lineTotal, setLineTotal] = useState(0);
    const [linePrice, setLinePrice] = useState(0);
    const [olData, setOlData] = useState(createAddOlModel());

    /** CFM 4-16-24: dont show Referral **/
    //const [referral, setReferral] = useState();

    const [requiredClass, setRequiredClass] = useState(" hidden ");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const createMsg = "Order Line created successfully.";
    const updateMsg = "Order Line updated successfully.";
    const createErrMsg = "There was an issue creating the line. Please try again or contact support.";


    // TODO: remove
    //const [disableFields, setDisableFields] = useState(false);
    //const [showAddLineForm, setShowAddLineForm] = useState(false);
    //const [linesUpdated, setLinesUpdated] = useState(false);    
    //const [selectedProduct, setSelectedProduct] = useState([]);
    //const [selectedProductPrice, setSelectedProductPrice] = useState([]);
    //const [lengthDisabled, setLengthDisabled] = useState(false);


    // Min Qty Modal
    const handleQtyModalShow = useCallback(() => {
        qtyModal.current?.showModal();
    }, [qtyModal]);

    const handleQtyModalHide = useCallback(() => {
        qtyModal.current?.close();
        SetFocusById("linePrice");
    }, [qtyModal]);


    //Helper Methods

    function updateMessage(msg, cssClass) {
        setMessage(msg);
        setMessageClass(cssClass);
    }

    async function createStoreList() {
        const list = [];
        //list.push(<option key="0" value="0">None</option>);

        const dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        setStoreList(list);
    }

    async function createProductCategoryList() {
        const list = [];
        list.push(<option key="0" value="0">None</option>);

        const dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        setCategoryList(list);
    }

    async function createVendorSupplierList() {
        const list = [];
        list.push(<option key="0" value="0">None</option>);

        const dataList = await GetVendorSelectList(1);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });
        //set page fields
        setVendorList(list);

        // TODO: remove
        //setShowAddLineForm(true);
    }

    async function createUnitsList(units) {
        const list = [];
        list.push(<option key="0" value="">None</option>);

        let createForValue = NotStringEmpty(units);
        const dataList = await GetUnitsSelectList();

        let cnt = 0;
        dataList.forEach((item, index) => {
            list.push(<option key={cnt + 1} value={item.value}>{item.text}</option>);
            cnt++;

            if (IsTrue(createForValue)) {
                createForValue = NotStringContains(item.text, units);
            }
        });

        if (IsTrue(createForValue)) {
            list.push(<option key={cnt + 1} value={units}>{units}</option>);
        }

        setUnitsList(list);
    }

    function updateBoxQtyStr(model) {

        let sellQty = olData.sellQuantity;
        let sellUnit = olData.sellUnit;
        if (Exists(model)) {
            sellQty = model.sellQuantity;
            sellUnit = model.sellUnit;
        }

        const bxQtyStr = GetBoxQtyStr(sellQty, sellUnit, model.units);
        setBoxQtyStr(bxQtyStr);
        setBoxQty(sellQty);
    }

    //Methods

    function returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal) {
        return <MinQtyModal minQuantity={minQuantity} newQuantity={newQuantity}
            onCloseModal={onCloseQtyModal}></MinQtyModal>;
    }

    function onOpenQtyModal(minQuantity, newQuantity) {
        //updateMessage("", "");

        setQtyModalContent(returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal));
        handleQtyModalShow();
    }

    function onCloseQtyModal(e, allowAdj, newQuantity) {
        PreventDefault(e);

        if (allowAdj) {
            //set adjusted qty for min sell qty
            setQuantity(newQuantity);
            UpdatePricesAndTotals(newQuantity, null, null, null);
            updateMessage("Quantity and totals have been adjusted.", "text-red");
        }
        else {
            //set allow qty override for submit
            setAllowQtyOverride(true);
        }
        handleQtyModalHide();
    }

    function DisableSubmit(e, cQuantity, cAllowQtyOverride) {
        PreventDefault(e);

        const minQtyRem = DoMinQtyCheck(cQuantity, cAllowQtyOverride, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            setSubmitDisabled(true);
            setCloseDisabled(true);
        }
        else {
            setSubmitDisabled(false);
            setCloseDisabled(false);
        }
    }

    function CheckMinQuantity(e) {
        PreventDefault(e);
        setSubmitDisabled(false);
        setCloseDisabled(false);

        if (IsFalse(olData.isCustom) || IsTrue(propsIsSamplesOrder)) {
            const minQtyRem = DoMinQtyCheck(null, null, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
            if (minQtyRem !== 0) {
                const newQuantity = GetNewQuantityForMin(olData.sellQuantity, quantity);
                //open min qty notification modal
                onOpenQtyModal(olData.sellQuantity, newQuantity);
                return true;
            }
        }
        return false;
    }

    function validateSave() {
        updateMessage();
        let valid = true;
        const msg = "";

        if (LessThanEq(quantity, 0)) {
            valid = false;
        }

        if (IsTrue(olData.isCustom)) {
            if (isSelectedDropdown(olData.productCategoryId) !== true) {
                valid = false;
            }
            if (isSelectedDropdown(olData.vendorId) !== true) {
                valid = false;
            }
            if (IsEmpty(olData.style)) {
                valid = false;
            }
            if (IsEmpty(olData.color)) {
                valid = false;
            }

            //if (isRoll) {
            //}
        }

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    }

    async function addLineAndNext(e, addNext) {
        PreventDefault(e);
        setRequiredClass(" hidden ");

        if (IsFalse(submitDisabled)) {

            // only validate data if saving
            // always save for create line
            const save = true;
            let valid = true;
            if (save === true) {
                valid = validateSave();
            }

            // if not valid stop all actions
            // error messages displayed
            if (valid === true) {

                //setLinesUpdated(true);
                const success = await addOrderLine();
                if (success === true) {

                    if (IsTrue(propsProductHasBundles) && IsFalse(olData.isCustom)) {
                        onAddBundles(addNext, propsProductId, propsProductPriceId, propsProductSkuId);
                    }
                    else {
                        if (addNext) {
                            //add another
                            loadModalContent(true, "products");
                        }
                        else {
                            //close modal
                            onCloseModal(e, true);
                        }
                    }
                }
                else {
                    updateMessage(createErrMsg, "text-red");
                }
            }
        }
    }

    const handleChange = (e) => {


        setMessage("");
        const data = handleFieldChange(e);

        if (data) {
            // individual fields
            if (data.name === "units") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);
                setUnits(data.value);
            }
            else if (data.name === "width") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);
                UpdateForWidthLengthQuantity(data.value, null, null);
            }
            else if (data.name === "length") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);
                UpdateForWidthLengthQuantity(null, data.value, null);
            }
            else if (data.name === "quantity") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);
                setSubmitDisabled(true);
                DisableSubmit(e, data.value, false);

                //reset allow override for submit on qty change
                setAllowQtyOverride(false);
                UpdateForWidthLengthQuantity(null, null, data.value);
            }
            else if (data.name === "linePrice") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);
                setLinePrice(data.value);
                UpdatePricesAndTotals(quantity, data.value, null, null);
            }
            else {
                // cost
                if (data.name === "cost") {
                    data.value = EnforceNumDecimalPlaces(data.value, 2);
                    UpdatePricesAndTotals(null, null, data.value, null);
                }
                // multiplier
                if (data.name === "multiplier") {
                    data.value = EnforceNumDecimalPlaces(data.value, 3);
                    UpdatePricesAndTotals(null, null, null, data.value);
                }
                // comments
                if (data.name === "comments") {
                    data.value = FormatStrLength(data.value, 50);
                }
                // productCategoryId
                if (data.name === "productCategoryId") {
                    if (data.value === "1" || data.value === "2") {
                        setUnits("SY");
                        setIsRoll(true);

                        // TODO: remove
                        //setUnitsDisabled(true);
                    }
                    else {
                        setIsRoll(false);

                        // TODO: remove
                        //setUnitsDisabled(false);
                        if (units === "SY") {
                            setUnits("");
                        }
                        else {
                            //dont clear units if not switching from Rolls
                        }
                    }
                }

                //updateMessage("", "");
                setOlData((olData) => ({
                    ...olData,
                    [data.name]: data.value
                }));
            }
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    }

    function UpdateForWidthLengthQuantity(cWidth, cLength, cQuantity) {

        //update roll fields based on changed
        if (isRoll === true) {
            //quantity always set here for rolls

            if (cWidth !== null) {
                //set changed value
                setWidth(cWidth);
                //set calc values for non-changed fields
                cLength = length ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cLength !== null) {
                //set changed value
                setLength(cLength);
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cQuantity !== null) {
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cLength = GetLengthFromWidthQuantity(cWidth, cQuantity, "SY")
                setLength(GetInputDecimalStr(cLength));
            }
        }

        //quantity always updated
        setQuantity(cQuantity);
        //update pricing and totals
        UpdatePricesAndTotals(cQuantity, null, null, null);
    }

    function UpdatePricesAndTotals(cQuantity, cLinePrice, cCost, cMultiplier) {
        //re-calc line price when cost/multiplier changed
        let reCalcLinePrice = false;

        if (cQuantity === null) {
            cQuantity = quantity;
        }
        if (cLinePrice === null) {
            cLinePrice = linePrice;
        }
        //re-calc line price when cost changed
        if (cCost === null) {
            cCost = olData.cost;
        }
        else {
            reCalcLinePrice = true;
        }
        //re-calc line price when multiplier changed
        if (cMultiplier === null) {
            cMultiplier = olData.multiplier;
        }
        else {
            reCalcLinePrice = true;
        }

        // TODO: make 2 decimal places
        //cMultiplier = formatCurrencyDecimal(cMultiplier);
        //cCost = formatCurrencyDecimal(cCost);

        olData.cost = cCost;
        olData.multiplier = cMultiplier;
        setOlData(olData);

        //update for changed fields
        if (reCalcLinePrice === true) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            cLinePrice = GetMultDec(bcPrice, propsBcMultiplier);
            setLinePrice(GetInputDecimalStr(cLinePrice));
        }

        //always update LineTotal
        const lt = GetMultDec(cQuantity, cLinePrice);
        setLineTotal(GetInputDecimalStr(lt));

        /** CFM 4-16-24: dont show Referral **
        //only calculate bc and referral for custom items
        if (IsTrue(olData.isCustom)) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            const priceReferral = cLinePrice - bcPrice;
            const referral = GetMultDec(priceReferral, cQuantity);
            setReferral(referral);
        }*/
    }

    function createOrderLineModel(productData) {
        let model = null;

        //productData can be sent for Product or Custom types
        //set from selected Product if exists for type
        if (productData) {
            if (productData.productPrices && productData.productPrices[0]) {
                const productPriceData = productData.productPrices[0];
                model = createAddOlModel(propsProductType, propsStoreId, propsOrderId,
                    productData, productPriceData);

                let bcPrice = model.price;
                if (propsBcMultiplier > 0) {
                    bcPrice = bcPrice * propsBcMultiplier;
                }
                setLinePrice(GetInputDecimalStr(bcPrice));
            }
        }
        else {
            model = createAddOlModel(propsProductType, propsStoreId, propsOrderId);
        }

        //set page fields and data model
        updateBoxQtyStr(model);
        setIsRoll(model.isRoll);

        setUnits(model.units);
        createUnitsList(model.units)

        setWidth(model.width ?? 0);
        setStdLength(model.length ?? 0);
        setQuantity(model.quantity);

        if (IsProduct) {
            //set 'Stock Item' id and status if Stock Product
            model.productStockId = propsProductStockId;
            if (IdExists(propsProductStockId)) {
                model.statusId = 15;

                setModalTitle("Add Stock Line");
                //setDisableFields(true);
            }
            else {
                setModalTitle("Add Product Line");
                //setDisableFields(true);
            }
        }
        else if (IsCustomProduct) {
            //set default Units for Rolls
            if (model.isRoll === true) {
                setUnits("SY");
                setUnitsDisabled(true);
            }
            else {
                //TODO: set default units for Items?
                //setUnits("EA");
            }

            model.isCustom = true;
            setModalTitle("Add Custom Line");
            setPriceDisabled(true);
            //setDisableFields(false);
        }
        else {
            //TODO: show error message - should not occur
            debugger;
        }

        //set Samples Order line values
        if (IsTrue(propsIsSamplesOrder)) {

            setModalTitle("Add Samples Line");

            model.cost = 0;
            setQuantity(1);
            setQuantityDisabled(true);
            setLinePrice(0);
            setPriceDisabled(true);
            setSubmitDisabled(false);
        }

        //set page model
        setOlData(model);
    }

    function setIndividualFields(model) {
        model.price = linePrice;
        model.units = units;
        model.width = width;
        model.length = length;
        model.quantity = quantity;

        model.productSkuId = propsProductSkuId;
        model.productStockId = propsProductStockId;

        if (!model.cost || model.cost === "") {
            model.cost = 0;
        }
        return model;
    }

    async function addOrderLine() {
        let resp = null;
        let model = null;

        if (olData && olData.orderId !== 0) {
            //validate and set model for type
            if (IsProduct) {

                //TODO: show error messages for missing Ids
                //TODO: remove olData.productId lookup by only productPriceId
                if (olData.productPriceId && olData.productId) {
                    model = olData;
                    setIndividualFields(model);
                }
            }
            else if (IsTrue(olData.isCustom)) {
                model = olData;
                model.isCustom = true;
                setIndividualFields(model);
            }
            //else if (propsProductType === "Stock") {
            //    //model = olData;
            //}
            else {
                //TODO: show error messages for missing type
                //should not occur
                debugger;
            }

            //post model for create
            if (model !== null) {

                const apiUrl = process.env.REACT_APP_API_URL + 'orders/CreateOrderLine';
                const endpoint = 'orders/CreateOrderLine';
                model.userId = userId;
                const postModel = JSON.stringify(model);
                //console.log("addOrderLine apiUrl: " + apiUrl + " \r\n postModel: " + postModel);

                try {
                    const response = await post(endpoint, postModel);
                    if (response.value) {
                        const respData = response.value;

                        if (IsTrue(respData.success)) {
                            const orderLineId = ParseIntSafe(respData.data);
                            if (IdExists(orderLineId)) {
                                resp = true;
                            }
                        }
                        else {
                            console.error("addOrderLine api error: " + respData.error);
                            console.error("apiUrl: " + apiUrl);
                            resp = respData;
                        }
                    }
                    else {
                        console.error("addOrderLine api error no response.value: " + response);
                        console.error("apiUrl: " + apiUrl);
                        resp = response;
                    }

                } catch (error) {
                    console.log('Error: ' + error);
                }
            }
            else {
                //TODO: should not occur
                console.error("addOrderLine: null model - orderId: " + olData.orderId);
            }
        }
        else {
            //TODO: should not occur
            console.error("addOrderLine: no olData.orderId - propsOrderId: " + propsOrderId);
        }
        return resp;
    }

    async function getProductLineData() {

        //if ProductPrice and ProductPriceId passed in
        if (IdExists(propsProductPriceId) && IdExists(propsProductSkuId)) {
            //remove if lookup by only productPriceId
            if (IdExists(propsProductId)) {

                const endpoint = 'product/GetProductOptions/' +
                    propsProductId + '/' + propsProductPriceId + '/' + propsProductSkuId + '/' + propsProductStockId ?? 0;

                try {
                    const response = await get(endpoint);
                    //console.log('Response: ' + JSON.stringify(response));

                    if (response) {
                        createOrderLineModel(response);
                    }
                    else {
                        console.error("no Product OrderLine data");
                    }
                } catch (error) {
                    console.error('Error fetching Product OrderLine data:', error);
                }
            }
        }
        else {
            //create empty model
            createOrderLineModel();
        }
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            addLineAndNext(e, true);
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        let cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    //function customizeLine(e) {
    //    PreventDefault(e);

    //    setModalTitle("Add Custom Line");
    //    createProductCategoryList();
    //    createVendorSupplierList();

    //    olData.isCustom = true;
    //    setOlData(olData);
    //}

    function getCartons() {
        if (GreaterThan(parseFloat(quantity), 0) && GreaterThan(parseFloat(boxQty), 0)) {
            const cartons = parseFloat(quantity) / parseFloat(boxQty);
            return Number.isInteger(cartons) ? cartons : cartons.toFixed(2);
        }
        return 0;
    }

    function handleCustomizeLine() {
        setIsEditable(prev => {
            const newIsEditable = !prev;
            olData.isCustom = newIsEditable;
            setOlData(olData);
            return newIsEditable;
        });
    }

    // ON LOAD
    useEffect(() => {
        setUserFields();
        createStoreList();
        createProductCategoryList();
        createVendorSupplierList();        
        getProductLineData();

        //if (!IsCustomProduct) {
        //    setShowAddLineForm(true);
        //}        
    }, []);

    return (
        <div className="w-[900px] p-2">
            <div className="flex justify-between pb-2">
                <h1 className="text-secondary font-bold text-xl underline">+ Product Line</h1>

                {(olData?.statusId === 0 || olData?.statusId === 1) &&
                    <button className="bg-primary text-white pb-[2px] px-3 font-medium rounded-md" type="button"
                        onClick={handleCustomizeLine}>Customize
                    </button>
                }
            </div>

            <div className="grid grid-cols-1 gap-8 mt-2 edit-line-form">
                <div className="flex gap-1">
                    <div className="grid grid-cols-1 gap-1 w-[150px]">
                        <span className="font-semibold">Supplier:</span>
                        <span className="font-semibold">Category:</span>
                        <span className="font-semibold">Product:</span>
                        <span className="font-semibold">Color:</span>
                    </div>
                    {
                        !isEditable ?
                            <div className="grid grid-cols-1 gap-2 w-[full]">
                                <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.vendorName}</span>
                                <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.categoryName}</span>
                                <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.style}</span>
                                <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.color}</span>
                            </div>
                            :
                            <div className="grid grid-cols-1 gap-2 w-[50%]">
                                <select id="vendorId" name="vendorId"
                                    className="font-bold rounded-md py-0 pl-1 text-black"
                                    value={olData.vendorId}
                                    onChange={handleChange}
                                >
                                    {vendorList}
                                </select>

                                <select id="productCategoryId" name="productCategoryId"
                                    className="font-bold rounded-md py-0 pl-1 text-black"
                                    onKeyDown={(e) => EnterSave(e)}
                                    onChange={handleChange}
                                    value={olData.productCategoryId}
                                >
                                    {categoryList}
                                </select>

                                <input type="text" id="style" name="style"
                                    className="font-bold rounded-md py-0 pl-1 text-black"
                                    onKeyUp={(e) => EnterSave(e)}
                                    onChange={handleChange}
                                    value={olData.style}
                                />

                                <input type="text" id="color" name="color"
                                    className="font-bold rounded-md py-0 pl-1 text-black"
                                    onKeyUp={(e) => EnterSave(e)}
                                    onChange={handleChange}
                                    value={olData.color}
                                />
                            </div>
                    }
                </div>

                <div className="flex gap-4">
                    <div className="flex gap-2">
                        <div className="grid grid-cols-1 gap-2 w-[130px]">
                            <span className="font-semibold">Quantity:</span>
                            {isEditable &&
                                <span className="font-semibold">Cost:</span>
                            }
                            {isEditable &&
                                <span className="font-semibold">Multiplier:</span>
                            }
                            <span className="font-semibold">Price:</span>
                            <span className="font-semibold">Total:</span>
                        </div>

                        <div className="grid grid-cols-1 gap-2 pr-4">
                            <div className="flex gap-2 rounded-md w-full">
                                <input
                                    id="quantity" name="quantity" type="number" step='0.01'
                                    className="font-semibold rounded-md w-[100px] py-0 px-1 ml-[18px] text-black"
                                    autoFocus
                                    onKeyDown={(e) => EnterSave(e)}
                                    onBlur={(e) => CheckMinQuantity(e)}
                                    onChange={handleChange}
                                    value={quantity}
                                    disabled={quantityDisabled}
                                    tabIndex={1}
                                />
                            </div>

                            {isEditable &&
                                <div className="flex gap-2 rounded-md w-full">
                                    <span className="font-bold py-0">$</span>
                                    <input
                                        id="cost" name="cost" type="number" step='0.01'
                                        className="font-semibold rounded-md w-[100px] py-0 px-1 text-black"
                                        onKeyDown={(e) => EnterSave(e)}
                                        onBlur={(e) => GetInputDecimalStr(e.target.value)}
                                        onChange={handleChange}
                                        value={olData.cost}
                                    />
                                </div>
                            }

                            {isEditable &&
                                <div className="flex gap-2 rounded-md w-full">
                                    <input
                                        id="multiplier" name="multiplier" type="number" step='0.001'
                                        className="font-semibold rounded-md w-[100px] py-0 px-1 ml-[18px] text-black"
                                        onKeyUp={(e) => EnterSave(e)}
                                        onBlur={(e) => GetInputDecimalStr(e.target.value, 3)}
                                        onChange={handleChange}
                                        value={olData.multiplier}
                                    />
                                </div>
                            }

                            <div className="flex gap-2 rounded-md w-full">
                                <span className="font-bold py-0">$</span>
                                <input
                                    id="linePrice" name="linePrice" type="number" step='0.01'
                                    className="font-semibold rounded-md w-[100px] py-0 px-1 text-black"
                                    onKeyUp={(e) => EnterSave(e)}
                                    onBlur={(e) => GetInputDecimalStr(e.target.value)}
                                    onChange={handleChange}
                                    value={linePrice}
                                    disabled={priceDisabled || !isEditable} />
                            </div>

                            <div className="flex gap-2 rounded-md w-full">
                                <span className="font-bold py-0">$</span>
                                <input
                                    id="total" name="total" type="number" step='0.01'
                                    className="font-semibold rounded-md w-[100px] py-0 px-1 text-black"
                                    value={GetInputDecimalStr(lineTotal)}
                                    disabled />
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-2">
                        <div className="grid grid-cols-1 gap-2">
                            <span className="font-semibold">UOM:</span>
                            <span className="font-semibold">Price Level:</span>
                            <span className="font-semibold">Weight:</span>
                            {isEditable && <span>&nbsp;</span>}
                            {isEditable && <span>&nbsp;</span>}
                        </div>

                        <div className="grid grid-cols-1 gap-2">
                            {
                                !isEditable ?
                                    <span className="font-bold text-black">{units}</span>
                                    :
                                    <select id="units" name="units"
                                        className="font-bold rounded-md py-0 pl-1 text-black w-[100px] h-[26px]"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={units}
                                        onChange={handleChange}
                                    >
                                        {unitsList}
                                    </select>
                            }
                            <span className="font-bold text-black">{propsPriceLevelStr}</span>
                            <span className="font-bold w-[100px]">{GetInputDecimalStr(olData?.pcWeight * quantity)}</span>
                            {isEditable && <span>&nbsp;</span>}
                            {isEditable && <span>&nbsp;</span>}
                        </div>
                    </div>

                    <div className="flex gap-1">
                        <div className="grid grid-cols-1 gap-2">
                            {!isRoll &&
                                <span className="font-semibold">Carton Qty:</span>}
                            {isRoll &&
                                <span className="font-semibold">Length:</span>}
                            {isRoll && isEditable &&
                                <span className="font-semibold">Width:</span>}

                            {IsTrue(olData?.isStock) && //Equals(olData.statusId, 15) &&
                                <span className="font-semibold">Stock Qty:</span>
                            }
                            {IsTrue(olData?.isStock) && <span>&nbsp;</span>}

                            {isRoll && isEditable && <span>&nbsp;</span>}
                            {isRoll && isEditable && <span>&nbsp;</span>}
                            {isRoll && isEditable && <span>&nbsp;</span>}

                        </div>

                        <div className="grid grid-cols-1 gap-2">
                            {!isRoll &&
                                <span className="font-bold w-[100px]">{GetInputDecimalStr(olData?.sellQuantity)}</span>}
                            {isRoll &&
                                <input
                                    id="length" name="length" type="number" step='0.01'
                                    className="font-semibold rounded-md py-0 px-1 text-black w-[80px] h-[26px]"
                                    value={length}
                                    onKeyDown={(e) => EnterSave(e)}
                                    onChange={handleChange}
                                />
                            }
                            {isRoll && isEditable &&
                                <input id="width" name="width" type="number" step='0.01'
                                    className="font-semibold rounded-md py-0 px-1 text-black w-[80px] h-[26px]"
                                    value={width}
                                    onKeyDown={(e) => EnterSave(e)}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                />
                            }

                            {IsTrue(olData?.isStock) && //Equals(olData.statusId, 15) &&
                                <span className="font-bold text-black">{olData?.productStockModel?.quantityAvailable ?? 0}</span>
                            }
                            {IsTrue(olData?.isStock) && <span>&nbsp;</span>}

                            {isRoll && isEditable && <span>&nbsp;</span>}
                            {isRoll && isEditable && <span>&nbsp;</span>}
                            {isRoll && isEditable && <span>&nbsp;</span>}
                        </div>
                    </div>

                    <div className="flex gap-2">
                        <div className="grid grid-cols-1 gap-2">
                            {!isRoll &&
                                <span className="font-semibold">Cartons:</span>
                            }
                            {isRoll && //NotStringEmpty(stdLength) && IsFalse(isEditable))  &&
                                <span className="font-semibold">Std Length:</span>}
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                            {!isRoll &&
                                <span className="font-bold">{GetInputDecimalStr(getCartons())}</span>
                            }
                            {isRoll && //IsFalse(isEditable) && NotStringEmpty(stdLength)) &&
                                <span className="font-bold">{GetInputDecimalStr(stdLength) + " LF"}</span>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex gap-1">
                        <div className="grid grid-cols-1 gap-2 w-[150px]">
                            <span className="font-semibold">Comments:</span>
                            <span className="font-semibold">Ship To WH:</span>
                            <span className="font-semibold">Status:</span>
                        </div>

                        <div className="grid grid-cols-1 gap-2">
                            <input
                                className="font-semibold rounded-md py-0 pl-1 w-[345px] text-black"
                                type="text"
                                id="comments" name="comments" maxLength="50"
                                onKeyDown={(e) => EnterSave(e)}
                                onChange={handleChange}
                                value={olData?.comments}
                                tabIndex={2}
                            />

                            <select
                                id="warehouseId" name="warehouseId"
                                className="font-bold rounded-md py-0 pl-1 w-[200px] text-black"
                                onKeyDown={(e) => EnterSave(e)}
                                value={olData.warehouseId}
                                onChange={handleChange}
                            >
                                {storeList}
                            </select>

                            <select id="statusId" name="statusId"
                                className="font-bold rounded-md py-0 pl-1 w-[200px] text-black"
                                onChange={handleChange}
                                value={olData.statusId}
                            >
                                <option value="0">None</option>
                                {propsOrderTypeStr !== "Quote" &&
                                    <option value="1">To Be Ordered</option>
                                }
                                {/*{propsOrderTypeStr === "Quote" &&*/}
                                {/*    <option value="13">Quote</option>*/}
                                {/*}*/}
                                {propsOrderTypeStr !== "Quote" && IdExists(propsProductStockId) &&
                                    <option value="15">Stock Item</option>
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center text-red text-[16px] pt-3">
                <div className="h-[22px]">{message}</div>
            </div>
            <div className="text-right pt-2">
              <button type="button" className="btn-cancel-modal"
                      disabled={closeDisabled}
                      onClick={(e) => onCloseModal(e, false)}
                      tabIndex={5}>
                Cancel
              </button>
              <button type="button" className="btn-submit-modal ml-2"
                      disabled={submitDisabled}
                      onClick={(e) => addLineAndNext(e, false)}
                      tabIndex={3}>
                Add & Close
              </button>
              <button type="button" className="btn-submit-modal ml-2"
                      disabled={submitDisabled}
                      onClick={(e) => addLineAndNext(e, true)}
                      tabIndex={4}>
                Add & Next
              </button>
            </div>

            {/**** Min Quantity Modal ****/}
            <div className="">
                <Modal ref={qtyModal} className="px-3">
                    <Modal.Body>
                        {qtyModalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}