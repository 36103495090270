import React, {useState, useEffect, useRef, useCallback, useMemo, useLayoutEffect,} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {Alert, Dropdown, Popover} from 'flowbite-react';
import { Modal, Button } from 'react-daisyui';

import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import {
    formatCurrencyDecStr, NotEmpty, IdExists, PreventDefault,
    Exists, IsInt, IsTrue, IsFalse, ParseIntSafe, NotStringEmpty, IsEmpty, defaultErrMsg, CheckEnterKeyPress
} from '../../js/formHelpers.js';
import { get, post } from '../../services/apiService.js';

import {
    SetPageTitle, CloseTab, SetValueById, GetValueById, ClickById, SetHrefById, StringContains, AddClassById
} from '../../js/helpers.js';

import { Equals, GreaterThan, LessThanEq, NotEquals } from '../../js/calculationHelpers.js';
import { GetUserSelectList, IsSuccessStandardResponseAndDataPOST } from '../../js/dataHelpers.js';
import {
    SaveOlDisplayOrder, CreateClaimPost, CreateOrderForQuoteLinesPost,
    AddQuoteLinesToOrderPost, CreateBillingGroupDb, DeleteOrderLinesPost,
    ConvertOrderToQuotePost, ChangeOrderStatusPost
} from './_js/_orderDataHelpers.js';
import {
    createOrderModel, createAccountModel, createJobModel
} from './_js/_orderHelpers.js';

import { AddAccountModal } from './_modals/AddAccountModal';
import { AddVendorModal } from './_modals/AddVendorModal';
import { OrderJobModal } from './_modals/OrderJobModal';
import { PurchaseOrderModal } from './_modals/PurchaseOrderModal';
import { AddOrderLineModal } from './_modals/AddOrderLineModal';
import { MaterialPickupModal } from './_modals/MaterialPickupModal';
import { EditOrderLineModal } from './_modals/EditOrderLineModal';
import { ReceiptsModal } from './_modals/ReceiptsModal';
import { BillingGroupModal } from '../BillingGroup/BillingGroupModal.js';
import { PaymentModal } from '../Payment/_modals/PaymentModal.js';
import { AuditEntriesModal } from './_modals/AuditEntriesModal.js';

import { OrdersGrid } from '../OrderSearch/OrdersGrid';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css';

import DetailBox from "./DetailsBox/DetailBox";
import BodyDetailBox from "./DetailsBox/BodyDetailBox";
import DropDownButtonDetail from "./DetailsBox/DropDownButtonDetail";
import { AdjustModal } from "./_modals/AdjustModal";
import { OrderCreditNotes } from "./OrderCreditNotes";

import { getCardMachinesForUser } from '../../services/paymentService.js';
import './_order.css';

import {HiInformationCircle} from "react-icons/hi";
import {IoInformationCircle} from "react-icons/io5";

export const OrderDetail = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina");
    const location = useLocation();
    const navigate = useNavigate();

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isQuoteOrderSearch, setIsQuoteOrderSearch] = useState(false);

    const locOrderType = GetLocationStateId("orderType");
    const locBillGroupId = GetLocationStateId("billingGroupId");

    const billGroupModalRef = useRef();
    const [billGroupModalContent, setBillGroupModalContent] = useState(null);

    const [activityLogModalContent, setActivityLogModalContent] = useState(null);

    const [isPaymentsModalOpen, setIsPaymentsModalOpen] = useState(false);
    const [paymentModalContent, setPaymentModalContent] = useState(false);
    const [paymentResult, setPaymentResult] = useState(null);

    const [isReceiptsModalOpen, setIsReceiptsModalOpen] = useState(false);
    const [receiptsResult, setReceiptsResult] = useState(null);

    const [userList, setUserList] = useState([]);
    const [accountId, setAccountId] = useState(GetLocationStateId("accountId"));

    const [orderId, setOrderId] = useState(GetLocationStateId("orderId"));
    const [orderData, setOrderData] = useState(null);
    const [orderLines, setOrderLines] = useState([]);

    const [orderLinesMessage, setOrderLinesMessage] = useState("");
    const [orderLinesMessageClass, setOrderLinesMessageClass] = useState("");

    const [addLinesDisabled, setAddLinesDisabled] = useState(true);
    const [canEmailSubmittal, setCanEmailSubmittal] = useState(false);
    const [canCreatePo, setCanCreatePo] = useState(false);
    const [canEditPo, setCanEditPo] = useState(false);

    const [cardMachines, setCardMachines] = useState([]);
    const [billingGroupId, setBillingGroupId] = useState(0);
    const [accountData, setAccountData] = useState(null);
    const [claimVendorData, setClaimVendorData] = useState(null);

    const [materialPickupClass, setMaterialPickupClass] = useState("");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    //const [jobBoxClass, setJobBoxClass] = useState("");
    //const [jobMessage, setJobMessage] = useState("");
    //const [jobMessageClass, setJobMessageClass] = useState("");

    //const [accountMessage, setAccountMessage] = useState("");
    //const [accountMessageClass, setAccountMessageClass] = useState("");

    //const [claimVendorMsg, setClaimVendorMsg] = useState("");
    //const [claimVendorMsgClass, setClaimVendorMsgClass] = useState("");

    //const [submitDisabled, setSubmitDisabled] = useState("");
    //const [reloadOrderUrl, setReloadOrderUrl] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalClass, setModalClass] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [quoteOrdersModalContent, setQuoteOrdersModalContent] = useState(null);
    const [jobModalContent, setJobModalContent] = useState(null);

    const [openOrderJobModal, setOpenOrderJobModal] = useState(false);
    const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
    const [openAddVendorModal, setOpenAddVendorModal] = useState(false);
    const [openAdjustPaymentModal, setOpenAdjustPaymentModal] = useState(false);
    const [currentAmountReceipt, setCurrentAmountReceipt] = useState(0);

    //const [formDirty, setFormDirty] = useState();
    //var formDirty = false;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [orderJobKey, setOrderJobKey] = useState(Date.now());

    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showCreateGroupKey, setShowCreateGroupKey] = useState(Date.now());
    const [createGroupNameValue, setCreateGroupNameValue] = useState("");

    const typeOlIds = "get-olIds";
    const poCreate = "po-create";
    const poEdit = "po-edit";
    const materialPickup = "material-pickup";
    const typeEdit = "edit-lines";
    const typeDelete = "delete-lines";

    //const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const [gridHeight, setGridHeight] = useState("400px");

    //const olGridRef = useRef();
    //const columnDefs = [
    //    {
    //        headerName: '', field: 'orderLineId', maxWidth: 40, //cellClass: ["text-left"],
    //        headerCheckboxSelection: true, checkboxSelection: true,
    //    },
    //    //{ headerName: '#', field: 'lineNumberStr', maxWidth: 50, cellClass: ["text-primary"], hide: true, },
    //    {
    //        headerName: 'Style', field: 'style', cellClass: ["text-wrap"], autoHeight: true,
    //        minWidth: 350,
    //        cellRenderer: params => {
    //            var ol = params.data;
    //            return <div className="leading-[20px] pt-[11px] min-w-[350px]">
    //                <span>{ol.style}</span>
    //                {/*<div>*/}
    //                {/*    <span className="pr-4px">Width: {ol.width}</span>*/}
    //                {/*    <span>Height: {ol.height}</span>*/}
    //                {/*</div>*/}
    //            </div>;
    //        },
    //    },
    //    {
    //        headerName: "Color", field: 'color', cellClass: ["text-wrap"], autoHeight: true,
    //        //minWidth: 200,
    //        cellRenderer: params => {
    //            return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.color}</div>;
    //        },
    //    },
    //    {
    //        headerName: "Comments", field: 'comments', cellClass: ["text-wrap"], autoHeight: true,
    //        maxWidth: 200,
    //        cellRenderer: params => {
    //            return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data.comments}</div>;
    //        },
    //    },
    //    {
    //        headerName: 'Qty', field: 'quantity', maxWidth: 70, cellClass: ["text-primary"],
    //    },
    //    //{
    //    //    headerName: 'Qty Rcvd', field: 'quantityReceived', maxWidth: 125, cellClass: ["text-primary"],
    //    //},
    //    {
    //        headerName: 'Units', field: 'unitsStr', maxWidth: 60 //cellClass: ["text-wrap"],

    var rowDrag = function (params) {
        // only rows that are NOT groups should be draggable
        return !params.node.group;
    };

    const colDefQuotes = [
        //{ headerName: '#', field: 'lineNumberStr', maxWidth: 50, cellClass: ["text-primary"], hide: true, },
        {
            headerName: 'Style', field: 'style',
            cellClass: ["text-wrap"], autoHeight: true,
            headerCheckboxSelection: true, checkboxSelection: true,
            minWidth: 350,
            //rowDrag: rowDrag,
            cellRenderer: params => {
                var ol = params.data;
                return <div className="leading-[20px] pt-[11px] min-w-[350px]">
                    <span>{ol?.style}</span>
                    {/*<div>*/}
                    {/*    <span className="pr-4px">Width: {ol.width}</span>*/}
                    {/*    <span>Height: {ol.height}</span>*/}
                    {/*</div>*/}
                </div>;
            },
        },
        {
            headerName: "Color", field: 'color', cellClass: ["text-wrap"], autoHeight: true,
            //minWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data?.color}</div>;
            },
        },
        {
            headerName: "Comments", field: 'comments', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data?.comments}</div>;
            },
        },
        {
            headerName: 'Qty', field: 'quantity', maxWidth: 70, cellClass: ["text-primary"],
        },
        //{
        //    headerName: 'Qty Rcvd', field: 'quantityReceived', maxWidth: 125, cellClass: ["text-primary"],
        //},
        {
            headerName: 'Units', field: 'unitsStr', maxWidth: 60 //cellClass: ["text-wrap"],
        },
        {
            headerName: 'Cost', field: 'cost', maxWidth: 100, //cellClass: ["text-left"],
            hide: IsFalse(isInventory()),
            cellRenderer: params => {
                var cost = formatCurrencyDecStr(params.data?.cost);
                return <span className="text-primary">{cost}</span>;
            }
        },
        {
            headerName: 'Price', field: 'price', maxWidth: 80, //cellClass: ["text-left"],
            cellRenderer: params => {
                var price = formatCurrencyDecStr(params.data?.price);
                return <span className="text-primary">{price}</span>;
            }
        },
        {
            headerName: 'Total', field: 'total', maxWidth: 80, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var total = formatCurrencyDecStr(params.data?.total);
                return <span className="text-primary">{total}</span>;
            }
        },
        {
            headerName: 'Status', field: 'statusStr', maxWidth: 150, cellClass: ["text-primary"],
            hide: isQuote(),
            cellRenderer: params => {
                return <span className="">{params.data?.statusStr}{orderData?.isCP && "-CP"}</span>;
            }
        },
        {
            headerName: 'Date', field: 'statusDateStr', maxWidth: 120, cellClass: ["text-primary"],
            hide: isQuote(),
        },
        {
            headerName: 'Warehouse', field: 'orderLineId', maxWidth: 105, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var ol = params.data;
                return <div className="text-primary"> {ol?.showLocationData === true &&
                    <div>
                        {ol.showReceivedStore !== true && <span>{ol?.warehouseName}</span>}
                        {ol.showReceivedStore === true && <span>{ol?.receivedStoreName}</span>}
                    </div>} </div>;
            }
        },
        {
            headerName: 'Bin', field: 'bin', maxWidth: 50, //cellClass: ["text-wrap"],
            hide: isQuote(),
            cellRenderer: params => {
                return <span className="">{showBinCol(params.data)}</span>;
            }
        },
        {
            headerName: 'Vendor', field: 'vendorName', //maxWidth: 150,
            cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data?.vendorName}</div>;
            },
        },
        {
            headerName: 'Category', field: 'productCategoryName',
            maxWidth: 150, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.data?.productCategoryName}</div>;
            },
        },
    ];

    const colDefOthers = [
        {
            headerName: '', field: 'orderLineId', maxWidth: 40, //cellClass: ["text-left"],
            headerCheckboxSelection: true, checkboxSelection: true,
        },
        //{ headerName: '#', field: 'lineNumberStr', maxWidth: 50, cellClass: ["text-primary"], hide: true, },
        {
            headerName: 'Style', field: 'style', cellClass: ["text-wrap"], autoHeight: true,
            minWidth: 350,
            cellRenderer: params => {
                var ol = params.data;
                return <div className="leading-[20px] pt-[11px] min-w-[350px]">
                    <span>{ol?.style}</span>
                    {/*<div>*/}
                    {/*    <span className="pr-4px">Width: {ol.width}</span>*/}
                    {/*    <span>Height: {ol.height}</span>*/}
                    {/*</div>*/}
                </div>;
            },
        },
        {
            headerName: "Color", field: 'color', cellClass: ["text-wrap"], autoHeight: true,
            //minWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data?.color}</div>;
            },
        },
        {
            headerName: "Comments", field: 'comments', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[200px]">{params.data?.comments}</div>;
            },
        },
        {
            headerName: 'Qty', field: 'quantity', maxWidth: 70, cellClass: ["text-primary"],
        },
        //{
        //    headerName: 'Qty Rcvd', field: 'quantityReceived', maxWidth: 125, cellClass: ["text-primary"],
        //},
        {
            headerName: 'Units', field: 'unitsStr', maxWidth: 60 //cellClass: ["text-wrap"],
        },
        {
            headerName: 'Cost', field: 'cost', maxWidth: 100, //cellClass: ["text-left"],
            hide: IsFalse(isInventory()),
            cellRenderer: params => {
                var cost = formatCurrencyDecStr(params.data?.cost);
                return <span className="text-primary">{cost}</span>;
            }
        },
        {
            headerName: 'Price', field: 'price', maxWidth: 80, //cellClass: ["text-left"],
            cellRenderer: params => {
                var price = formatCurrencyDecStr(params.data?.price);
                return <span className="text-primary">{price}</span>;
            }
        },
        {
            headerName: 'Total', field: 'total', maxWidth: 80, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var total = formatCurrencyDecStr(params.data?.total);
                return <span className="text-primary">{total}</span>;
            }
        },
        {
            headerName: 'Status', field: 'statusStr', maxWidth: 150, cellClass: ["text-primary"],
            hide: isQuote(),
            cellRenderer: params => {
                return <span className="">{params.data?.statusStr}{orderData?.isCP && "-CP"}</span>;
            }
        },
        {
            headerName: 'Date', field: 'statusDateStr', maxWidth: 120, cellClass: ["text-primary"],
            hide: isQuote(),
        },
        {
            headerName: 'Warehouse', field: 'orderLineId', maxWidth: 105, //cellClass: ["text-wrap"],
            cellRenderer: params => {
                var ol = params.data;
                return <div className="text-primary"> {ol?.showLocationData === true &&
                    <div>
                        {ol.showReceivedStore !== true && <span>{ol?.warehouseName}</span>}
                        {ol.showReceivedStore === true && <span>{ol?.receivedStoreName}</span>}
                    </div>} </div>;
            }
        },
        {
            headerName: 'Bin', field: 'bin', maxWidth: 50, //cellClass: ["text-wrap"],
            hide: isQuote(),
            cellRenderer: params => {
                return <span className="">{showBinCol(params.data)}</span>;
            }
        },
        {
            headerName: 'Vendor', field: 'vendorName', //maxWidth: 150,
            cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px] min-w-[150px]">{params.data?.vendorName}</div>;
            },
        },
        {
            headerName: 'Category', field: 'productCategoryName',
            maxWidth: 150, cellClass: ["text-wrap"], autoHeight: true,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.data?.productCategoryName}</div>;
            },
        },
    ];

    const olGridRef = useRef();
    const [columnDefs, setColumnDefs] = useState();
    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            editable: false,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            cellClass: ["no-border"],
            //menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    const initialGroupOrderComparator = (params) => {
        const { nodeA, nodeB } = params;
        if (nodeA.group && !nodeB.group) {
            return -1;
        }
        else if (!nodeA.group && nodeB.group) {
            return 1;
        }
        return 0;
    }

    const onRowDragMove = useCallback(async (e) => {
        const movingNode = e.node;
        const overNode = e.overNode;

        let groupLine;
        let groupLineName;
        if (overNode?.group) {
            groupLine = overNode.key;
            groupLine = overNode.allLeafChildren[0].data.orderLineGroupId;
            groupLineName = overNode.key;
        } else {
            groupLine = overNode?.data.orderLineGroupId;
            groupLineName = overNode?.data.orderLineGroup?.name;
        }

        const selectedRows = olGridRef.current.api.getSelectedNodes()

        if (selectedRows.length > 0) {

            const ids = selectedRows.map(row => row.data.orderLineId);

            selectedRows.forEach(row => {
                const needToChangeParent = row.data.orderLineGroupId !== groupLine;

                if (needToChangeParent) {
                    const movingData = row.data;

                    if (groupLine !== null) {
                        movingData.orderLineGroupId = groupLine;
                        movingData.orderLineGroup = {
                            id: groupLine,
                            name: groupLineName
                        }
                    } else {
                        movingData.orderLineGroupId = null
                        movingData.orderLineGroup = null
                    }

                    olGridRef.current.api.applyTransaction({ update: [movingData] });
                    olGridRef.current.api.clearFocusedCell();
                }
            })

            const response = await post('orders/UpdateOrderLineGroup', {
                orderLineIds: ids,
                groupId: groupLine
            })

            if (response !== true) {
                updateOrderLinesMessage("Error updating order line group", "text-red");
                await handleReloadOrderLines('', '')
            } else {
                updateOrderLinesMessage("Order line group updated successfully", "text-green");
            }

        } else {
            const needToChangeParent = movingNode?.data.orderLineGroupId !== groupLine;
            if (needToChangeParent) {
                const movingData = movingNode?.data;

                if (groupLine !== null) {
                    movingData.orderLineGroupId = groupLine;
                    movingData.orderLineGroup = {
                        id: groupLine,
                        name: groupLineName
                    }

                    const response = await post('orders/UpdateOrderLineGroup', {
                        orderLineIds: [movingData.orderLineId],
                        groupId: groupLine
                    })

                    if (response !== true) {
                        updateOrderLinesMessage("Error updating order line group", "text-red");
                        await handleReloadOrderLines('', '')
                    } else {
                        updateOrderLinesMessage("Order line group updated successfully", "text-green");
                    }

                } else {
                    movingData.orderLineGroupId = null
                    movingData.orderLineGroup = null
                }

                olGridRef.current.api.applyTransaction({ update: [movingData] });
                olGridRef.current.api.clearFocusedCell();
            }
        }

    }, []);

    function showBinCol(ol) {
        if (ol) {
            if (ol.statusId === 1 || ol.statusId === 2 || ol.statusId === 3 ||
                ol.statusId === 4 || ol.statusId === 5 || ol.statusId === 6) {
                return <span>{ol.bin}</span>;
            }
        }
    };

    function onRowDoubleClicked(ol) {
        if (ol) {
            onOpenEditLinesModal(ol);
        }
    }

    const onRowDragEnd = useCallback((e) => {
        var orderLines = getRowNodesWithSort();
        if (NotEmpty(orderLines)) {
            var response = SaveOlDisplayOrder(orderLines);
            //logged in data method
            //if (response !== true) {
            //    console.error("SaveOlDisplayOrder error: " + response);
            //}
        }
    }, []);

    function getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        //get correct type for create/update/refresh paths

        if (isQuote(isQuoteType)) {
            return "Quote";
        }
        else if (isClaim(isClaimType)) {
            return "Claim";
        }
        else if (isInventory(isInventoryType)) {
            return "Inventory Order";
        }
        // Samples behaves as Order type
        //else if (isSample(isSamplesType)) {
        //    return "Samples";
        //}
        else {
            return "Order";
        }
    }

    function createMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " created successfully.";
    }

    function updateMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " saved successfully.";
    }

    function closedMsg(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        return getOrderTypeStr(isOrderType, isQuoteType, isClaimType, isInventoryType) + " closed successfully.";
    }

    function createMsgSearch(orderNumber) {
        return orderNumber + " created successfully.";
    }

    function updateMsgSearch(orderNumber) {
        return orderNumber + " saved successfully.";
    }

    function closedMsgSearch(orderNumber) {
        return orderNumber + " closed successfully.";
    }

    //function createErrMsg() {
    //    return "There was an issue creating the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}
    //function updateErrMsg() {
    //    return "There was an issue saving the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}
    //function updateErrMsg() {
    //    return "There was an issue saving the " + getOrderTypeStr() + ". Please try again or contact support.";
    //}

    function checkLinesNotEmpty(objArr, msg, msgClass) {
        if (!objArr) {
            objArr = olGridRef.current.api.getSelectedNodes();
        }
        if (NotEmpty(objArr)) {
            return true;
        }
        if (!msg || !msgClass) {
            msg = "No lines selected.";
            msgClass = "text-primary";
        }
        updateOrderLinesMessage(msg, msgClass);
        return false;
    }

    function getRowNodesWithSort(type, getSelected) {
        var olIds = [];
        var orderLines = [];

        olGridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
            var ol = node.data;
            if (ol) {
                //use selected cbs
                if (getSelected === true) {
                    if (node.selected === true) {
                        //PO
                        if (type === poEdit || type === poCreate) {
                            //get all selected orderLines for create statuses
                            if (type === poCreate &&
                                ((ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 10) ||
                                    // only add POs for Stock Item for Inventory Order
                                    (isInventory() && ol.statusId === 15))) {
                                orderLines.push(ol);
                            }
                            //get all lines that have a PO set for them and allow edit
                            else if (type === poEdit && IdExists(ol.purchaseOrderLineId)) {
                                //get all selected orderLines for edit statuses w/ PO
                                //(ol.statusId !== 0 && ol.statusId !== 1 && ol.statusId !== 10)
                                orderLines.push(ol);
                            }
                        }
                        //get all selected
                        else {
                            orderLines.push(ol);
                        }
                    }
                }
                //use all lines
                else {
                    //MaterialPickup
                    if (type === materialPickup) {
                        //'Received' and 'Stock Item' statuses
                        if (ol.statusId === 3 || ol.statusId === 15) {
                            orderLines.push(ol);
                        }
                    }
                    //get all orderLines
                    else {
                        orderLines.push(ol)
                    }
                }
            }

        });

        //return OrderLineIds for these types
        if (type === typeOlIds || type === poCreate || type === poEdit) {
            if (orderLines) {
                orderLines.forEach((ol, index) => {
                    olIds.push(ol.orderLineId);
                });
            }
            return olIds;
        }
        else {
            return orderLines;
        }
    }

    // Billing Group Modal
    const billGroupModalShow = useCallback(() => {
        billGroupModalRef.current?.showModal();
    }, [billGroupModalRef]);

    const billGroupModalHide = useCallback(() => {
        billGroupModalRef.current?.close();
    }, [billGroupModalRef]);

    function getBillGroupModalContent(billingGroupId) {
        return <BillingGroupModal billingGroupId={billingGroupId} orderId={orderData.orderId}
            onCloseModal={onCloseBillGroupModal}></BillingGroupModal>;
    }

    function onOpenBillGroupModal(e, billingGroupId) {
        PreventDefault(e);
        setBillGroupModalContent(getBillGroupModalContent(billingGroupId));
        billGroupModalShow();
    }

    function onCloseBillGroupModal(e) {
        PreventDefault(e);
        setBillGroupModalContent(null);
        billGroupModalHide();
    }

    async function createBillingGroup(e) {
        PreventDefault(e);
        var success = false;
        var resp = await CreateBillingGroupDb(orderData.orderId);

        var bgId = ParseIntSafe(resp)
        if (IdExists(bgId)) {
            success = true;
            setBillingGroupId(bgId);
            onOpenBillGroupModal(null, bgId);
        }

        if (success !== true) {
            console.error("createBillingGroup(): ", JSON.stringify(resp));
            alert(defaultErrMsg());
        }
    }


    /****** Data Methods ******/

    async function createUserList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    //async function fetchCardMachines() {
    //    var machines = await getCardMachinesForUser(userId);
    //    console.log('Machines:', machines);
    //    setCardMachines(machines);
    //}

    function loadDataLists() {
        createUserList();
    }


    /****** Methods ******/

    function clearAllMessages() {
        if (orderId && orderId !== 0) {
            //account
            //setAccountMessage("");
            //setAccountMessageClass("");
            //job
            //setJobMessage("");
            //setJobMessageClass("");
            //orderlines
            setOrderLinesMessage("");
            setOrderLinesMessageClass("");
            //general
            setMessage("");
            setMessageClass("");
        }
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function updateOrderLinesMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setOrderLinesMessage(msg);
        setOrderLinesMessageClass(cssClass);
    }

    function loadPage(e, route) {
        PreventDefault(e);
        SetHrefById("lnkLoadPage", route);
        ClickById("lnkLoadPage");
    }

    function openNewPage(e, route) {
        PreventDefault(e);
        SetHrefById("lnkOpenNewPage", route);
        ClickById("lnkOpenNewPage");
    }

    function voidClick(e) {
        PreventDefault(e);
    }

    function isOrder() {
        if (isClaim() || isQuote() || isInventory()) {
            return false;
        }
        //isSample()
        //Order and Sample type behave the same
        return true;
    }

    function isQuote(isQuoteType) {
        var isQuote = false;
        if (Exists(isQuoteType)) {
            isQuote = IsTrue(isQuoteType);
        }
        else if (locOrderType === 2) {
            isQuote = true;
        }
        else if (orderData) {
            isQuote = IsTrue(orderData.isQuoteType);
        }
        return isQuote;
    }

    function isClaim(isClaimType) {
        var isClaim = false;
        if (Exists(isClaimType)) {
            isClaim = IsTrue(isClaimType);
        }
        else if (locOrderType === 3) {
            isClaim = true;
        }
        else if (orderData) {
            isClaim = IsTrue(orderData.isClaimType);
        }
        return isClaim;
    }

    function isInventory(isInventoryType) {
        var isInventory = false;
        if (Exists(isInventoryType)) {
            isInventory = IsTrue(isInventoryType);
        }
        else if (locOrderType === 5) {
            isInventory = true;
        }
        else if (orderData) {
            isInventory = IsTrue(orderData.isInventoryType);
        }
        return isInventory;
    }

    //function isSample(isSamplesType) {
    //    var isSample = false;
    //    if (Exists(isSamplesType)) {
    //        isSample = IsTrue(isSamplesType);
    //    }
    //    else if (locOrderType === 4) {
    //        isSample = true;
    //    }
    //    else if (orderData) {
    //        isSample = IsTrue(orderData.isSamplesType);
    //    }
    //    return isSample;
    //}

    function showOnlyOrderClass() {
        var hiddenClass = " hidden ";
        if (isOrder()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }

    function hiddenForOrderClass() {
        var hiddenClass = "";
        if (isOrder()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }


    function showOnlyQuoteClass() {
        var hiddenClass = " hidden ";
        if (isQuote()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }

    function hiddenForQuoteClass() {
        var hiddenClass = "";
        if (isQuote()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function showOnlyClaimClass() {
        var hiddenClass = " hidden ";
        if (isClaim()) {
            hiddenClass = "";
        }
        return hiddenClass;
    }

    function hiddenForClaimClass() {
        var hiddenClass = "";
        if (isClaim()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function showOnlyInventoryClass() {
        var hiddenClass = "";
        if (isInventory()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function hiddenForInventoryClass() {
        var hiddenClass = "";
        if (isInventory()) {
            hiddenClass = " hidden ";
        }
        return hiddenClass;
    }

    function backToClick(orderStatus, orderMessage) {
        var rldlnk = "/Orders";
        if (isQuote()) {
            rldlnk = "/Quotes";
        }
        else if (isClaim()) {
            rldlnk = "/Claims";
        }
        navigate(rldlnk, {
            replace: true,
            state: { useSavedFiltersOrder: true, orderStatus, orderMessage }
        });
    }

    function cbAllSetClick(checked) {
        if (checked === true) {
            olGridRef.current.api.selectAll();
        }
        else {
            olGridRef.current.api.deselectAll();
        }
    }

    /****** Modal Methods ******/

    // Quote Create Order / Add to Order Modal --------------------------------------

    async function moveOrderToQuote(e) {
        PreventDefault(e);
        var response = null;
        var canMoveToQuote = true;

        //cannot move if payments on order
        canMoveToQuote = Equals(orderData.payments, 0);

        //cannot move if all lines not in "None" or "To Be Ordered"
        olGridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
            if (canMoveToQuote) {
                var ol = node.data;
                if (ol) {
                    if (ol.statusId === 0 || ol.statusId === 1) {
                        canMoveToQuote = true;
                    }
                    else {
                        canMoveToQuote = false;
                    }
                }
            }
        });

        if (canMoveToQuote) {
            if (window.confirm("Are you sure you want to move this Order to a Quote? This will delete the Order.")) {
                response = await ConvertOrderToQuotePost(userId, orderData.orderId);

                var newQuoteId = ParseIntSafe(response);
                if (IdExists(newQuoteId)) {
                    loadPage(null, "/Redirect?route=EditQuote&orderId=" + newQuoteId);
                }
                else {
                    updateMessage(defaultErrMsg(), "text-red");
                    alert(defaultErrMsg());
                }
            }
        }
        else {
            updateMessage("Order cannot be moved to quote.", "text-primary");
        }
    }


    function returnQuoteOrderSearchComponent() {
        setIsQuoteOrderSearch(true);
        return <div>
            <OrdersGrid pageType="QuoteOrders"
                selectOrderForQuoteLines={selectOrderForQuoteLines}
                onCloseModal={onCloseModal}>
            </OrdersGrid>
        </div>
    }

    function onOpenQuoteOrderSearchModal(e) {
        PreventDefault(e);
        clearAllMessages();

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            setModalClass("quotes-order-search");
            setQuoteOrdersModalContent(returnQuoteOrderSearchComponent);
            onOpenModal();
        }
    }

    function selectOrderForQuoteLines(e, orderId) {
        PreventDefault(e);
        AddQuoteLinesToOrder(orderId);
    }

    async function AddQuoteLinesToOrder(orderId) {
        var success = false;

        if (IdExists(orderId)) {

            var selOlIds = getRowNodesWithSort(typeOlIds, true);
            if (checkLinesNotEmpty(selOlIds)) {
                var resp = await AddQuoteLinesToOrderPost(userId, orderId, selOlIds);

                var addToOrderId = ParseIntSafe(resp);
                if (IdExists(addToOrderId)) {
                    success = true;
                    //openNewPage(null,
                    loadPage(null, "/Redirect?route=EditOrder&orderId=" + addToOrderId);
                }
                else {
                    onCloseModal(false, false, false);
                    console.error("AddQuoteLinesToOrder(): ", JSON.stringify(resp));
                    alert(defaultErrMsg());
                }
            }
        }

        if (!success) {
            onCloseModal(false, false, false);
        }
    }

    async function QuoteCreateNewOrder(e) {
        PreventDefault(e);

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            var resp = await CreateOrderForQuoteLinesPost(selOlIds);

            var newOrderId = ParseIntSafe(resp);
            if (IdExists(newOrderId)) {
                //openNewPage(null,
                loadPage(null, "/Redirect?route=EditOrder&orderId=" + newOrderId);
            }
            else {
                console.error("QuoteCreateNewOrder(): ", JSON.stringify(resp));
                alert(defaultErrMsg());
            }
        }
    }

    // Claim Vendor / Account --------------------------------------

    function openAccountOrClaimVendor() {
        PreventDefault();
        if (isClaim()) {
            onOpenAddClaimVendorModal();
        }
    }

    function openJobOrClaimAccount(e) {
        PreventDefault(e);
        openJobModal();
    }

    function onOpenAddClaimVendorModal(e) {
        PreventDefault(e);
        setOpenAddVendorModal(true);
    }

    function onCloseAddClaimVendorModal(e, selectedVendorId) {
        PreventDefault(e);
        clearAllMessages();

        if (selectedVendorId) {
            saveClaimVendorAndSetVendorData(selectedVendorId);
        }
        else {
            //close button clicked (vendor not changed)
            //onCloseModal(false, false, false);
            setOpenAddVendorModal(false);
        }


    }

    async function saveClaimVendorAndSetVendorData(vendorId) {
        //should only ever be used for Claim OrderType
        //return vendor model for display on change

        if (vendorId && vendorId > 0) {
            //will save vendorId to Order if OrderId != 0
            const endpoint = 'orders/SaveAndGetVendorModel/' + vendorId + '/' + orderId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                setClaimVendorData(response);

                orderData.claimAgainstVendorId = vendorId;
                setOrderData(orderData);

                if (orderId === 0) {
                    //onCloseModal(false, false, true);
                    setOpenAddVendorModal(false);
                }
                else {
                    //setClaimVendorMsg("Claim Against - Vendor saved successfully.");
                    setOpenAddVendorModal(false);
                    //onCloseModal(false, false, false);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
    }

    //// Account Modal --------------------------------------
    //function onCloseAddAccountModal(e, selectedAccountId) {
    //    PreventDefault(e);
    //    clearAllMessages();

    //    if (selectedAccountId) {
    //        saveOrderAccountId(selectedAccountId, null);

    //        //if (orderId === 0 && isClaim()) {
    //        //    //create new claim after Claim For Account selected
    //        //    //set fields and save new Order with ClickById("btnOrderSubmit")
    //        //    orderData.accountId = selectedAccountId;
    //        //    setOrderData(orderData);
    //        //    setOpenAddAccountModal(false);
    //        //}
    //        //else {
    //        //    //save account for order,claim
    //        //    saveOrderAccountId(selectedAccountId, null);
    //        //}
    //    }
    //    else {
    //        //close button clicked (account not changed)
    //        setOpenAddAccountModal(false);
    //    }

    //    setOpenOrderJobModal(true);
    //    setOrderJobKey(Date.now())
    //}

    //function saveOrderAccountId(selectedAccountId, orderDataModel) {
    //    if (selectedAccountId) {
    //        saveAccountAndSetAccountData(selectedAccountId, orderDataModel);
    //    }
    //}

    const centralPurchase = async () => {
        const response = await get('orders/centralPurchase/' + orderId);        
        handleReloadOrder(orderId, "update", "text-green");
    }

    //async function saveAccountAndSetAccountData(accountId, orderDataModel) {
    //    if (IdExists(accountId)) {
    //        const endpoint = 'orders/SaveAndGetAccountModel/' + accountId + '/' + orderId;
    //        try {
    //            const data = await get(endpoint);
    //            if (IdExists(data.accountId)) {
    //                var acctModel = createAccountModel(null, data);
    //                setAccountData(acctModel);

    //                if (orderId === 0) {
    //                    // used for create new Order from Account
    //                    // return OrderModel with Account data
    //                    var orderModel = orderData;
    //                    if (!orderModel && orderDataModel) {
    //                        orderModel = orderDataModel;
    //                    }

    //                    orderModel.accountId = acctModel.accountId;
    //                    orderModel.samplesAccount = acctModel.samplesAccount;
    //                    // Terms - default to Cash
    //                    orderModel.termsId = 1;
    //                    if (IdExists(acctModel.termsId)) {
    //                        orderModel.termsId = acctModel.termsId;
    //                    }
    //                    // BC PriceLevel
    //                    if (IdExists(acctModel.defaultPriceLevelId)) {
    //                        orderModel.priceLevelId = acctModel.defaultPriceLevelId;
    //                    }
    //                    // fields not needed for claim
    //                    if (IsFalse(isClaim())) {
    //                        //set autofill values from Account on new Order
    //                        orderModel.materialNotificationEmail = acctModel.materialNotificationEmail;
    //                        orderModel.materialNotificationPhone = acctModel.materialNotificationPhone;
    //                    }

    //                    if (acctModel.taxTypeId) {
    //                        orderModel.taxTypeId = acctModel.taxTypeId;
    //                    }

    //                    // open Job modal on new Order
    //                    // unless creating from Billing Group
    //                    //var openJobModal = true;
    //                    //var createNewOrder = false;
    //                    //if (IdExists(locBillGroupId)) {
    //                    //    openJobModal = false;
    //                    //    createNewOrder = true;
    //                    //}

    //                    //set page model data
    //                    setOrderData(orderModel);
    //                    setOpenAddAccountModal(false);
    //                    openJobModal(orderModel, acctModel);
    //                    //onCloseModal(openJobModal, createNewOrder, false);
    //                }
    //                else {
    //                    // only update accountId for existing order
    //                    orderData.accountId = accountId;
    //                    setOrderData(orderData);

    //                    // show OrderType message
    //                    //if (isClaim()) {
    //                    //    setAccountMessage("Claim For - Account saved successfully.");
    //                    //}
    //                    //else {
    //                    //    setAccountMessage("Account saved successfully.");
    //                    //}
    //                    setOpenAddAccountModal(false);
    //                }

    //            } else {
    //                alert("An error occurred updating Account");
    //            }
    //        } catch (error) {
    //            console.log('Error: ' + error);
    //        }
    //    }
    //}


    // Job Modal --------------------------------------

    function returnJobModalComponent(pOrderData, pAccountData) {
        // pass in on create Order
        if (Exists(orderData)) {
            pOrderData = orderData;
        }
        if (IdExists(accountData?.accountId)) {
            pAccountData = accountData;
        }

        var jobData = createJobModel(pOrderData, null);
        var pOrderStatus = IdExists(orderId) ? pOrderData.orderStatus : 0;
        var pOrderType = IdExists(pOrderData?.orderType) ? pOrderData.orderType : locOrderType;
        var orderTypeStr = getOrderTypeStr();

        ////set accountId for create order if passed in
        //if (!IdExists(orderId) && IdExists(pOrderData.accountId)) {
        //    pAccountData.accountId = pOrderData.accountId;
        //}

        return <OrderJobModal
            orderId={orderId} orderStatus={pOrderStatus}
            orderType={pOrderType} orderTypeStr={orderTypeStr}
            orderData={pOrderData}
            jobData={jobData}
            accountData={pAccountData}
            vendorData={claimVendorData}
            billingGroupId={billingGroupId}
            setOpenAddAccountModal={setOpenAddAccountModal}
            setOpenAddVendorModal={setOpenAddVendorModal}
            openClaimOrder={openClaimOrder}
            onCloseModal={onCloseJobModal}
        //userId={userId}
        //accountModalContent={returnAddAccountModalComponent()}
        //billingGroupId={locBillGroupId}
        //samplesAccount={orderData.samplesAccount}
        />
    }

    function openJobModal(pOrderData, pAccountData) {
        setJobModalContent(returnJobModalComponent(pOrderData, pAccountData));
        setOrderJobKey(Date.now());
        setOpenOrderJobModal(true);
    }

    function onCloseJobModal(e, postModel) { //modalJobData 
        PreventDefault(e);
        clearAllMessages();

        // always reload order after open modal, whether saved or not
        if (IdExists(postModel?.orderId)) {
            // "Save" clicked, load from saved response orderId
            handleReloadOrder(postModel.orderId, "update", "text-green");
        }
        else if (IdExists(orderId)) {
            // "Cancel" clicked, load from saved orderId
            handleReloadOrder(orderId, "", "");
        }
        else {
            // "Cancel" new order, load order search
            navigate(GetSearchLink());
        }
    }

    // Add Order Line Modal --------------------------------------

    function returnAddOrderLineModalComponent() {
        //var priceLevelStr = GetSelectItemTextById("priceLevelId");
        var orderTypeStr = getOrderTypeStr();
        var isInventoryOrder = isInventory();

        return <AddOrderLineModal orderId={orderId} storeId={orderData.storeId}
            priceLevelStr={orderData.priceLevelStr} bcMultiplier={orderData.bcMultiplier}
            orderTypeStr={orderTypeStr} isSamplesOrder={orderData.isSamplesOrder}
            isInventoryOrder={isInventoryOrder} onCloseModal={onCloseAddOlModal}>
        </AddOrderLineModal>;
    }

    function onOpenAddOlModal(e) {
        PreventDefault(e);
        clearAllMessages();

        setModalClass("add-line-modal");
        setModalContent(returnAddOrderLineModalComponent);
    }

    function onCloseAddOlModal(e, linesUpdated) {
        PreventDefault(e);

        //reload to be sure any changes reflected
        var status = "";
        var message = "";
        var messageClass = "";
        if (linesUpdated === true) {
            status = "update";
        }
        handleReloadOrder(orderId, status, message, messageClass)
    }

    // Edit Order Line Modal --------------------------------------

    function returnEditLinesModalComponent(ols) {
        return <EditOrderLineModal type={typeEdit} orderLines={ols}
            priceLevelStr={orderData.priceLevelStr} bcMultiplier={orderData.bcMultiplier}
            isSamplesOrder={orderData.isSamplesOrder} onCloseModal={onCloseEditLinesModal}
        >
        </EditOrderLineModal>;
    }

    function onOpenEditLinesModal(ol) {
        clearAllMessages();

        var ols = [];
        if (ol) {
            ols.push(ol);
        }
        else {
            ols = getRowNodesWithSort(null, true);
        }

        if (checkLinesNotEmpty(ols)) {
            setModalClass("edit-line-modal");
            setModalContent(returnEditLinesModalComponent(ols));
        }
    }

    async function onCloseEditLinesModal(e, linesEdited) {
        PreventDefault(e);
        var status = "";
        var message = "";
        var messageClass = "";

        if (IsTrue(linesEdited)) {
            status = "update";
        }
        //else {
        //    //updateOrderLinesMessage("An error occurred deleting line(s). Please try again or contact support.", "text-red");
        //}

        handleReloadOrder(orderId, status, message, messageClass);
    }

    // Delete Order Line Modal --------------------------------------

    function returnDeleteLinesModalComponent() {
        return <EditOrderLineModal
            type={typeDelete} onCloseModal={onCloseDeleteLinesModal}>
        </EditOrderLineModal>;
    }

    function onOpenDeleteLinesModal() {
        clearAllMessages();

        if (checkLinesNotEmpty()) {
            setModalClass("delete-line-modal");
            setModalContent(returnDeleteLinesModalComponent());
        }
    }

    async function onCloseDeleteLinesModal(e, deleteLines) {
        PreventDefault(e);
        var status = "";

        if (deleteLines === true) {
            //set selected lines deleted and reload
            var olIds = getRowNodesWithSort(typeOlIds, true);
            if (checkLinesNotEmpty(olIds)) {

                var response = await DeleteOrderLinesPost(olIds);
                if (IsTrue(response)) {
                    status = "update";
                }
                else {
                    console.error(JSON.stringify(response));
                    //updateOrderLinesMessage("An error occurred deleting line(s). Please try again or contact support.", "text-red");
                }
            }
        }
        handleReloadOrder(orderId, status, message, messageClass);
    }


    // PO Modal --------------------------------------

    function returnPurchaseOrderModalComponent(olIds) {
        //orderData={orderData}
        return <PurchaseOrderModal
            checkedOrderLineIds={olIds} orderData={orderData} orderLines={orderLines}
            isSamplesOrder={orderData.isSamplesOrder} onCloseModal={onClosePoModal}>
        </PurchaseOrderModal>;
    }

    function onOpenPoModal(create) {

        if (checkLinesNotEmpty()) {

            var poType = poEdit;
            if (create === true) {
                poType = poCreate;
            }

            var olIds = getRowNodesWithSort(poType, true);
            if (checkLinesNotEmpty(olIds)) {
                setModalClass("pur-order-modal");
                setModalContent(returnPurchaseOrderModalComponent(olIds));
            }
            else {
                if (create) {
                    updateOrderLinesMessage("No selected lines can create PO(s).", "text-primary");
                }
                else {
                    updateOrderLinesMessage("No selected lines have PO(s).", "text-primary");
                }
            }
        }
    }

    function onClosePoModal(e, posSaved) {
        PreventDefault(e);
        //reload to be sure any changes reflected
        var status = "";
        var message;
        var messageClass;
        if (posSaved === true) {
            status = "update";
            message = "PO(s) saved successfully.";
            messageClass = "text-green";
        }

        handleReloadOrder(orderId, status, message, messageClass);
    }


    // Material Pickup Modal --------------------------------------

    function returnMaterialPickupModalComponent(olIds, ols) {
        return <MaterialPickupModal orderLineIds={olIds} orderLines={ols}
            onCloseModal={onCloseMaterialPickupModal}>
        </MaterialPickupModal>;
    }

    function onOpenMaterialPickupModal(e) {
        PreventDefault(e);
        clearAllMessages();

        var ols = getRowNodesWithSort(materialPickup);
        if (NotEmpty(ols)) {
            var olIds = [];
            setModalClass("add-line-modal");
            setModalContent(returnMaterialPickupModalComponent(olIds, ols));
        }
        else {
            updateMessage("No lines are available for pickup.", "text-primary");
        }
    }

    function onCloseMaterialPickupModal(e, linesDelivered) {
        PreventDefault(e);
        //reload to be sure any changes reflected
        var status = "";
        var message;
        var messageClass;
        if (linesDelivered === true) {
            status = "update";
            message = "Line(s) delivered successfully.";
            messageClass = "text-green";
        }

        handleReloadOrder(orderId, status, message, messageClass);
    }

    // Add Payment Modal --------------------------------------

    const applyPayment = async (paymentModel) => {
        //console.log('Applying payments:', paymentModel);

        try {
            let transactionId = null;

            // Create transaction if a credit card payment
            if (paymentModel.paymentType === 0
                || paymentModel.paymentType === 5
                || paymentModel.paymentType === 3) { // Card, AMEX, ACH
                const transactionResponse = await post('payments/SaveTransaction', {
                    userId: paymentModel.userId,
                    cardToken: paymentModel.token,
                    transactionResponse: paymentModel.transactionResponse,
                    responseMessage: paymentModel.responseMessage,
                    status: paymentModel.status,
                    transactionIdentifier: paymentModel.transactionIdentifier,
                    gtrc: paymentModel.gtrc
                });

                if (transactionResponse?.data?.transactionId) {
                    // Store transaction ID to link to payment
                    transactionId = transactionResponse.data.transactionId;
                } else {
                    throw new Error('Transaction creation failed');
                }
            }

            // Add transaction ID to payment model if exists
            if (transactionId) {
                paymentModel.transactionId = transactionId;
            }

            // Create payment
            const paymentResponse = await post('payments/CreatePayment', paymentModel);

            // If successful, update transaction with the payment ID
            if (transactionId && paymentResponse) {
                await post('payments/UpdateTransactionPayment', {
                    transactionId: transactionId,
                    paymentId: paymentResponse
                });
            }
            return paymentResponse;
        }
        catch (error) {
            console.error('Error applying payments:', error);
            throw error; // Throw error so modal can handle it
        }
    };

    function onOpenPaymentModal(e) {
        PreventDefault(e);
        clearAllMessages();

        setPaymentModalContent(
            <PaymentModal
                invoices={[{
                    invoiceId: 0,
                    orderId: orderData.orderId,
                    orderNumber: orderData.orderNumber,
                    balanceDue: orderData.balance,
                    accountName: accountData?.accountName || ''
                }]}
                onClose={onClosePaymentModal}
                confirmPayment={applyPayment}
            />
        );
        setIsPaymentsModalOpen(true);
    }

    function onClosePaymentModal(result) {
        var status = "";
        if (result && result.success) {
            status = "payment-success";

            setPaymentResult(result.message);
            setMessage(result.message);
            setMessageClass("text-green");
        }
        handleReloadOrder(orderId, status, message, messageClass);
    }

    // Activity Log Modal --------------------------------------

    function onOpenActivityLogModal(e) {
        PreventDefault(e);
        clearAllMessages();
        setActivityLogModalContent(<AuditEntriesModal orderId={orderId}
            orderNum={orderData.orderNumber} onClose={onCloseActivityLogModal} />);
    }

    function onCloseActivityLogModal(e) {
        PreventDefault(e);
        setActivityLogModalContent(null);
    }

    // Receipts Modal --------------------------------------

    const handleOpenRecieptsModal = () => setIsReceiptsModalOpen(true);

    function handleCloseReceiptsModal(result) {
        setIsReceiptsModalOpen(false);
        if (result && result.success) {
            setReceiptsResult(result.message);
            setMessage(result.message);
        }
    }

    // base modal methods  --------------------------------------

    function onOpenModal() {
        handleOrderModalShow();
    }

    function onCloseModal(openJobInfo, createNewOrder, openClaimAccount) {
        setModalClass("");
        handleOrderModalHide();
        setModalContent(null);
        setJobModalContent(null);
        //reset modal type bool
        setIsQuoteOrderSearch(false);
        //uncheck all boxes after action
        cbAllSetClick(false);

        if (IsTrue(openJobInfo) || IsTrue(openClaimAccount)) {
            ClickById("btnOpenJobOrClaimAccountModal");
        }
        //else if (IsTrue(createNewOrder)) {
        //    SaveOrder();
        //}
    }

    const handleOrderModalShow = () => { setIsModalOpen(true); };
    const handleOrderModalHide = () => { setIsModalOpen(false); };


    /****** db methods ******/

    // Claim ----------------------------------------------------

    function openClaimOrder(e) {
        PreventDefault(e);

        if (IdExists(orderData.claimForOrderId)) {
            openNewPage(e, "/Redirect?route=EditOrder&orderId=" + orderData.claimForOrderId);
        }
        else {
            console.error("openClaimOrder() claimForOrderId: " + orderData.claimForOrderId);
            alert("No Order found for Claim.");
        }
    }

    async function createClaim(e) {
        PreventDefault(e);

        var selOlIds = getRowNodesWithSort(typeOlIds, true);
        if (checkLinesNotEmpty(selOlIds)) {
            var resp = await CreateClaimPost(userId, selOlIds);

            var billGroupId = ParseIntSafe(resp);
            if (IdExists(billGroupId)) {
                setBillingGroupId(billGroupId);
                onOpenBillGroupModal(e, billGroupId);
            }
            else {
                //resp has error info
                var error = resp;
            }
        }
    }

    const handleReloadOrder = async (pOrderId, status, message, cssClass) => {
        var success = false;
        try {
            var rldlnk = "";
            if (isQuote()) {
                rldlnk = "/Redirect?route=EditQuote&orderId=" + pOrderId;
            }
            else if (isClaim()) {
                rldlnk = "/Redirect?route=EditClaim&orderId=" + pOrderId;
            }
            else if (isInventory()) {
                rldlnk = "/Redirect?route=EditInventoryOrder&orderId=" + pOrderId;
            }
            else {
                rldlnk = "/Redirect?route=EditOrder&orderId=" + pOrderId;
            }
            navigate(rldlnk + "&status=" + status);
            //loadPage(null, rldlnk);
        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    const handleReloadOrderLines = async (message, cssClass) => {
        var success = false;
        try {
            const endpoint = 'orders/GetOrder/' + orderId + '/0/0';
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            //setOrderTotals(response);
            setOrderLines(response.orderLines);

            setPoMenuItemsView(response.orderLines);
            updateMessage(message, cssClass);
            //updateOrderLinesMessage(message, cssClass);
            success = true;
        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    function getQuoteOrderLinks(exportedQuoteOrders) {
        var links = [];

        if (NotEmpty(exportedQuoteOrders)) {
            exportedQuoteOrders.forEach((o) => {
                links.push(
                    <span className="mr-1">
                        <a href={"/Redirect?route=EditOrder&orderId=" + o.orderId} target="_blank" rel="noreferrer"
                            className="underline text-primary text-[14px]">
                            {o.orderNumber}
                        </a>
                    </span>
                );
            });
        }
        return links;
    }

    function setPoMenuItemsView(ols, isInventoryType) {
        setCanEmailSubmittal(false);
        setCanCreatePo(false);
        setCanEditPo(false);

        if (IsEmpty(ols)) {
            ols = orderLines;
        }

        if (NotEmpty(ols)) {
            ols.forEach((ol, index) => {
                // poCreate
                if (((ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 10) ||
                    // only add POs for Stock Item for Inventory Order
                    (isInventory(isInventoryType) && ol.statusId === 15))) {
                    setCanEmailSubmittal(true);
                    setCanCreatePo(true);
                    return;
                }
                // poEdit
                else if (IdExists(ol.purchaseOrderLineId)) {
                    setCanEditPo(true);
                    return;
                }
            });
        }
    }

    async function createOrderData(data) {
        if (Exists(data)) {

            //set page fields
            if (locBillGroupId !== 0) {
                setBillingGroupId(locBillGroupId);
            }
            else {
                setBillingGroupId(data.billingGroupId);
            }

            //set Claim Against Vendor for Claim order
            setClaimVendorData(data.claimAgainstVendor);

            setOrderData(data);
            setOrderLines(data.orderLines);

            getMaterialPickupClass(data.orderLines);
            setPoMenuItemsView(data.orderLines, data.isInventoryType)

            // init AccountData model
            setAccountData(createAccountModel(null, null));
            if (data.orderId !== 0 && data.accountId !== 0) {
                // set AccountData model for existing Order and AccountId
                setAccountData(createAccountModel(data, null));
            }
            else if (data.orderId === 0 && accountId !== 0) {
                // create new Order from Account
                data.accountId = accountId;
                openJobModal(data);
            }
            else if (data.orderId === 0) {
                // create new Order
                openJobModal(data);
            }

            // success
            setError(false);
        }
        else {
            // should return empty model for new order
            // should not get to here
            setError(true);
        }
        setLoading(false);
    }

    const handleLoadDetails = async (cUserId) => {
        //console.log('cUserId: ' + cUserId);
        const endpoint = 'orders/GetOrder/' + orderId + '/' + cUserId + '/' + locBillGroupId;
        //console.log('API URL: ' + endpoint);

        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));
            createOrderData(response);

            var newOrderDisabled = response.orderId === 0;
            setAddLinesDisabled(newOrderDisabled);
            if (newOrderDisabled) {
                updateOrderLinesMessage("Save Order to Add POs and Lines", "text-primary");
            }

            var dt = "";
            var pt1 = "";
            var pt2 = "";
            var pt3 = "";
            if (Equals(response.orderId, 0)) {
                dt = "Lumina New " + getOrderTypeStr();
                pt1 = "New " + getOrderTypeStr();
            }
            else {

                //data.orderTypeStr
                var ots = getOrderTypeStr(response.isOrderType, response.isQuoteType, response.isClaimType, response.isInventoryType);
                dt = "Lumina " + ots + " - " + response.orderNumber;
                pt1 = ots + " - ";
                pt2 = response.orderNumber;
                // show "Closed" for Closed Orders
                if (response.orderStatus === 2) {
                    pt3 = " (Closed)"
                }
                //show created,saved,etc...msg
                showOrderMessage(response.isOrderType, response.isQuoteType, response.isClaimType, response.isInventoryType);
            }
            setDocTitle(dt);
            SetPageTitle(pt1, pt2, pt3);
        } catch (error) {
            console.log('Error: ' + error);
            setLoading(false);
            setError(true);
        }
    }

    const handleBeforeUnload = (event) => {
        //TODO: working on change only? browser specific?
        //if (formDirty === true)
        //if (true)

        var formDirtyVal = GetValueById("hfFormDirty");
        if (formDirtyVal === "1") {
            event.preventDefault();
            event.returnValue = '';
        }
    };

    function changeOrderStatus(close, reopen, isDelete) {
        var model = {
            orderId: orderId,
            submitCloseOrder: false,
            submitReOpenOrder: false,
            submitDelete: false,
            jobModel: {}
        };

        if (IsTrue(close)) {
            model.submitCloseOrder = true;
            handleChangeOrderStatusPost(model);
        }
        else if (IsTrue(reopen)) {
            model.submitReOpenOrder = true;
            handleChangeOrderStatusPost(model);
        }
        else if (IsTrue(isDelete)) {
            model.submitDelete = true;
            handleChangeOrderStatusPost(model);
        }
        else {
            alert(defaultErrMsg());
        }
    }

    const handleChangeOrderStatusPost = async (model) => {
        clearAllMessages();

        if (IdExists(model.orderId)) {
            // POST data
            var submitCloseSave = false;
            var response = await ChangeOrderStatusPost(model);

            // POST response
            var success = false;
            if (IsSuccessStandardResponseAndDataPOST(response)) {

                // response returns orderId and orderNumber if success
                var pOrderId = ParseIntSafe(response.orderId);
                if (IdExists(pOrderId)) {
                    success = true;
                    var rOrderNumber = response.orderNumber;
                    var orderStatus = "update";
                    var orderMessage = updateMsgSearch(rOrderNumber);

                    if (IsTrue(model.submitCloseOrder)) {
                        orderStatus = "closed";
                        orderMessage = closedMsgSearch(rOrderNumber);
                    }

                    // if (redirectSubmit) {
                    if (IsTrue(submitCloseSave)) {
                        // redirect to Search on 'Close' link click
                        backToClick(orderStatus, orderMessage);
                    }
                    else {
                        clearAllMessages();
                        handleReloadOrder(pOrderId, orderStatus);
                    }
                }
            }

            if (success === false) {
                console.error("handleSubmit(): ", JSON.stringify(response));
                alert(defaultErrMsg());
            }
        }
    }

    function convertIdsToQueryString(ids) {
        // Map each integer to a query string 'idN=value'
        const queryString = ids.map((id, index) => `id${index + 1}=${id}`).join('&');
        return queryString;
    }

    const printOrder = async (e) => {
        PreventDefault(e);
        clearAllMessages();

        const apiUrl = process.env.REACT_APP_API_URL + 'orders/PrintOrder/' + orderData.orderId;
        window.location.href = apiUrl;
    }

    const generateOrderPdfEmail = async (e) => {
        PreventDefault(e);
        clearAllMessages();

        try {
            // open email link from response
            const endpoint = 'orders/CreateEmailOrderLink/' + orderData.orderId;
            const responseLink = await get(endpoint);
            window.open(responseLink);
            //console.log('Response: ' + JSON.stringify(responseLink));

            // download Order pdf to browser
            const pdfApiUrl = process.env.REACT_APP_API_URL + 'orders/PrintOrder/' + orderData.orderId;
            window.location.href = pdfApiUrl;
            return true;

        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    async function generatePoEmail() {
        //generate Po/Invoice email
        //var pdfApiUrl = "";
        var selOlIds = getRowNodesWithSort(poCreate, true);
        if (checkLinesNotEmpty(selOlIds, "No selected lines can create a submittal email", "text-primary")) {
            handleCreatePoEmail(selOlIds);
        }

        //if (NotStringEmpty(pdfApiUrl)) {
        //    // generate and download pdf
        //    setTimeout(() => {
        //        window.location.href = pdfApiUrl;
        //    }, 500);
        //}
    }

    const handleCreatePoEmail = async (olIds) => {
        clearAllMessages();

        var idsQuery = convertIdsToQueryString(olIds);
        if (NotEmpty(olIds)) {
            try {
                // open email link from response
                const endpoint = 'orders/CreatePoEmailLink?' + idsQuery;
                const openEmailLink = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(openEmailLink));

                if (NotStringEmpty(openEmailLink)) {
                    // show updated for line statuses set to Emailed
                    const resp = await handleReloadOrderLines(updateMsg(), "text-green");

                    var cUserId = setUserFields();
                    setTimeout(() => {
                        // open email application
                        window.open(openEmailLink);

                        // generate and download pdf
                        const pdfApiUrl = process.env.REACT_APP_API_URL + 'orders/PrintPo?userId=' + cUserId + "&" + idsQuery;
                        window.location.href = pdfApiUrl;
                    }, 500);
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
        return "";
    }

    function showOrderMessage(isOrderType, isQuoteType, isClaimType, isInventoryType) {
        if (location.state && location.state.orderStatus) {
            var status = location.state.orderStatus;
            if (status === "create") {
                updateMessage(createMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
            else if (status === "update") {
                updateMessage(updateMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
            else if (status === "closed") {
                updateMessage(closedMsg(isOrderType, isQuoteType, isClaimType, isInventoryType), "text-green");
            }
            else if (status === "payment-success") {
                updateMessage("Payment successful.", "text-green");
            }
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            //console.log('User userData: ', userData);
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId ?? 0;
    }

    function GetSearchLink() {
        if (IsTrue(isQuote())) {
            return "/Quotes";
        }
        else if (IsTrue(isClaim())) {
            return "/Claims";
        }
        else {
            return "/Orders";
        }
    }

    // ON LOAD
    useEffect(() => {
        //todo: get working but not on order create
        //window.addEventListener('beforeunload', handleBeforeUnload);

        var cUserId = setUserFields();
        if (cUserId === 0) {
            ClickById("lnkMenuLogin");
        }
        else {
            loadDataLists();
            handleLoadDetails(cUserId);
        }

        AddClassById("responsiveOuterContainer", "order-responsive-container");
        //return () => {
        //    window.removeEventListener('beforeunload', handleBeforeUnload);
        //};
    }, []);

    useLayoutEffect(() => {
        if (isQuote()) {
            setColumnDefs(colDefQuotes);
        } else {
            setColumnDefs(colDefOthers);
        }
    }, [orderLines])

    useEffect(() => {
        if (!isQuote()) return;

        if (isQuote()) {
            setColumnDefs(colDefQuotes);
            console.log('xd')
        } else {
            setColumnDefs(colDefOthers);
        }

        const existsGroups = orderLines.some(line => line.orderLineGroupId !== null);

        if (existsGroups) {
            setColumnDefs(prevData => [
                ...prevData,
                {
                    headerName: 'Custom Group',
                    rowGroup: true,
                    hide: true,
                    valueGetter: (params) => params.data.orderLineGroup?.name
                }
            ])
        }
    }, [orderLines]);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    async function createOptionGroup() {
        setShowCreateGroupKey(Date.now());

        const selectedRows = olGridRef.current.api.getSelectedNodes();

        if (selectedRows.length === 0) {
            return;
        }

        const anyHasAlreadyGroup = selectedRows.some(row => row.data.orderLineGroupId !== null);
        if (anyHasAlreadyGroup) {
            updateOrderLinesMessage('Selected lines are already in a group', 'text-red');
            return
        }

        const response = await post('orders/CreateOrderLineGroup', {
            groupName: createGroupNameValue,
            orderLineIds: selectedRows.map(row => row.data.orderLineId)
        })

        if (await response?.id) {
            await handleReloadOrderLines('', '')
            clearAllMessages()
        } else {
            updateOrderLinesMessage('An error occurred creating the group', 'text-red');
        }

        setShowCreateGroup(false);
        setCreateGroupNameValue('');
    }

    async function OptionGroupOnEnter(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            createOptionGroup();
        }
    }

    function cancelCreateOptionGroup() {
        setShowCreateGroup(false);
        setCreateGroupNameValue('');
        setShowCreateGroupKey(Date.now());
    }

    const formCreateGroup = (
        <div className="flex gap-3">
            <input value={createGroupNameValue}
                onChange={(e) => setCreateGroupNameValue(e.target.value)}
                onKeyUp={(e) => OptionGroupOnEnter(e)}
                className="h-6 text-sm border-gray-500 rounded" type="text" placeholder="Group Name" />
            <button
                className="px-3 py-1 text-xs font-medium text-white bg-[#045ca4] rounded-md hover:bg-[#498abf] focus:outline-none focus:ring-2"
                onClick={() => createOptionGroup()}>
                Create
            </button>
            <button
                className="px-3 py-1 text-xs font-medium text-white bg-gray-400 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2"
                onClick={() => cancelCreateOptionGroup()}>
                Cancel
            </button>
        </div>
    )

    const createNewGroup = (
        <Alert additionalContent={formCreateGroup} color="white" icon={IoInformationCircle}>
            <h4 className="font-medium leading-none">Creating a new group</h4>
        </Alert>
    )

    function getEditDropDownOptions() {
        return (
            <>
                <Dropdown.Item disabled={orderData.orderStatus === 2} onClick={e => onOpenDeleteLinesModal(e)}>
                    Delete Line
                </Dropdown.Item>
                {isOrder() && <Dropdown.Item onClick={e => createClaim(e)}>
                    Create Claim
                </Dropdown.Item>}
                {//isQuote() &&
                //    <Dropdown.Item onClick={() => setShowCreateGroup(true)}>
                //        Create Option Group
                //    </Dropdown.Item>
                }
                {/*<Dropdown.Item onClick={e => onOpenEditLinesModal(e)}>Edit Line</Dropdown.Item>*/}
            </>
        )
    }

    function getOtherDropDownOptions() {
        return (
            <>
                {isOrder() && IsTrue(orderData.canCloseOrder) &&
                    <Dropdown.Item onClick={e => changeOrderStatus(true, false, false)}>
                        Close Order
                    </Dropdown.Item>
                }
                {isOrder() && IsTrue(orderData.canReOpenOrder) &&
                    <Dropdown.Item onClick={e => changeOrderStatus(false, true, false)}>
                        Open Order
                    </Dropdown.Item>
                }
                {billingGroupId !== 0 && (isOrder() || isClaim()) &&
                    <Dropdown.Item
                        onClick={e => onOpenBillGroupModal(e, billingGroupId)}>
                        View Billing Group
                    </Dropdown.Item>
                }
                {billingGroupId === 0 && (isOrder() || isClaim()) &&
                    <Dropdown.Item onClick={e => createBillingGroup(e)}>
                        Create Billing Group
                    </Dropdown.Item>
                }
                {billingGroupId === 0 && (isOrder() || isClaim()) &&
                    <Dropdown.Item onClick={e => handleOpenRecieptsModal()}>
                        Receipts
                    </Dropdown.Item>
                }
                <Dropdown.Item onClick={e => onOpenActivityLogModal(e)}>
                    Activity Log
                </Dropdown.Item>
                {isOrder() &&
                    <Dropdown.Item disabled={orderData.orderStatus === 2} onClick={e => moveOrderToQuote(e)}>
                        Move to Quote
                    </Dropdown.Item>
                }
            </>
        )
    }

    function getPoDropDownOptions() {
        return (
            <>
                {canEmailSubmittal &&
                    <Dropdown.Item onClick={e => generatePoEmail()}>Email
                        Submittal</Dropdown.Item>
                }
                {canCreatePo &&
                    <Dropdown.Item onClick={e => onOpenPoModal(true)}>Create
                        PO</Dropdown.Item>
                }
                {canEditPo &&
                    <Dropdown.Item onClick={e => onOpenPoModal(false)}>View
                        PO</Dropdown.Item>
                }
                {/*<Dropdown.Item onClick={centralPurchase}>Central Purchase</Dropdown.Item>*/}
            </>
        )
    }

    function getConvertToOrderDropDownOptions() {
        return (
            <>
                <Dropdown.Item onClick={e => QuoteCreateNewOrder()}>Create Order</Dropdown.Item>
                <Dropdown.Item onClick={e => onOpenQuoteOrderSearchModal()}>Add to Order</Dropdown.Item>
            </>
        )
    }

    function getMaterialPickupClass(pOrderLines) {
        var canDeliver = false;
        if (NotEmpty(pOrderLines)) {
            pOrderLines.forEach((ol, index) => {
                //'Received' and 'Stock Item' statuses
                if (ol.statusId === 3 || ol.statusId === 15) {
                    canDeliver = true;
                }
            });
        }

        if (IsFalse(canDeliver)) {
            setMaterialPickupClass("bg-grey");
        }
        else {
            setMaterialPickupClass("");
        }
    }

    return (
        <div className="h-screen">
            <div>
                {/*Header*/}
                <div className="flex mt-3 gap-5">
                    <div style={{ width: "60%" }}>
                        <DetailBox title={`${getOrderTypeStr()} Summary - #${orderData.orderNumber}`}
                            style={{ width: "100%" }} showButton={true}
                            onClickAccountClaimVendor={openJobOrClaimAccount}>

                            <BodyDetailBox tailwindClass={'col-span-3 '}>
                                <p className="font-bold  text-[18px] text-secondary pb-1">
                                    {IsFalse(isClaim()) ? "Account Name" : "Claim For"}
                                </p>
                                <a href={"/Redirect?route=EditAccount&accountId=" + accountData.accountId}
                                    className="link-primary underline"
                                    target="_blank" rel="noreferrer">
                                    <p className="truncate ">{accountData.accountName}</p>
                                </a>
                                <p className="text-black">{accountData.accountPhone}</p>
                                <a href={"mailto:" + accountData.accountEmail} className="underline link-primary">
                                    <p className="truncate">{accountData.accountEmail}</p>
                                </a>
                            </BodyDetailBox>

                            {(IsTrue(isClaim()) && Exists(claimVendorData)) &&
                                <BodyDetailBox>
                                    <p className="font-bold text-[18px] text-secondary pb-1">Claim Against</p>
                                    <a href={"/Redirect?route=EditVendor&vendorId=" + claimVendorData.vendorId}
                                        className="link-primary underline" target="_blank" rel="noreferrer">
                                        {claimVendorData.name}
                                    </a>
                                    <p className="text-black">{claimVendorData.phone1}</p>
                                    <a href={"mailto:" + claimVendorData.email1} className="underline link-primary">
                                        <p className="truncate">{claimVendorData.email1}</p>
                                    </a>
                                </BodyDetailBox>
                            }

                            {IsFalse(isClaim()) &&
                                <BodyDetailBox tailwindClass={'col-span-3 '}>
                                    <p className="font-bold text-[18px] text-secondary pb-1">Job Name</p>
                                    <p className="text-black">{orderData.jobModel.jobName}</p>
                                    <p className="text-black">{orderData.jobModel.jobAddress1}</p>
                                    <p className="text-black">{`${orderData.jobModel.jobCity}, ${orderData.jobModel.jobState} ${orderData.jobModel.jobZip}`}</p>
                                </BodyDetailBox>
                            }

                            <BodyDetailBox>
                                <p className="font-bold text-[18px] text-secondary pb-1">
                                    {IsFalse(isClaim()) ? "Job Detail" : "Claim Detail"}
                                </p>
                                <p className="text-black">{orderData.jobModel.jobNotes}</p>

                                {IsTrue(isClaim()) &&
                                    <span onClick={() => props.openClaimOrder()}
                                        className="text-primary underline cursor-pointer">
                                        {orderData?.claimForOrder?.orderNumber}
                                    </span>
                                }
                            </BodyDetailBox>

                            {IsFalse(isClaim()) &&
                                <BodyDetailBox>
                                    <p className="font-bold text-[18px] text-secondary pb-1">PO #</p>
                                    <p className="text-black">{orderData.poNumber}</p>
                                </BodyDetailBox>
                            }
                            <BodyDetailBox tailwindClass=' col-span-1 '>
                                <p className="font-bold text-[18px] text-secondary pb-1">Date</p>
                                <p className="text-black">{orderData.orderDateStr}</p>
                            </BodyDetailBox>
                        </DetailBox>

                        {IsFalse(isClaim()) &&
                            <div className="flex justify-between gap-2 mt-5">
                                <DetailBox title={`${getOrderTypeStr()} Details`} style={{ width: "75%" }}
                                    showButton={true}
                                    onClickAccountClaimVendor={openJobOrClaimAccount}>
                                    <BodyDetailBox>
                                        <p className="font-bold text-[18px] text-secondary pb-1">Placed By:</p>
                                        <p className="text-black">{orderData.placedByName}</p>
                                        <p className="text-black">{orderData.placedByPhone}</p>

                                        <a href={"mailto:" + accountData.accountEmail}
                                            className="underline link-primary">
                                            <p className="truncate">{orderData.placedByEmail}</p>
                                        </a>
                                    </BodyDetailBox>

                                    <BodyDetailBox>
                                        <p className="font-bold text-[18px] text-secondary pb-1">Salesperson</p>
                                        <p className="text-black">{orderData?.salesperson1?.fullName}</p>
                                        <p className="text-black">{orderData?.salesperson2?.fullName}</p>
                                    </BodyDetailBox>

                                    <BodyDetailBox>
                                        <div className="w-[200px]">
                                            {
                                                getOrderTypeStr() === "Order" ?
                                                    <>
                                                        <p className="font-bold text-[18px] text-secondary pb-1">Original Quote</p>
                                                        {IdExists(orderData.quoteFromOrderId) && NotStringEmpty(orderData.quoteFromOrderNumber) &&
                                                            <a href={"/Redirect?route=EditQuote&orderId=" + orderData.quoteFromOrderId}
                                                                target="_blank" rel="noreferrer"
                                                                className="underline text-primary text-[14px]">
                                                                {orderData.quoteFromOrderNumber}
                                                            </a>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <p className="font-bold text-[18px] text-secondary">Order Number</p>
                                                        <div className="label-block-2 text-black">
                                                            {getQuoteOrderLinks(orderData.exportedQuoteOrders)}
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </BodyDetailBox>
                                </DetailBox>

                                <DetailBox title="Pricing" style={{ width: "25%" }} showButton={true}
                                    onClickAccountClaimVendor={openJobOrClaimAccount}>
                                    <BodyDetailBox>
                                        <p className="font-bold text-[18px] text-secondary pb-1">Price Level</p>
                                        <p className="font-bold text-black text-[15px]">{orderData.priceLevelStr}</p>
                                    </BodyDetailBox>
                                    <BodyDetailBox>
                                        <p className="font-bold text-[18px] text-secondary pb-1">Referral</p>
                                        <p className="font-bold text-black text-[15px]">{orderData.referralStr}</p>
                                    </BodyDetailBox>

                                </DetailBox>
                            </div>
                        }
                    </div>

                    <div style={{ width: "40%" }}>
                        <div className="flex h-full gap-5">
                            <div className="w-1/2">
                                <DetailBox title={getOrderTypeStr() + " Totals"}
                                    style={{ height: "100%", width: "100%" }}
                                    styleGrid={{ gridTemplateColumns: "1fr", gap: 8 }}>

                                    <div className="flex justify-between text-[18px]">
                                        <p className="font-bold text-secondary">Subtotal</p>
                                        <p className="font-semibold text-black">{orderData.orderLinesTotalStr}</p>
                                    </div>
                                    <div className="flex justify-between text-[18px]">
                                        <p className="font-bold text-secondary">Sales Tax</p>
                                        <p className="font-semibold text-black">{orderData.salesTaxStr}</p>
                                    </div>
                                    <div className="flex justify-between text-[18px]">
                                        <p className="font-bold text-secondary">TOTAL</p>
                                        <p className="font-bold text-black">{orderData.orderTotalStr}</p>
                                    </div>

                                    <div className="mt-[60px]">
                                        <div className="flex justify-between text-[18px]">
                                            <p className="font-bold text-secondary">Payments</p>
                                            <p className="font-bold text-black">{orderData.paymentsStr}</p>
                                        </div>
                                        <div className="flex justify-between text-[18px]">
                                            <p className="font-bold text-secondary">Balance Due</p>
                                            <p className="font-bold text-red">{orderData.balanceStr}</p>
                                        </div>
                                    </div>
                                </DetailBox>
                            </div>

                            <div className="w-1/2 h-full">
                                <DetailBox title="Account Details" style={{ width: "100%" }}>
                                    <div className="flex justify-between gap-5">

                                        {IsFalse(isClaim()) &&
                                            <div className="text-sm font-semibold">
                                                <p className="font-bold text-[18px] text-secondary pb-1">Terms</p>
                                                <p className="text-black">{orderData.termsStr}</p>
                                            </div>
                                        }
                                        {IsFalse(isClaim()) &&
                                            <div className="text-sm font-semibold">
                                                <p className="font-bold text-[18px] text-secondary pb-1">Tax Status</p>
                                                <p className="text-black">{orderData.taxTypeStr}</p>
                                            </div>
                                        }
                                        <div className="text-sm font-semibold">
                                            <p className="font-bold text-[18px] text-secondary pb-1">Closed Date</p>
                                            <p className="text-black">{orderData.closedDateStr}</p>
                                        </div>
                                    </div>
                                </DetailBox>

                                {/*Buttons*/}
                                <div className="flex justify-end gap-2 pt-2">

                                    <button onClick={e => printOrder(e)}
                                        className="w-8 h-8 text-black flex items-center justify-center rounded-md transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="w-8 h-8">
                                            <path d="M6 9V2h12v7" />
                                            <path
                                                d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                                            <path d="M6 14h12v8H6z" />
                                        </svg>
                                    </button>
                                    <button onClick={e => generateOrderPdfEmail(e)}
                                        className="w-8 h-8 text-black flex items-center justify-center rounded-md transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="w-8 h-8">
                                            <rect x="2" y="4" width="20" height="16" rx="2" />
                                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                                        </svg>
                                    </button>
                                </div>

                                {/*Messages*/}
                                <div className="h-[24px] mt-[134px]">
                                    {(orderLinesMessage || message) &&
                                        <div>
                                            <div className={"flex-wrapper justify-end text-sm pr-[2px] " + orderLinesMessageClass}>
                                                {orderLinesMessage}
                                            </div>
                                            <div className={"flex-wrapper justify-end text-sm pl-1 pr-[2px] " + messageClass}>
                                                {message}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Buttons*/}
                <div className="flex mt-3 gap-5">
                    <div className="flex w-3/5">
                        <div className="w-3/5">
                            <div className="flex gap-32">
                                <div className="flex gap-5">
                                    <button
                                        disabled={orderData.orderStatus === 2}
                                        onClick={e => onOpenAddOlModal(e)}
                                        className="flex items-center justify-center px-5 py-0 text-white font-medium rounded-md btn-order">
                                        <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M12 5v14m-7-7h14" />
                                        </svg>
                                        Add Line
                                    </button>

                                    <div>
                                        <DropDownButtonDetail name="Edit Line" classNames="btn-order"
                                            dropdownItems={getEditDropDownOptions()} />
                                        <Popover key={showCreateGroupKey} content={createNewGroup} open={showCreateGroup}>
                                            <span></span>
                                        </Popover>
                                    </div>
                                
                                    {(isOrder() || isClaim() || isInventory()) &&
                                        <DropDownButtonDetail name="PO" classNames="btn-order"
                                            dropdownItems={getPoDropDownOptions()} />
                                    }
                                    {isQuote() &&
                                        <DropDownButtonDetail name="Convert to Order" classNames="btn-order"
                                            dropdownItems={getConvertToOrderDropDownOptions()} />
                                    }                                    
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between w-2/5">
                            <div className="justify-end">
                                <DropDownButtonDetail name="Other" classNames="btn-order"
                                    dropdownItems={getOtherDropDownOptions()} />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-2/5">
                        <div className="w-1/2">
                            {(isOrder() || isClaim()) &&
                                <div className="flex justify-end">
                                    <button
                                        disabled={LessThanEq(orderData.balance, 0)}
                                        onClick={e => onOpenPaymentModal(e)}
                                        className={"flex items-center justify-center px-5 py-0 text-white font-medium !rounded-md btn-payment " +
                                            (GreaterThan(orderData.balance, 0) ? "" : "bg-grey") }>
                                        Payment
                                    </button>                                    
                                </div>
                            }
                        </div>

                        <div className="w-1/2">
                            <div className="flex justify-end">
                                {(isOrder() || isClaim()) &&
                                    <button onClick={e => onOpenMaterialPickupModal(e)}
                                        disabled={addLinesDisabled || orderData.orderStatus === 2}
                                        className={"flex items-center justify-center px-5 py-0 text-white font-medium !rounded-md btn-order " +
                                            materialPickupClass}>
                                        Material Pickup
                                    </button>
                                }
                                <button id="btnDeleteSubmit" type="button" className="hidden order-btn-cancel ml-2"
                                    onClick={(e) => changeOrderStatus(false, false, true)}>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Orders Table*/}
                <div className="w-auto mt-2">
                    <div className="!block pt-0">
                        <div className="pt-0">
                            <div className="ag-order-lines-div">
                                <div className="ag-theme-alpine ag-order-lines ag-grid-act">
                                    {
                                        isQuote() ?
                                            <AgGridReact
                                                headerHeight={32}
                                                rowData={orderLines}
                                                ref={olGridRef}
                                                gridOptions={gridOptions}
                                                columnDefs={columnDefs}
                                                rowDragEntireRow={true}
                                                rowDragManaged={true}
                                                rowDragMultiRow={true}
                                                rowMultiSelectWithClick={true}
                                                rowSelection='multiple'
                                                domLayout='autoHeight'
                                                onRowDragEnd={onRowDragEnd}
                                                onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                                                //groupAllowUnbalanced={true}
                                                //initialGroupOrderComparator={initialGroupOrderComparator}
                                                //groupSelectsChildren={true}
                                                //onRowDragEnd={onRowDragMove}
                                                //suppressRowClickSelection={true}                                                
                                            />
                                            :
                                            <AgGridReact
                                                headerHeight={32}
                                                rowData={orderLines}
                                                ref={olGridRef}
                                                gridOptions={gridOptions}
                                                columnDefs={columnDefs}
                                                onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                                                onRowDragEnd={onRowDragEnd}
                                                rowDragEntireRow={true}
                                                rowDragManaged={true}
                                                rowDragMultiRow={true}
                                                rowMultiSelectWithClick={true}
                                                rowSelection='multiple'
                                                domLayout='autoHeight'
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <OrderCreditNotes />
                </div>
            </div>

            <a href="/Orders" id="lnkOrdersSearch" className="hidden">Search</a>
            <a href="/" id="lnkLoadPage" className="hidden">Reload</a>
            <a href="/" id="lnkOpenNewPage" target="_blank" className="hidden" rel="noreferrer">Open</a>

            <Modal open={openAdjustPaymentModal} className="w-1/4 max-w-full max-h-full" style={{ zIndex: 1000 }}>
                <Modal.Body>
                    <AdjustModal setOpenAdjustPaymentModal={setOpenAdjustPaymentModal} orderId={orderData.orderNumber} amount={currentAmountReceipt} />
                </Modal.Body>
            </Modal>

            <Modal open={openAddVendorModal} className="w-auto max-w-full max-h-full" style={{ zIndex: 1000 }}>
                <Modal.Body>
                    <AddVendorModal orderId={orderId} isClaim={isClaim()}
                        onCloseModal={onCloseAddClaimVendorModal}></AddVendorModal>;
                </Modal.Body>
            </Modal>

            {isModalOpen &&
                <div className={"modal-wrapper"}>
                    <div className={"modal-content " + modalClass}>
                        {
                            isQuoteOrderSearch &&
                            <Button size="sm" color="ghost" shape="circle"
                                className="absolute right-0 mr-[35px] min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                                onClick={(e) => onCloseModal()}
                            >x</Button>
                        }
                        <div>
                            {quoteOrdersModalContent}
                        </div>
                    </div>
                </div>
            }
            <div className="">
                <Modal ref={billGroupModalRef} className="order-bg-modal min-h-[700px] min-w-[1350px]">
                    <Modal.Body>
                        {billGroupModalContent}
                    </Modal.Body>
                </Modal>
            </div>

            {/* Order/Line modals */}
            <div>
                {modalContent}
            </div>

            {/* Order Info */}
            <div>
                {jobModalContent}
            </div>

            {/* ActivityLog */}
            <div>
                {activityLogModalContent}
            </div>

            {/* Payments */}
            <div>
                <dialog className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" open={isPaymentsModalOpen}>
                    {paymentModalContent}
                </dialog>
            </div>

            {/* Receipts */}
            <div>
                <ReceiptsModal orderId={orderId} orderNum={orderData.orderNumber}
                    isOpen={isReceiptsModalOpen} onClose={handleCloseReceiptsModal}
                    openAdjustModal={setOpenAdjustPaymentModal}
                    setCurrentAmountReceipt={setCurrentAmountReceipt} />
            </div>
        </div>
    );
}