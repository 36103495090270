import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import authService from '../../../services/authService.js';
import {
    makePayment,
    getCardToken,
    makePaymentByToken,
    makeAchPayment,
    postTransactionToDatabase,
    getCardMachinesForUser
} from '../../../services/paymentService';
import { ManualEntryContent } from '../_forms/ManualEntryContent';
import { OnFileContent } from '../_forms/OnFileContent';
import { SwipeContent } from '../_forms/SwipeContent';
import { CheckContent } from '../_forms/CheckContent';
import { CashContent } from '../_forms/CashContent';
import { ECheckContent } from '../_forms/ECheckContent';
import { LinkContent } from '../_forms/LinkContent';
import is from 'date-fns/locale/is';

const CardMachineModal = ({ isOpen, onClose, onSubmit, cardMachines, paymentAmount }) => {
    const [selectedMachineId, setSelectedMachineId] = useState('');

    // Set initial selection when modal opens
    useEffect(() => {
        if (isOpen && cardMachines.length === 1) {
            onSubmit(cardMachines[0]);
            return;
        }

        // Set initial selection for multiple machines
        if (isOpen && cardMachines.length > 1) {
            const defaultMachine = cardMachines.find(m => m.isDefault) || cardMachines[0];
            setSelectedMachineId(defaultMachine.id);
        }
    }, [isOpen, cardMachines, onSubmit]);

    if (!isOpen || cardMachines.length === 1) return null;

    const handleSubmit = () => {
        const machine = cardMachines.find(m => m.id === selectedMachineId) || cardMachines[0];
        onSubmit(machine);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
            <div className="bg-white p-4 pt-2 rounded-lg shadow-lg w-full max-w-sm">
                <div className="relative pb-2 mb-4 flex items-center justify-between">
                    <h3 className="font-bold text-xl text-center flex-grow">
                        Select Card Reader
                    </h3>
                    <button
                        onClick={onClose}
                        className="btn btn-sm btn-circle btn-ghost"
                    >
                        ✕
                    </button>
                </div>

                <div className="mb-4">
                    <select
                        value={selectedMachineId}
                        onChange={(e) => setSelectedMachineId(e.target.value)}
                        className="select select-bordered w-full"
                    >
                        {cardMachines.map(machine => (
                            <option key={machine.id} value={machine.id}>
                                {machine.name} {machine.isDefault ? '(Default)' : ''}
                            </option>
                        ))}
                    </select>
                </div>

                <button
                    onClick={handleSubmit}
                    className="btn btn-sm w-full bg-green-500 hover:bg-green-600 text-white font-bold"
                >
                    Process Payment (${formatCurrency(paymentAmount)})
                </button>
            </div>
        </div>
    );
};

const PaymentStatusModal = ({ isOpen, onClose, success, message, isSendLink = false }) => {
    if (!isOpen) return null;

    console.log('Is Link:', isSendLink);
    const handleClose = () => {
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
            <div className="bg-white p-4 pt-2 rounded-lg shadow-lg w-full max-w-sm">
                <div className="relative pb-2 mb-4 flex items-center justify-between">
                    <h3 className="font-bold text-xl text-center flex-grow">
                        {isSendLink ? 'Send Payment Link' :
                            message.invoices.length > 1 ? 'Process Payment - Multiple Invoices' : 'Process Payment'}
                    </h3>
                    <button
                        onClick={handleClose}
                        className="btn btn-sm btn-circle btn-ghost"
                    >
                        ✕
                    </button>
                </div>

                {!isSendLink ? (
                    <div className="mb-4 border-b pb-4">
                        {message.invoices.length === 1 ? (
                            // Single invoice 
                            <div className="flex justify-between text-sm">
                                <div>
                                    <span className="text-black font-semibold">Order #: </span>
                                    <span className="font-bold">{message.invoices[0].orderNumber}</span>
                                </div>
                                <div>
                                    <span className="text-black font-semibold">Balance Due: </span>
                                    <span className={success ? 'text-black font-bold' : 'text-red-500 font-bold'}>
                                        ${(message.invoices[0].balanceDue - message.invoices[0].paymentAmount).toFixed(2)}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            // Multiple invoices display
                            <div className="space-y-2">
                                {message.invoices.map((invoice, index) => (
                                    <div key={invoice.orderNumber} className="flex justify-between text-sm">
                                        <div>
                                            <span className="text-black font-semibold">Order #: </span>
                                            <span className="font-bold">{invoice.orderNumber}</span>
                                        </div>
                                        <div>
                                            <span className="text-black font-semibold">Balance Due: </span>
                                            <span className={`font-bold ${invoice.balanceDue - invoice.paymentAmount > 0 ? 'text-red' : 'text-black'}`}>
                                                ${(invoice.balanceDue - invoice.paymentAmount).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="mb-4 border-b pb-4">
                        <p className="text-center text-lg font-semibold">
                            Payment Link sent successfully
                        </p>
                    </div>
                )}
                <hr />
                <button
                    onClick={handleClose}
                    className={`w-full mt-2 py-2 px-4 rounded-md text-center text-white font-semibold 
                        ${success
                            ? 'bg-green-500 hover:bg-green-600'
                            : 'bg-red-500 hover:bg-red-600'
                        }`}
                >
                    {isSendLink ? 'Close' :
                        success ? 'Payment Approved' : 'Unsuccessful - Call Bank!'}
                </button>
            </div>
        </div>
    );
};

export function PaymentModal({
    onClose,
    invoices,
    confirmPayment
}) {
    // Refs
    const gridRef = useRef();

    const [cardMachines, setCardMachines] = useState([]);
    const [orderNumber, setOrderNumber] = useState(invoices[0]?.orderNumber || '');

    const [invoiceList, setInvoiceList] = useState(invoices.map(invoice => ({
        ...invoice,
        invoiceId: invoice.invoiceId || 0,
        orderId: invoice.orderId || 0,
        orderNumber: invoice.orderNumber || '',
        paymentAmount: invoice.paymentAmount || invoice.balanceDue || 0,
        accountName: invoice.accountName || ''
    })));

    const [totalAmount, setTotalAmount] = useState(
        invoices.reduce((sum, inv) => sum + (inv.paymentAmount || inv.balanceDue || 0), 0)
    );

    const [balanceDue, setBalanceDue] = useState(
        invoices.reduce((sum, inv) => sum + (inv.balanceDue || 0), 0)
    );

    // States
    // Payment state
    const [paymentMethod, setPaymentMethod] = useState('creditCard');
    const [entryMethod, setEntryMethod] = useState('manual');
    const [paymentAmountLabel, setPaymentAmountLabel] = useState('Payment Amount:');
    const [accountName, setAccountName] = useState(invoices[0]?.accountName || '');
    const [paymentAmount, setPaymentAmount] = useState(
        invoices.reduce((sum, inv) => sum + (inv.balanceDue || 0), 0).toFixed(2)
    );
    const [individualPayments, setIndividualPayments] = useState(
        invoices.map(invoice => ({
            invoiceId: invoice.invoiceId,
            orderId: invoice.orderId,
            orderNumber: invoice.orderNumber,
            amount: invoice.balanceDue || 0
        }))
    );

    // Card payment state
    const [selectedCardId, setSelectedCardId] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvc, setCvc] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [saveCard, setSaveCard] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [storedPaymentType, setStoredPaymentType] = useState('');

    // Check payment state
    const [checkNumber, setCheckNumber] = useState('');

    // E-Check payment state
    const [bankName, setBankName] = useState('');
    const [accountType, setAccountType] = useState('checking');
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');

    // Link payment state
    const [linkPayerName, setLinkPayerName] = useState('');
    const [linkEmail, setLinkEmail] = useState('');
    const [linkText, setLinkText] = useState('');
    const [linkMessage, setLinkMessage] = useState('');

    // Cash payment state
    const [cashTendered, setCashTendered] = useState('');
    const [balance, setBalance] = useState('');
    const [changeDue, setChangeDue] = useState('');

    // UI state
    const [userData, setUserData] = useState(null);
    const [readerStatus, setReaderStatus] = useState('Reader Active - Awaiting');
    const [selectedRow, setSelectedRow] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showCardMachineModal, setShowCardMachineModal] = useState(false);
    const [showPaymentAmount, setShowPaymentAmount] = useState(false);
    const [paymentStep, setPaymentStep] = useState('initial');
    const [paymentStatus, setPaymentStatus] = useState({
        success: false,
        message: {
            orderNumber: '',
            balance: 0,
            amountPaid: 0
        }
    });

    useEffect(() => {
        const initializeData = async () => {
            try {
                // User data
                const user = await authService.getCurrentUser();
                setUserData(user);

                // Get card machines for user
                if (user?.userId) {
                    const machines = await getCardMachinesForUser(user.userId);
                    //console.log('Machines:', machines);
                    if (machines) {
                        setCardMachines(machines);

                        // Default machine if available
                        const defaultMachine = machines.find(m => m.isDefault);
                        if (defaultMachine) {
                            // Do something with this?
                            //console.log('Default card machine:', defaultMachine);
                        }
                    }
                }
            } catch (error) {
                console.error('Error getting user data:', error);
                setError('Error loading user');
            }
        };

        initializeData();
    }, []);

    // Clear fields on payment method change
    useEffect(() => {
        setCardNumber('4111111111111111');   // TODO: remove - temp for testing
        //setCardNumber('');
        setCardholderName('');
        setExpiryDate('');
        setCvc('');
        setZipCode('');
        setCheckNumber('');
        setSelectedCardId('');
        setSelectedRow(null);
        setBankName('');
        setAccountNumber('');
        setConfirmAccountNumber('');
        setRoutingNumber('');
        setConfirmRoutingNumber('');
        setNameOnAccount('');
        if (invoices.length < 1) setTotalAmount(balanceDue);
        if (paymentMethod === 'creditCard') {
            setEntryMethod('manual');
        }
    }, [paymentMethod, balanceDue, invoices]);

    useEffect(() => {
        setPaymentAmount(balanceDue.toFixed(2));
        setCashTendered(balanceDue.toFixed(2));
    }, [balanceDue]);

    const invoiceColumnDefs = [
        {
            headerName: "",
            field: "invoiceId",
            flex: 1,
            hide: true
        },
        {
            headerName: "Invoice #",
            field: "orderNumber",
            flex: 1
        },
        {
            headerName: "Balance Due",
            field: "balanceDue",
            flex: 1,
            cellRenderer: params => {
                const balanceDue = params.value;
                return (
                    <div className={`font-semibold ${balanceDue > 0 ? 'text-red-500' : ''}`}>
                        ${formatCurrency(balanceDue)}
                    </div>
                );
            }
        },
        {
            headerName: "Amount Paid",
            field: "paymentAmount",
            flex: 1,
            editable: true,
            cellStyle: { cursor: 'pointer' },
            singleClickEdit: true,
            enableGroupEdit: true,
            cellRenderer: params => `$${formatCurrency(params.value)}`,
            valueParser: params => {
                const newValue = parseFloat(params.newValue);
                return isNaN(newValue) ? params.oldValue : Math.min(newValue, params.data.balanceDue);
            },
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 2,
            }
        },
        {
            headerName: "",
            width: 50,
            cellRenderer: params => (
                <button
                    onClick={() => handleRemoveInvoice(params.data.invoiceId)}
                    className="text-red-500 hover:text-red-700 font-bold"
                >
                    ✕
                </button>
            )
        }
    ];

    const getModalWidth = () => {
        if (paymentMethod === 'creditCard' && entryMethod === 'file') {
            return invoices.length > 1
                ? 'w-full max-w-4xl'  // Multiple invoices + OnFile
                : 'w-full max-w-3xl';  // Single invoice + OnFile
        }
        return invoices.length > 1
            ? 'w-full max-w-3xl'  // Multiple invoices
            : 'w-full max-w-sm';  // Single invoice
    };

    const getBreadcrumbs = () => {
        const crumbs = [];
        if (paymentStep === 'method' || paymentStep === 'payment') {
            crumbs.push({
                label: paymentMethod === 'creditCard' ? 'Credit Card' :
                    paymentMethod === 'check' ? 'Check' :
                        paymentMethod === 'cash' ? 'Cash' :
                            paymentMethod === 'echeck' ? 'E-Check' :
                                paymentMethod === 'sendLink' ? 'Send Link' : '',
                onClick: () => setPaymentStep('initial')
            });
        }
        if (paymentStep === 'payment' && paymentMethod === 'creditCard') {
            crumbs.push({
                label: entryMethod === 'manual' ? 'Manual Entry' :
                    entryMethod === 'swipe' ? 'Swipe/Tap' : 'Card On File',
                onClick: () => setPaymentStep('method')
            });
        }
        return crumbs;
    };

    const getSubmitButtonText = () => {
        if (isSubmitting) {
            return (
                <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                </span>
            );
        }

        if (paymentMethod === 'creditCard' && entryMethod === 'swipe') {
            return (
                <span className="flex items-center justify-center gap-4">
                    <span className="swipe-icon-left" />
                    {readerStatus || 'Reader Active - Awaiting'}
                    <span className="swipe-icon-right" />
                </span>
            );
        }

        const amount = formatCurrency(invoices.length > 1 ? totalAmount : paymentAmount);
        let prefix = (paymentMethod === 'check' || paymentMethod === 'cash' || paymentMethod === 'echeck' || paymentMethod === 'sendLink')
            ? 'Submit'
            : 'Process';

        if (paymentMethod === 'sendLink') prefix = 'Send Payment Link for ';

        return (
            <>
                {paymentMethod === 'creditCard' && (
                    <span className="lock-icon mr-2 -mt-1" aria-hidden="true" />
                )}
                {`${prefix} $${amount}`}
            </>
        );
    };

    const renderBreadcrumbs = () => {
        const crumbs = getBreadcrumbs();
        if (crumbs.length === 0) return null;

        return (
            <div className="flex items-center text-sm mb-4 -mt-4">
                {crumbs.map((crumb, index) => (
                    <React.Fragment key={index}>
                        <button
                            onClick={crumb.onClick}
                            className="text-blue-500 hover:text-blue-700 underline underline-offset-4 font-semibold"
                        >
                            {crumb.label}
                        </button>
                        {index < crumbs.length - 1 && (
                            <span className="text-lg w-4 pl-1 font-semibold"> › </span>
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const renderInitialButtons = () => (
        <div className="flex flex-col gap-6">
            <button
                className="w-full btn-submit-modal font-semibold"
                onClick={() => {
                    setPaymentMethod('creditCard');
                    setPaymentStep('method');
                }}
            >
                Credit Card
            </button>

            <button
                className="w-full btn-submit-modal font-semibold"
                onClick={() => {
                    setPaymentMethod('check');
                    setPaymentStep('payment');
                }}
            >
                Check
            </button>

            <button
                className="w-full btn-submit-modal font-semibold"
                onClick={() => {
                    setPaymentMethod('cash');
                    setPaymentStep('payment');
                }}
            >
                Cash
            </button>

            {/* TODO: Check for admin */}
            <button
                className="w-full btn-submit-modal font-semibold"
                onClick={() => {
                    setPaymentMethod('echeck');
                    setPaymentStep('payment');
                }}
            >
                E-Check
            </button>

            <button
                className="w-full btn-submit-modal font-semibold"
                onClick={() => {
                    setPaymentMethod('sendLink');
                    setPaymentStep('payment');
                }}
            >
                Send Payment Link
            </button>
        </div>
    );

    const renderCreditCardMethods = () => (
        <div className="space-y-4">
            {renderBreadcrumbs()}

            <div className="flex flex-col gap-4">
                <button
                    className="w-full btn-submit-modal font-semibold"
                    onClick={() => {
                        setEntryMethod('manual');
                        setPaymentStep('payment');
                    }}
                >
                    Manual Entry
                </button>
                <button
                    className="w-full btn-submit-modal font-semibold"
                    onClick={() => {
                        setEntryMethod('swipe');
                        setPaymentStep('payment');
                    }}
                >
                    Swipe / Tap
                </button>
                <button
                    className="w-full btn-submit-modal font-semibold"
                    onClick={() => {
                        setEntryMethod('file');
                        setPaymentStep('payment');
                    }}
                >
                    Card On File
                </button>
            </div>
        </div>
    );

    const renderPaymentForm = () => {
        return (
            <div className="space-y-4">
                {renderBreadcrumbs()}

                {/* Payment Amount */}
                {invoices.length === 1 && paymentMethod !== 'cash' && (
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text font-semibold">{paymentAmountLabel}</span>
                        </label>
                        <div className="flex gap-4">
                            <div className="relative w-1/3">
                                <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-8">
                                    $
                                </span>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={paymentAmount}
                                    onChange={handlePaymentAmountChange}
                                    className="payment-input w-[130px] pl-8 font-bold text-right bg-sky-50"
                                    placeholder="0.00"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Payment Method Content */}
                {paymentMethod === 'creditCard' && (
                    <>
                        {entryMethod === 'manual' && (
                            <ManualEntryContent
                                cardholderName={cardholderName}
                                setCardholderName={setCardholderName}
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                expiryDate={expiryDate}
                                handleExpiryChange={handleExpiryChange}
                                cvc={cvc}
                                setCvc={setCvc}
                                zipCode={zipCode}
                                setZipCode={setZipCode}
                                getCardType={getCardType}
                                formatCardNumber={formatCardNumber}
                                saveCard={saveCard}
                                setSaveCard={setSaveCard}
                            />
                        )}
                        {entryMethod === 'swipe' && (
                            <SwipeContent readerStatus={readerStatus} />
                        )}
                        {entryMethod === 'file' && (
                            <OnFileContent
                                setCardholderName={setCardholderName}
                                setCardNumber={setCardNumber}
                                setSelectedCardId={setSelectedCardId}
                                setPaymentType={setStoredPaymentType}
                                orderId={invoiceList[0].orderId}
                            />
                        )}
                    </>
                )}
                {paymentMethod === 'check' && (
                    <CheckContent
                        checkNumber={checkNumber}
                        setCheckNumber={setCheckNumber}
                    />
                )}
                {paymentMethod === 'cash' && (
                    <CashContent
                        paymentAmount={paymentAmount}
                        balanceDue={balanceDue}
                        cashTendered={cashTendered}
                        handleCashTenderedChange={handleCashTenderedChange}
                        changeDue={changeDue}
                    />
                )}
                {paymentMethod === 'echeck' && (
                    <ECheckContent
                        accountType={accountType}
                        setAccountType={setAccountType}
                        bankName={bankName}
                        setBankName={setBankName}
                        accountNumber={accountNumber}
                        setAccountNumber={setAccountNumber}
                        confirmAccountNumber={confirmAccountNumber}
                        setConfirmAccountNumber={setConfirmAccountNumber}
                        routingNumber={routingNumber}
                        setRoutingNumber={setRoutingNumber}
                        confirmRoutingNumber={confirmRoutingNumber}
                        setConfirmRoutingNumber={setConfirmRoutingNumber}
                        nameOnAccount={nameOnAccount}
                        setNameOnAccount={setNameOnAccount}
                    />
                )}
                {paymentMethod === 'sendLink' && (
                    <LinkContent
                        linkPayerName={linkPayerName}
                        setLinkPayerName={setLinkPayerName}
                        linkEmail={linkEmail}
                        setLinkEmail={setLinkEmail}
                        linkText={linkText}
                        setLinkText={setLinkText}
                        linkMessage={linkMessage}
                        setLinkMessage={setLinkMessage}
                    />
                )}
            </div>
        );
    };


    // Card formatting
    const getCardType = useCallback((number) => {
        const cleanNumber = number.replace(/\D/g, '');
        if (cleanNumber.match(/^3[47]/)) return 'amex';
        if (cleanNumber.match(/^4/)) return 'visa';
        if (cleanNumber.match(/^5[1-5]/)) return 'mastercard';
        if (cleanNumber.match(/^6/)) return 'discover';
        return 'unknown';
    }, []);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    const formatCardNumber = useCallback((value) => {
        const cleanValue = value.replace(/\D/g, '');
        const cardType = getCardType(cleanValue);

        if (cardType === 'amex') {
            return cleanValue.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
        }

        return cleanValue.replace(/(\d{4})(\d{4})?(\d{4})?(\d{4})?/, (_, p1, p2, p3, p4) => {
            let output = p1;
            if (p2) output += ' ' + p2;
            if (p3) output += ' ' + p3;
            if (p4) output += ' ' + p4;
            return output;
        });
    }, [getCardType]);

    const handleExpiryChange = useCallback((e) => {
        const { value } = e.target;
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length >= 2) {
            setExpiryDate(cleanValue.slice(0, 2) + ' / ' + cleanValue.slice(2, 4));
        } else {
            setExpiryDate(cleanValue);
        }
    }, []);

    const handleCashTenderedChange = (e) => {
        console.log('Cash Tendered:', e.target.value);
        const tenderedAmount = parseFloat(e.target.value) || 0;
        setCashTendered(tenderedAmount);

        if (tenderedAmount < balanceDue) {
            setPaymentAmount(tenderedAmount);
        } else {
            setPaymentAmount(balanceDue);
        }

        if (e.target.payment !== undefined) {
            setPaymentAmount(e.target.payment.toFixed(2));
        }

        // Calculate change due
        if (tenderedAmount > balanceDue) {
            setChangeDue((tenderedAmount - balanceDue).toFixed(2));
        } else {
            setChangeDue('0.00');
        }
    };

    // Payment Amount
    const handlePaymentAmountChange = (e) => {
        const payment = parseFloat(e.target.value) || 0;
        setPaymentAmount(payment);
        setCashTendered(payment);

        if (paymentMethod === 'cash') {
            const change = cashTendered - payment;

            if (change > 0) {
                setChangeDue(change.toFixed(2));
                setBalance('0.00');
            } else {
                setChangeDue('0.00');
                setBalance(Math.abs(change).toFixed(2));
            }

        }
    };
    
    // Submit
    const handleSubmit = async (e, machine = null) => {
        e.preventDefault();

        // Send Link
        if (paymentMethod === 'sendLink') {
            setPaymentStatus({
                success: true,
                message: {
                    invoices: [],
                    isSendLink: true
                }
            });
            setShowStatusModal(true);
            return;
        }

        const amountInCents = Math.round(parseFloat(paymentAmount) * 100);

        // Card Machines
        if (paymentMethod === 'creditCard' || paymentMethod === 'echeck') {
            // Use machine from modal if available
            if (machine) {
                setSelectedMachine(machine);
            }
            else if (!selectedMachine) {
                // Single machine
                if (cardMachines.length === 1) {
                    setSelectedMachine(cardMachines[0]);
                }
                // Multiple machines - show modal
                else if (cardMachines.length > 1) {
                    setShowCardMachineModal(true);
                    return;
                }
                // No machines
                else {
                    setError('No card machines configured');
                    return;
                }
            }
        }

        setIsSubmitting(true);
        setError('');

        try {
            let token = '';
            let paymentResponse = null;

            // Payment Type
            let paymentType = paymentMethod === 'creditCard'
                ? (() => {
                    if (entryMethod === 'file') {
                        return storedPaymentType || paymentResponse?.CardType || 'UNKNOWN';
                    }
                    const firstDigit = cardNumber.replace(/\D/g, '').charAt(0);
                    return firstDigit === '3' ? 'AMEX' :
                        firstDigit === '4' ? 'VISA' :
                            firstDigit === '5' ? 'MC' :
                                firstDigit === '6' ? 'DISCOVER' : 'VISA';
                })()
                : paymentMethod === 'cash' ? 'CASH'
                    : paymentMethod === 'check' ? 'CHECK'
                        : paymentMethod === 'echeck' ? 'ACH'
                            : '';

            console.log('Payment Type:', paymentType);

            // Cash/Check
            if (paymentMethod === 'cash' || paymentMethod === 'check') {
                const model = {
                    paymentId: "",
                    description: invoiceList.length > 1
                        ? invoiceList.map(inv => `#${inv.orderNumber}`).join(', ')
                        : `#${invoiceList[0].orderNumber}`,
                    paymentType,
                    status: "Success",
                    fullName: "",
                    amount: paymentAmount,
                    checkNumber: paymentMethod === 'check' ? checkNumber : "",
                    identifier: "",
                    accountNumber: "",
                    routingNumber: "",
                    bankName: "",
                    accountType: "",
                    token: "",
                    orderId: invoiceList[0].orderId,
                    orderNumber: invoiceList[0].orderNumber,
                    invoiceId: invoiceList[0].invoiceId,
                    billingGroupId: 0,
                    userId: userData?.userId || 0,
                    userName: userData?.userName || "",
                    lastUsed: new Date().toISOString(),
                    invoices: invoiceList.map(invoice => ({
                        invoiceId: invoice.invoiceId,
                        orderId: invoice.orderId,
                        amountPaid: invoices.length === 1 ? parseFloat(paymentAmount) : invoice.paymentAmount,
                        datePaid: new Date().toISOString()
                    })),
                    GTRC: ""
                };

                console.log('Processing cash/check payment:', model);
                await confirmPayment(model);

                setPaymentStatus({
                    success: true,
                    message: {
                        invoices: invoiceList.map(invoice => ({
                            orderNumber: invoice.orderNumber,
                            balanceDue: invoice.balanceDue,
                            paymentAmount: invoice.paymentAmount
                        }))
                    }
                });
                setShowStatusModal(true);
                return;
            }

            // Card/ACH
            if (paymentMethod === 'creditCard' || paymentMethod === 'ach') {
                console.log('Processing credit card payment:', entryMethod);

                if (entryMethod === 'manual') {
                    const paymentRequest = {
                        TransType: "Sale",
                        Medium: "Credit",
                        EntryMode: "Manual",
                        AccountNum: cardNumber.replace(/\D/g, ''),
                        ExpDate: expiryDate.replace(/\D/g, ''),
                        Cvv: cvc,
                        MainAmt: amountInCents,
                        InvoiceNum: orderNumber,
                    };

                    if (saveCard) {
                        paymentRequest.TransType = "CreateCardToken";
                        const tokenResponse = await getCardToken(paymentRequest);
                        token = tokenResponse.CardToken;

                        if (token) {
                            paymentResponse = await makePaymentByToken(token, parseFloat(paymentAmount));
                        }
                    } else {
                        console.log('Making payment with selectedMachine: ', selectedMachine);
                        paymentResponse = await makePayment(paymentRequest, selectedMachine);
                    }
                    // Last 4
                    const identifier = cardNumber.replace(/\D/g, '').slice(-4);
                    console.log('Identifier:', identifier);
                } else if (entryMethod === 'file') {
                    paymentResponse = await makePaymentByToken(selectedCardId, parseFloat(paymentAmount));
                    token = selectedCardId;
                    paymentType = storedPaymentType || paymentResponse.CardType || 'UNKNOWN';
                    console.log('File response:', paymentResponse);
                } else if (entryMethod === 'swipe') {
                    // Swipe code here
                }

                console.log('Payment response:', paymentResponse);
            } else if (paymentMethod === 'echeck') {
                const achData = {
                    EleCheckRoutingNumber: routingNumber,
                    EleCheckAccountNumber: accountNumber,
                    AccountType: accountType.toUpperCase(),
                    MainAmt: amountInCents,
                    NameOnAccount: nameOnAccount,
                    Identifier: accountNumber.slice(-4),
                    BankName: bankName,
                    UserId: userData?.userId || 0,
                    UserName: userData?.userName || ''
                };
                console.log('ACH request:', { ...achData, EleCheckAccountNumber: '****' });
                paymentResponse = await makeAchPayment(achData);
                console.log('ACH response:', paymentResponse);
                token = token || accountNumber.slice(-4); // Set token to last 4 of account number for ACH
            }

            // Save transaction first (Cards/ACH payments)
            if (!paymentResponse) {
                throw new Error('No payment response received');
            }

            console.log('Creating transaction model');
            const transactionModel = {
                transactionIdentifier: paymentResponse.TransactionIdentifier,
                transactionResponse: JSON.stringify(paymentResponse),
                responseMessage: paymentResponse.ErrorText || '',
                cardToken: token,
                status: paymentResponse.ResultText || 'Success',
                userId: userData?.userId || 0,
                transactionDate: new Date().toISOString(),
                GTRC: paymentResponse.GTRC
            };

            const transactionResult = await postTransactionToDatabase(transactionModel);
            console.log('Transaction save result:', transactionResult);

            if (!transactionResult.success) {
                throw new Error('Failed to save transaction to database');
            }

            // If transaction success, create payment
            const isSuccessful = paymentResponse && paymentResponse.ErrorCode === '000';
            console.log('Payment success status:', isSuccessful);

            if (isSuccessful) {
                console.log('Creating payment model');
                const paymentModel = {
                    ...createPaymentModel(token, paymentResponse),
                    paymentType,
                    transactionId: transactionResult.data.transactionId
                };

                await confirmPayment(paymentModel);
                console.log('Payment confirmed');

                // Single invoice/order, set paymentAmount
                if (invoiceList.length === 1) {
                    invoiceList[0].paymentAmount = paymentAmount;
                }
            }

            // Status modal
            const statusMessage = {
                success: isSuccessful,
                message: {
                    invoices: invoiceList.map(invoice => ({
                        orderNumber: invoice.orderNumber,
                        balanceDue: invoice.balanceDue,
                        paymentAmount: invoice.paymentAmount
                    }))
                }
            };
            setPaymentStatus(statusMessage);
            setShowStatusModal(true);

        } catch (error) {
            console.error('Payment processing error:', error);
            setError(error.response?.data?.message || error.message || 'An unknown error occurred');

            const errorStatus = {
                success: false,
                message: {
                    invoices: invoiceList.map(invoice => ({
                        orderNumber: invoice.orderNumber,
                        balanceDue: invoice.balanceDue,
                        paymentAmount: invoice.paymentAmount
                    }))
                }
            };
            console.log('Setting error status:', errorStatus);
            setPaymentStatus(errorStatus);
            setShowStatusModal(true);
        } finally {
            console.log('Payment submission process completed');
            setIsSubmitting(false);
        }
    };

    const createPaymentModel = (token = '', paymentResponse = null) => {
        const isOrderOnly = invoices[0].isOrderOnly;

        const paymentInvoices = isOrderOnly
            ? [{
                invoiceId: 0,
                orderId: invoiceList[0].orderId,
                amountPaid: parseFloat(paymentAmount),
                datePaid: new Date().toISOString()
            }]
            : invoiceList.map(invoice => ({
                invoiceId: invoice.invoiceId,
                orderId: invoice.orderId,
                amountPaid: invoices.length === 1 ? parseFloat(paymentAmount) : invoice.paymentAmount,
                datePaid: new Date().toISOString()
            }));

        const paymentType = paymentMethod === 'creditCard'
            ? (() => {
                if (entryMethod === 'file') {
                    return storedPaymentType || paymentResponse?.CardType || 'UNKNOWN';
                }
                const firstDigit = cardNumber.replace(/\D/g, '').charAt(0);
                return firstDigit === '3' ? 'AMEX' :
                    firstDigit === '4' ? 'VISA' :
                        firstDigit === '5' ? 'MC' :
                            firstDigit === '6' ? 'DISCOVER' : 'UNKNOWN';
            })()
            : paymentMethod === 'cash' ? 'CASH'
                : paymentMethod === 'check' ? 'CHECK'
                    : paymentMethod === 'echeck' ? 'ACH'
                        : '';

        const model = {
            paymentId: "",
            description: invoiceList.length > 1
                ? invoiceList.map(inv => `#${inv.orderNumber}`).join(', ')
                : `#${invoiceList[0].orderNumber}`,
            paymentType,
            status: paymentResponse ? paymentResponse.ResultText || "Success" : "Success",
            fullName: paymentMethod === 'creditCard' ? cardholderName :
                paymentMethod === 'echeck' ? nameOnAccount : "",
            amount: paymentAmount,
            checkNumber: paymentMethod === 'check' ? checkNumber : "",
            identifier: paymentMethod === 'creditCard' ?
                (entryMethod === 'manual' ? cardNumber.replace(/\D/g, '').slice(-4) :
                 entryMethod === 'file' ? cardNumber : "") :
                paymentMethod === 'echeck' ? accountNumber.slice(-4) : "",
            accountNumber: paymentMethod === 'echeck' ? accountNumber : "",
            routingNumber: paymentMethod === 'echeck' ? routingNumber : "",
            bankName: paymentMethod === 'echeck' ? bankName : "",
            accountType: paymentMethod === 'echeck' ? accountType : "",
            token: token || '',
            orderId: invoiceList[0].orderId,
            orderNumber: invoiceList[0].orderNumber,
            invoiceId: invoiceList[0].invoiceId,
            billingGroupId: 0,
            userId: userData?.userId || 0,
            userName: userData?.userName || "",
            lastUsed: new Date().toISOString(),
            invoices: paymentInvoices,
            transactionInfo: paymentResponse ? {
                transactionIdentifier: paymentResponse.TransactionIdentifier || '',
                transactionResponse: JSON.stringify(paymentResponse),
                responseMessage: paymentResponse.ErrorText || '',
                cardToken: token || '',
                status: paymentResponse.ResultText || 'Success',
                paymentId: '',
                userId: userData?.userId || 0,
                transactionDate: new Date().toISOString()
            } : null,
            GTRC: paymentResponse.GTRC || ''
        };
        console.log('Payment Model', model);
        return model;
    };

    const handleRemoveInvoice = useCallback((invoiceId) => {
        setInvoiceList(prevList => {
            const updatedList = prevList.filter(invoice => invoice.invoiceId !== invoiceId);
            return updatedList;
        });

        setIndividualPayments(prevPayments => {
            const updatedPayments = prevPayments.filter(payment => payment.invoiceId !== invoiceId);

            const newTotal = updatedPayments.reduce((sum, payment) =>
                sum + parseFloat(payment.amount || 0), 0
            );

            setTotalAmount(newTotal);
            setPaymentAmount(newTotal.toFixed(2));
            setCashTendered(newTotal.toFixed(2));
            return updatedPayments;
        });
    }, []);

    const onCellValueChanged = (params) => {
        if (params.colDef.field === 'paymentAmount') {
            const newValue = parseFloat(params.newValue);

            setInvoiceList(currentList =>
                currentList.map(item => {
                    // Match by invoiceId if it exists, otherwise by orderId
                    const isMatch = item.invoiceId
                        ? item.invoiceId === params.data.invoiceId
                        : item.orderId === params.data.orderId;

                    return isMatch ? { ...item, paymentAmount: newValue } : item;
                })
            );

            setIndividualPayments(currentPayments =>
                currentPayments.map(payment => {
                    // Match by invoiceId if it exists, otherwise by orderId
                    const isMatch = payment.invoiceId
                        ? payment.invoiceId === params.data.invoiceId
                        : payment.orderId === params.data.orderId;

                    return isMatch ? { ...payment, amount: newValue } : payment;
                })
            );

            const newTotal = gridRef.current.api.getModel().rowsToDisplay
                .reduce((sum, row) => sum + parseFloat(row.data.paymentAmount || 0), 0);

            setTotalAmount(newTotal);
            setPaymentAmount(newTotal.toFixed(2));
            setCashTendered(newTotal.toFixed(2));
        }
    };

    const isFormValid = useMemo(() => {
        // Payment amount
        const amount = parseFloat(paymentAmount);
        if (isNaN(amount) || amount <= 0) return false;

        // Multiple Invoices
        if (invoices.length > 1) {
            const sumOfInvoices = invoiceList.reduce((sum, invoice) =>
                sum + parseFloat(invoice.paymentAmount || 0), 0
            );

            if (Math.abs(amount - sumOfInvoices) > 0.01) {
                return false;
            }
        }

        // Credit Card 
        if (paymentMethod === 'creditCard') {
            switch (entryMethod) {
                case 'manual':
                    const cleanCardNumber = cardNumber.replace(/\D/g, '');
                    const isAmex = getCardType(cleanCardNumber) === 'amex';
                    return (
                        cardholderName.trim().length > 0 &&
                        (isAmex ? cleanCardNumber.length === 15 : cleanCardNumber.length === 16) &&
                        expiryDate.replace(/\D/g, '').length === 4 &&
                        (isAmex ? cvc.length === 4 : cvc.length === 3) &&
                        zipCode.length === 5
                    );
                case 'file':
                    return selectedCardId !== '';
                case 'swipe':
                    // Add swipe validation
                    return true;
                default:
                    return false;
            }
        }

        // Check 
        if (paymentMethod === 'check') {
            return checkNumber.trim().length > 0;
        }

        // E-Check 
        if (paymentMethod === 'echeck') {
            return (
                bankName.trim().length > 0 &&
                accountType &&
                accountNumber.length > 0 &&
                accountNumber === confirmAccountNumber &&
                routingNumber.length === 9 &&
                routingNumber === confirmRoutingNumber &&
                nameOnAccount.trim().length > 0
            );
        }

        return true;

    }, [invoiceList, invoices.length, paymentMethod, paymentAmount, entryMethod, cardholderName, cardNumber, expiryDate, cvc, zipCode, selectedCardId, checkNumber, bankName, accountType, accountNumber, confirmAccountNumber, routingNumber, confirmRoutingNumber, nameOnAccount, getCardType]);

    useEffect(() => {
        switch (paymentMethod) {
            case 'check':
                setPaymentAmountLabel('Check Amount:');
                break;
            default:
                setPaymentAmountLabel('Payment Amount:');
                break;
        }
    }, [paymentMethod]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className={`bg-white p-4 rounded-lg shadow-lg ${getModalWidth()}`}>
                <div className="flex justify-between items-center mb-2">
                    <h2 className="text-xl font-bold">
                        {invoices.length > 1 ? 'Process Payment - Multiple Invoices' : 'Process Payment'}
                    </h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
                </div>

                {/* Header Info */}
                <div className="mb-6">
                    {invoices.length === 1 ? (
                        <div className="flex justify-between items-center pb-2 border-b">
                            <div className="font-semibold">
                                Order #: <span>{orderNumber}</span>
                            </div>
                            <div className="font-semibold">
                                Balance Due: <span className="text-red-500">${balanceDue.toFixed(2)}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="pb-4 border-b">
                            <div>Account Name:</div>
                            <div className="font-bold">{accountName}</div>
                        </div>
                    )}
                </div>

                {/* Main Content Area */}
                <div className="flex gap-6">
                    {/* Payment Form */}
                    <div className={invoices.length > 1 ? 'w-1/2' : 'w-full'}>
                        <form onSubmit={handleSubmit} className="flex flex-col justify-between h-full">
                            {paymentStep === 'initial' && renderInitialButtons()}
                            {paymentStep === 'method' && renderCreditCardMethods()}
                            {paymentStep === 'payment' && renderPaymentForm()}

                            {/* Submit Button */}
                            {paymentStep === 'payment' && (
                                <button
                                    type="submit"
                                    disabled={!isFormValid || isSubmitting}
                                    className={`w-full py-2 text-sm mt-4 ${!isFormValid || isSubmitting
                                        ? 'bg-gray-400'
                                        : 'bg-green-500 hover:bg-green-600'
                                        } text-white rounded-md font-semibold`}
                                >
                                    {getSubmitButtonText()}
                                </button>
                            )}
                        </form>
                    </div>

                    {/* Invoice Grid for Multiple Invoices */}
                    {invoices.length > 1 && (
                        <div className="w-1/2">
                            <div className="h-[300px] ag-theme-alpine">
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={invoiceList}
                                    columnDefs={invoiceColumnDefs}
                                    suppressCellFocus={true}
                                    headerHeight={32}
                                    rowHeight={32}
                                    stopEditingWhenCellsLoseFocus={true}

                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true
                                    }}
                                    onCellValueChanged={onCellValueChanged}
                                />
                            </div>
                            <div className="mt-4 flex justify-end items-center">
                                <span className="mr-2">Total Payment Amount:</span>
                                <div className="w-32 relative">
                                    <span className="absolute left-2 top-2 bottom-2 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-8">
                                        $
                                    </span>
                                    <input
                                        type="text"
                                        value={formatCurrency(totalAmount)}
                                        className="w-full pl-6 pr-2 py-1 border rounded text-right bg-gray-100"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <CardMachineModal
                isOpen={showCardMachineModal}
                onClose={() => setShowCardMachineModal(false)}
                onSubmit={(machine) => {
                    setSelectedMachine(machine);
                    setShowCardMachineModal(false);
                    handleSubmit(null, machine); // Pass null for the event
                }}
                cardMachines={cardMachines}
                paymentAmount={invoices.length > 1 ? totalAmount : paymentAmount}
            />

            <PaymentStatusModal
                isOpen={showStatusModal}
                onClose={() => {
                    setShowStatusModal(false);
                    if (paymentStatus.success) {
                        onClose({
                            success: true,
                            message: `${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)} ${paymentMethod === 'sendLink' ? 'link sent' : 'payment successful'}`
                        });
                    } else {
                        onClose({
                            success: false,
                            message: `${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)} ${paymentMethod === 'sendLink' ? 'link failed' : 'payment failed'}`
                        });
                    }
                }}
                success={paymentStatus.success}
                message={paymentStatus.message}
                isSendLink={paymentMethod === 'sendLink'}
            />
        </div>
    );
}
