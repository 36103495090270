import React, { useState } from 'react';

export const LinkContent = ({
    linkEmail,
    setLinkEmail,
    linkPayerName,
    setLinkPayerName,
    linkText,
    setLinkText,
    linkMessage,
    setLinkMessage
}) => {
    const [isValidPhone, setIsValidPhone] = useState(true);

    const validatePhoneNumber = (phone) => {
        const cleanPhone = phone.replace(/\D/g, '');
        return cleanPhone.length === 10;
    };

    const formatPhoneNumber = (phone) => {
        const cleaned = phone.replace(/\D/g, '');

        // (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (!match) return phone;

        const parts = [match[1], match[2], match[3]].filter(Boolean);

        if (parts.length === 0) return '';
        if (parts.length === 1) return `(${parts[0]}`;
        if (parts.length === 2) return `(${parts[0]}) ${parts[1]}`;
        return `(${parts[0]}) ${parts[1]}-${parts[2]}`;
    };

    const handlePhoneChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setLinkText(formattedPhone);
        setIsValidPhone(validatePhoneNumber(formattedPhone));
    };

    return (
        <div className="space-y-3">
            <input
                type="text"
                placeholder="Payer Name"
                value={linkPayerName}
                onChange={(e) => setLinkPayerName(e.target.value)}
                className="payment-input"
                required
            />
            <input
                type="email"
                placeholder="Email Address"
                value={linkEmail}
                onChange={(e) => setLinkEmail(e.target.value)}
                className="payment-input w-full"
                required
            />
            <div>
                <input
                    type="tel"
                    placeholder="Text Phone Number"
                    value={linkText}
                    maxLength={14}
                    onChange={handlePhoneChange}
                    className={`payment-input ${!isValidPhone && linkText ? 'border-red-500' : ''}`}
                    required
                />
                {!isValidPhone && linkText && (
                    <div className="text-red-500 text-sm mt-1">
                        Please enter a valid phone number
                    </div>
                )}
            </div>
            <textarea
                placeholder="Thank you for your order!"
                value={linkMessage}
                onChange={(e) => setLinkMessage(e.target.value)}
                className="payment-input w-full min-h-[150px] resize-y"
                required
            />
        </div>
    );
};
