import React, { useState, useEffect } from 'react';

import { get, post } from '../../../services/apiService';
import { reversePayment, adjustPayment } from '../../../services/paymentService';
import { formatCurrencyDecStr } from '../../../js/formHelpers';

const AdjustStatusModal = ({ isOpen, onClose, success, message, selectedOption, parentOnClose }) => {
    if (!isOpen) {
        return null;
    }
    //console.log('Message:', message);

    const handleClose = () => {
        if (success) {
            onClose();
            parentOnClose();
        } else {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]"
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    handleClose();
                }
            }}
        >
            <div className="bg-white p-4 pt-2 rounded-lg shadow-lg">
                <div className="relative pb-2 mb-4 flex items-center justify-between">
                    <h3 className="font-bold text-xl text-center flex-grow">
                        {selectedOption === 'adjust' ? 'Adjust Payment' : 'Void Payment'}
                    </h3>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                        className="btn btn-sm btn-circle btn-ghost"
                    >
                        ✕
                    </button>
                </div>

                <div className="mb-4 border-b pb-4">
                    {message.orders ? (
                        // Multiple orders
                        <div className="space-y-2">
                            {message.orders.map((order, index) => (
                                <div key={index} className="flex justify-between text-sm border-b last:border-b-0 pb-2">
                                    <div>
                                        <span className="text-label font-semibold">Order #: </span>
                                        <span className="text-black font-bold">{order.orderNumber}</span>
                                    </div>
                                    <div>
                                        <span className="text-black font-semibold">Amount: </span>
                                        <span className={success ? 'text-black font-bold' : 'text-red-500 font-bold'}>
                                            {formatCurrencyDecStr(order.amount)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-end text-sm pt-2">
                                <div>
                                    <span className="text-black font-semibold">Total: </span>
                                    <span className={success ? 'text-black font-bold' : 'text-red-500 font-bold'}>
                                        {formatCurrencyDecStr(message.orders.reduce((sum, order) => sum + parseFloat(order.amount || 0), 0))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Single order
                        <div className="flex justify-between text-sm">
                            <div>
                                <span className="text-label font-semibold">Order #: </span>
                                <span className="text-black font-bold">{message.orderNumber}</span>
                            </div>
                            <div>
                                <span className="text-black font-semibold">Amount: </span>
                                <span className={success ? 'text-black font-bold' : 'text-red-500 font-bold'}>
                                    {formatCurrencyDecStr(message.amount)}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <button
                    onClick={handleClose}
                    className={`btn btn-sm w-full mt-2 py-2 px-4 rounded-md text-center text-white font-semibold 
                        ${success
                            ? 'bg-green-500 hover:bg-green-600'
                            : 'bg-red-500 hover:bg-red-600'
                        }`}
                >
                    {success ? (selectedOption === 'adjust' ? 'Adjustment Successful' : 'Void Approved') : 'Unsuccessful - Try Again!'}
                </button>
            </div>
        </div>
    );
};

export function AdjustModal({ isOpen, onClose, paymentDetailId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [orderAmounts, setOrderAmounts] = useState({});
    const [selectedOption, setSelectedOption] = useState('adjust');
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusMessage, setStatusMessage] = useState({
        success: false,
        orderNumber: '',
        amount: 0
    });

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            try {
                const response = await get(`payments/GetPaymentDetails/${paymentDetailId}`);
                console.log('Payment Details:', response);
                setPaymentDetails(response);

                // Init Amounts
                const amounts = {};
                if (response.orders) {
                    // Multiple orders
                    response.orders.forEach(order => {
                        amounts[order.orderId] = formatAmount(order.amountPaid);
                    });
                } else {
                    // Single order
                    amounts[response.orderId] = formatAmount(response.amountPaid);
                }
                setOrderAmounts(amounts);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching payment details:', err);
                setError('Error fetching payment details: ' + err.message);
                setLoading(false);
            }
        };

        if (paymentDetailId) {
            fetchPaymentDetails();
        }
    }, [paymentDetailId]);

    const formatAmount = (value) => {
        if (!value && value !== 0) return '0.00';
        let strValue = value.toString();
        strValue = strValue.replace(/[^\d.]/g, '');

        const parts = strValue.split('.');
        if (parts.length > 2) {
            strValue = parts[0] + '.' + parts.slice(1).join('');
        }

        const numValue = parseFloat(strValue);
        return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
    };

    const handleAmountChange = (orderId, value) => {
        setOrderAmounts(prev => ({
            ...prev,
            [orderId]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let result;

            if (selectedOption === 'adjust') {
                // Adjustment Amount
                const totalAmount = Object.values(orderAmounts).reduce((sum, amount) => sum + parseFloat(amount), 0);
                result = await adjustPayment(paymentDetails.paymentId, paymentDetails.gtrc, totalAmount);
            } else {
                // Void/Reverse Amount
                result = await reversePayment(paymentDetails.paymentId, paymentDetails.gtrc, paymentDetails.totalAmount);
            }

            // Status message
            const statusMsg = {
                success: result?.ErrorCode === '000'
            };

            if (paymentDetails.orders?.length > 1) {
                statusMsg.orders = paymentDetails.orders.map(order => ({
                    orderNumber: order.orderNumber,
                    amount: orderAmounts[order.orderId]
                }));
            } else if (paymentDetails.orders?.length === 1) {
                statusMsg.orderNumber = paymentDetails.orders[0].orderNumber;
                statusMsg.amount = orderAmounts[paymentDetails.orders[0].orderId];
            }

            setStatusMessage(statusMsg);
            setShowStatusModal(true);
        } catch (err) {
            console.error('Error processing payment:', err);
            const errorMessage = err.response?.data?.message
                || err.message
                || 'An unexpected error occurred';

            setError(errorMessage);

            // Error status message
            const statusMsg = {
                success: false
            };

            if (paymentDetails.orders?.length > 1) {
                statusMsg.orders = paymentDetails.orders.map(order => ({
                    orderNumber: order.orderNumber,
                    amount: orderAmounts[order.orderId]
                }));
            } else if (paymentDetails.orders?.length === 1) {
                statusMsg.orderNumber = paymentDetails.orders[0].orderNumber;
                statusMsg.amount = orderAmounts[paymentDetails.orders[0].orderId];
            }

            setStatusMessage(statusMsg);
            setShowStatusModal(true);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusModalClose = () => {
        setShowStatusModal(false);
    };

    if (!isOpen) return null;

    return (
        <>
            <div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                style={{ minHeight: "100%" }}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        onClose(false);
                    }
                }}
            >
                <div className="bg-white rounded-lg p-4 w-[500px] max-h-[80vh] overflow-y-auto">
                    <div className="relative mb-2 flex items-center justify-between">
                        <h3 className="font-bold text-xl text-center flex-grow">
                            Adjust Payment
                        </h3>
                        <button
                            onClick={onClose}
                            className="btn btn-sm btn-circle btn-ghost"
                        >
                            ✕
                        </button>
                    </div>

                    {paymentDetails?.orders?.length === 1 && (
                        <div className="mb-4 text-sm">
                            <div className="flex justify-between items-center">
                                <div className="flex gap-2">
                                    <span className="font-semibold">Order #:</span>
                                    <span className="font-bold">{paymentDetails.orders[0].orderNumber}</span>
                                </div>
                                <div className="flex gap-2">
                                    <span className="font-semibold">Original Amount:</span>
                                    <span className="font-bold">{formatCurrencyDecStr(paymentDetails.orders[0].amountPaid)}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col gap-4 pt-2 border-t-2">
                        <div className={`p-2 pl-1 rounded ${selectedOption === 'void' ? 'bg-gray-200' : 'bg-white'}`}>
                            <label className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    checked={selectedOption === 'adjust'}
                                    onChange={() => setSelectedOption('adjust')}
                                    className="radio radio-primary radio-checked:bg-primary bg-gray-200"
                                />
                                <span className="label-text font-bold">Adjust Payment</span>
                            </label>

                            {paymentDetails?.orders?.length === 1 && (
                                <div className="flex items-center gap-2 ml-6 mt-2 border-b-1">
                                    <span className="w-1/2 label-text font-semibold">New Amount:</span>
                                    <div className="relative">
                                        <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border border-gray-500 w-5">
                                            $
                                        </span>
                                        <input
                                            type="number"
                                            value={orderAmounts[paymentDetails.orders[0].orderId] || ''}
                                            onChange={(e) => handleAmountChange(paymentDetails.orders[0].orderId, e.target.value)}
                                            className="input font-bold input-bordered input-sm pl-6 rounded-r-md border-gray-500 w-24 text-right"
                                            step="0.01"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            )}

                            {selectedOption === 'adjust' && paymentDetails && paymentDetails.orders?.length > 1 && (
                                <div className="mt-4 space-y-4">
                                    {paymentDetails.orders.map((order, index) => (
                                        <div key={order.orderId} className="mb-4 text-sm border-b-2 pb-2">
                                            <div className="flex justify-between items-start">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex gap-2">
                                                        <span className="font-semibold">Order #:</span>
                                                        <span className="font-bold">{order.orderNumber}</span>
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <span className="font-semibold">Original Amount:</span>
                                                        <span className="font-bold">{formatCurrencyDecStr(order.amountPaid)}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <span className="label-text font-semibold">New Amount:</span>
                                                    <div className="relative">
                                                        <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border border-gray-500 w-5">
                                                            $
                                                        </span>
                                                        <input
                                                            type="number"
                                                            value={orderAmounts[order.orderId] || ''}
                                                            onChange={(e) => handleAmountChange(order.orderId, e.target.value)}
                                                            className="input font-bold input-bordered input-sm pl-6 rounded-r-md border-gray-500 w-24 text-right"
                                                            step="0.01"
                                                            min="0"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="ml-6 mt-2 flex justify-end items-center gap-2">
                                        <span className="label-text font-semibold">Total:</span>
                                        <span className="font-bold">
                                            {formatCurrencyDecStr(Object.values(orderAmounts).reduce((sum, amount) => sum + parseFloat(amount || 0), 0))}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={`p-2 pl-1 rounded ${selectedOption === 'adjust' ? 'bg-gray-200' : 'bg-white'}`}>
                            <label className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    checked={selectedOption === 'void'}
                                    onChange={() => setSelectedOption('void')}
                                    className="radio radio-primary radio-checked:bg-primary bg-gray-200"
                                />
                                <span className="label-text font-bold">Void Payment</span>
                            </label>

                            {paymentDetails?.orders?.length > 1 && (
                                <div className="flex items-center gap-2 ml-6 mt-2">
                                    <span className="w-1/2 label-text font-semibold">Cancel Amount:</span>
                                    <div className="relative">
                                        <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border border-gray-500 w-5">
                                            $
                                        </span>
                                        <input
                                            type="number"
                                            value={formatAmount(paymentDetails?.totalAmount) || 0}
                                            readOnly
                                            className="input font-bold input-bordered input-sm pl-6 rounded-r-md border-gray-500 w-24 text-right bg-gray-200"
                                            step="0.01"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {error && <div className="text-red-500 text-sm mt-2">{error}</div>}

                    <div className="mt-6">
                        <button
                            onClick={handleSubmit}
                            className="btn btn-sm w-full bg-green-500 hover:bg-green-600 text-white font-bold"
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : selectedOption === 'adjust' ?
                                `Adjust Payment (${formatCurrencyDecStr(Object.values(orderAmounts).reduce((sum, amount) => sum + parseFloat(amount || 0), 0))})` :
                                `Void Payment (${formatCurrencyDecStr(paymentDetails?.totalAmount || 0)})`}
                        </button>
                    </div>
                </div>
            </div>

            <AdjustStatusModal
                isOpen={showStatusModal}
                onClose={handleStatusModalClose}
                success={statusMessage.success}
                message={statusMessage}
                selectedOption={selectedOption}
                parentOnClose={onClose}
            />
        </>
    );
}