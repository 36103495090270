import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Label, Radio, Checkbox } from 'flowbite-react';

import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import {
    GetEnumSelectList,
    GetStoreSelectList,
    GetUserSelectList,
    StandardDataAndResponsePOST
} from '../../js/dataHelpers.js';
import { FormatDate, FormatDateStrMMDDYYYY, GetInputValuesCheckedByClass, SetPageTitle } from '../../js/helpers.js';
import {
    handleFieldChange, IsEmpty, enforcePhoneFormat, formatToPhone, IdExists,
    IsEmail, NotEmpty, IsFalse, PreventDefault, NotExists, ParseIntSafe, IsTrue, Exists, NotStringEmpty
} from '../../js/formHelpers.js';
import { get } from '../../services/apiService.js';
import { Equals } from '../../js/calculationHelpers';
import { DateFilter } from "@ag-grid-community/core";
import DatePicker from "react-datepicker";

//import { createVendorModel } from './_vendorHelpers.js';

export const SampleCheckoutAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina Sample Checkout");
    const location = useLocation();
    const navigate = useNavigate();

    const [otherState, setOtherState] = useState('');
    const [selecTedCategories, setSelecTedCategories] = useState([]);
    const [checkFolloweUp, setCheckFolloweUp] = useState(null);

    const dateAfetr2Days = new Date();
    dateAfetr2Days.setDate(dateAfetr2Days.getDate() + 2);
    const [selectedDate, setSelectedDate] = useState(dateAfetr2Days);

    const handleSelectCategories = (e) => {
        const { value } = e.target;
        setSelecTedCategories((prev) => {
            if (prev.includes(value)) {
                return prev.filter((item) => item !== value);
            }
            else {
                return [...prev, value];
            }
        });
    }

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [sampleCheckoutId, setSampleCheckoutId] = useState(GetLocationStateId("sampleCheckoutId"));
    const [sampleData, setSampleData] = useState(null);

    const categoriesList = ['Carpet', 'Hardwood', 'Laminate', 'LVT', 'Countertops', 'Cabinetry', 'Tile']
    const [userList, setUserList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [categories, setCategories] = useState([]);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const createMsg = "Sample created successfully.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    /** Methods **/

    function backToClick(e, create) {
        PreventDefault(e);

        var statusMsg = "";
        if (IsTrue(create)) {
            statusMsg = createMsg;
        }

        navigate('/SampleCheckout', {
            replace: true,
            state: { statusMsg }
        });
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            btnSubmitClick(e);
        }
    }

    /****** Data Methods ******/

    async function createStoreList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    async function createUserList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    function createCategories(data) {
        var categories = ['Carpet', 'Hardwood', 'Laminate', 'LVT', 'Countertops', 'Cabinetry', 'Tile'];

        var list = [];
        categories.forEach((item, index) => {
            var cbDisabled = IdExists(data.sampleCheckoutId);
            var defChecked = data.categoriesList.find((el) => el === item.toLowerCase()) ?? false;

            var rbId = "rbSC-" + item;
            list.push(
                <div className="flex items-center gap-1">

                    <Checkbox id={rbId} name="sampleCheckoutCategories" value={item}
                        defaultChecked={defChecked} disabled={cbDisabled}
                        className="sampleCheckoutCategories text-blue-500 focus:ring-blue-500 cursor-pointer" />
                    <div className="table-cell">
                        <Label htmlFor={rbId} className="pr-1 text-[15px] align-bottom">{item}</Label>
                    </div>

                </div>
            );
        });

        setCategories(list);
    }

    const handleChange = (e) => {

        var data = handleFieldChange(e);
        if (data) {

            if (data.name === "phone") {
                data.value = formatToPhone(e);
            }

            setSampleData((sampleData) => ({
                ...sampleData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
            console.error("handleChange() setSampleData() error");
        }
    };

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(logMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function validateData(categoriesStr) {
        var message = "";
        var valid = true;
        var phoneOrEmail = false;

        if (IsEmpty(sampleData.customerName)) {
            valid = false;
            message += "Customer Name is required.  ";
        }

        if (NotEmpty(sampleData.email)) {
            if (IsEmail(sampleData.email)) {
                phoneOrEmail = true;
            }
            else {
                message += "Email must be valid.  ";
                valid = false;
            }
        }

        if (NotEmpty(sampleData.phone)) {
            phoneOrEmail = true;
        }

        if (IsFalse(phoneOrEmail)) {
            valid = false;
            message += "Phone or Email is required.  ";
        }

        if (IsEmpty(categoriesStr)) {
            valid = false;
            message += "Categories are required.  ";
        }

        if (IsFalse(valid)) {
            updateMessage(message, "text-red");
        }
        return valid;
    }

    function hideValidationMessages() {
        updateMessage("", "");
    }

    function btnSubmitClick(e) {
        handleSubmit(e);
    };

    const handleSubmit = async (e) => {
        PreventDefault(e);
        hideValidationMessages();

        var listCategories = selecTedCategories;
        if (NotStringEmpty(otherState)) {
            listCategories.push(otherState)
        }

        var categoriesStr = listCategories.join(", ");
        var valid = validateData(categoriesStr);
        if (valid === true) {
            try {
                var response = await SaveSampleCheckoutPost(categoriesStr);
                var rId = ParseIntSafe(response);
                if (IdExists(rId)) {
                    //redirect to search
                    sampleData.sampleCheckoutId = rId;
                    updateMessage(createMsg, "text-green");
                    backToClick(null, true);
                }
                else {
                    console.error(JSON.stringify(response));
                    alert(defaultErrMsg);
                }

            } catch (error) {
                updateMessage(defaultErrMsg, "text-red", error);
            }
        }
    }

    async function SaveSampleCheckoutPost(categoriesStr) {
        var response = null;

        if (sampleData) {
            sampleData.categories = categoriesStr;
            sampleData.followUpDate = selectedDate;
            var dateStr = Exists(selectedDate) ? FormatDate(selectedDate) : "";
            sampleData.followUpDateStr = dateStr;
            sampleData.followedUp = checkFolloweUp ?? false;
            sampleData.checkedInDate = null;
            var postModel = JSON.stringify(sampleData);
            var endpoint = "samplecheckout/SaveSampleCheckout";
            var typeStr = "SaveSampleCheckoutPost";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        }
        else {
            response = "No POST data for SaveSampleCheckoutPost().";
        }
        return response;
    }

    async function handleLoadDetails(cUserId) {
        if (location.state && Exists(location.state.sampleCheckoutData) &&
            IdExists(location.state.sampleCheckoutData.sampleCheckoutId))
        {
            createCategories(location.state.sampleCheckoutData);
            setOtherState(location.state.sampleCheckoutData.otherCategory);
            setSampleData(location.state.sampleCheckoutData);
            setLoading(false);
        }
        else {
            const endpoint = 'samplecheckout/GetSampleCheckoutModel/' + cUserId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));
                createCategories(response);
                setSampleData(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(true);
            }
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    // ON LOAD
    useEffect(() => {
        var cUserId = setUserFields();

        createStoreList();
        createUserList();
        handleLoadDetails(cUserId);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="product-page">
            <div className="details-page-container w-[1000px] m-auto">
                <div className="">
                    {/*** Form ***/}
                    <form className="sample-checkout-form" onSubmit={handleSubmit}>

                        <div className="flex flex-wrap forms-page clear">
                            <div class="form-break">
                                <hr class="form-break-line" />
                                <span class="form-break-title">
                                    Sample Checkout
                                </span>
                            </div>

                            {/*** Form Fields ***/}
                            <div className="form-col-1 w-full">
                                <div className="table-cell w-[60%]">
                                    <div className={'grid gap-2'}>

                                        <div className="details-form-field pb-2 ">
                                            <label htmlFor="customerName" className="lbl-text1 w-[140px]">
                                                *Customer Name:
                                            </label>
                                            <input type="text" className="simple-input "
                                                id="customerName" name="customerName"
                                                required autoFocus
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={sampleData.customerName}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            />
                                        </div>

                                        <div className="details-form-field pb-2">
                                            <label htmlFor="email" className="lbl-text1 w-[140px]">*Email:</label>
                                            <input type="email" className="simple-input"
                                                id="email" name="email"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={sampleData.email}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            />
                                        </div>

                                        <div className="details-form-field pb-2">
                                            <label htmlFor="phone" className="lbl-text1 w-[140px]">Phone:</label>
                                            <input type="text" className="simple-input frmPhoneInput"
                                                id="phone" name="phone"
                                                onKeyDown={e => enforcePhoneFormat(e)}
                                                value={sampleData.phone}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            />
                                        </div>

                                        <div className={'details-form-field pb-2'}>
                                            <label className="lbl-text1 w-[140px]">Account Name:</label>
                                            <input type="text" className="simple-input frmPhoneInput"
                                                id="accountName" name="account"
                                                value={sampleData.accountName}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            />
                                        </div>

                                        <div className={'flex gap-5'}>
                                            <div className={'flex'}>
                                                <label htmlFor='productTypes' className="lbl-text1 w-[235px] block">
                                                    *Product Types:
                                                </label>
                                                <div className={'grid grid-cols-3'} style={{ rowGap: '15px' }}>
                                                    {categoriesList.map(item => {
                                                        const cbDisabled = IdExists(sampleData.sampleCheckoutId);
                                                        const defChecked = sampleData.categoriesList.find((el) => el === item.toLowerCase()) ?? false;
                                                        const rbId = "rbSC-" + item;
                                                        return (
                                                            <div>
                                                                <Checkbox id={rbId} onChange={handleSelectCategories}
                                                                    name="sampleCheckoutCategories" value={item}
                                                                    defaultChecked={defChecked} disabled={cbDisabled}
                                                                    className="text-blue-500 mr-1 focus:ring-blue-500 cursor-pointer" />
                                                                <Label htmlFor={rbId}
                                                                    className="pr-1 text-[15px] align-bottom">{item}</Label>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="details-form-field pb-2" style={{ gridColumnStart: '2', gridColumnEnd: '3' }}>
                                                        <Label htmlFor="other" className="lbl-text1 !text-[15px] font-bold">
                                                            Other:
                                                        </Label>
                                                        <input type="text"
                                                            className={"simple-input frmPhoneInput " +
                                                                (IdExists(sampleData.sampleCheckoutId) ? "!text-black" : "")}
                                                            id="other" name="other"
                                                            value={otherState}
                                                            onChange={e => setOtherState(e.target.value)}
                                                            disabled={IdExists(sampleData.sampleCheckoutId)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-cell w-[40%]">
                                    <div className={'grid gap-2'}>

                                        <div className="details-form-field pb-2">
                                            <label htmlFor="enteredDateStr" className="lbl-text1 w-[140px]">
                                                Checkout Date:
                                            </label>
                                            <span className='bg-gray-100 p-1 rounded pr-10 font-bold'>
                                                {sampleData.enteredDateStr}
                                            </span>
                                        </div>

                                        <div className={'details-form-field'}>
                                            <label htmlFor="email" className="lbl-text1 w-[140px]">Follow Up Date:</label>
                                            <DatePicker
                                                id="resellerPermitExpDate"
                                                name="resellerPermitExpDate"
                                                className="inp-date"
                                                minDate={new Date()}
                                                showIcon
                                                selected={selectedDate}
                                                onChange={(date) => setSelectedDate(date)}
                                                toggleCalendarOnIconClick
                                            // dateFormat="dd/mm/yyyy"
                                            // selected={resellerPermitExpDate}
                                            // onKeyDown={(e) =>
                                            //     KeyHandlerDatePicker(
                                            //         e,
                                            //         handleSubmitEdit,
                                            //         setTodaysDate,
                                            //         null
                                            //     )
                                            // }
                                            //onCalendarClose={(e) => handleCalendarClose(e)}
                                            //onCalendarOpen={(e) => handleCalendarOpen(e)}
                                            // onChange={(date) => setResellerPermitExpDate(date)}
                                            />
                                        </div>

                                        <div className={'details-form-field pb-2'}>
                                            <label className="lbl-text1 w-[140px]">Followed Up?</label>
                                            <Checkbox id="Followed Up" name="followedUp"
                                                className="text-blue-500 mr-1 focus:ring-blue-500 cursor-pointer"
                                                value={sampleData.followedUp}
                                                defaultChecked={sampleData.followedUp}
                                                onChange={e => setCheckFolloweUp(e.target.checked)}
                                                disabled={IdExists(sampleData.sampleCheckoutId)} />
                                        </div>

                                        <div className="details-form-field">
                                            <label htmlFor="storeId" className="lbl-text1 w-[140px]">Store:</label>
                                            <select id="storeId" name="storeId"
                                                className="sp-sel1 pb-1 bg-none border-gray-300 rounded h-7 px-2 input-bordered"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={sampleData.storeId}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            >
                                                {storeList}
                                            </select>
                                        </div>

                                        <div className="details-form-field">
                                            <label htmlFor="salespersonId" className="lbl-text1 w-[140px] ml-[-7px]">
                                                Salesperson:
                                            </label>
                                            <select id="salespersonId" name="salespersonId"
                                                className="sp-sel1 pb-1 bg-none border-gray-300 rounded h-7 px-2 input-bordered"
                                                onKeyDown={(e) => EnterSave(e)}
                                                value={sampleData.salespersonId}
                                                onChange={handleChange}
                                                disabled={IdExists(sampleData.sampleCheckoutId)}
                                            >
                                                {userList}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mb-4 mt-4">
                                <label for="notes" class="lbl-text1  !align-top !w-[200px]">Notes:</label>
                                <div className="border-textarea table-cell w-[86%]">
                                    <textarea className="inp-textarea !px-[.5rem] !py-[.25rem]"
                                        id="notes" name="notes" rows="3"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={sampleData.notes}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    ></textarea>
                                </div>
                            </div>

                            <div className="w-full text-right">
                                <div className={"inline-block mr-1 p-1 " + messageClass}>
                                    {message}
                                </div>

                                <button onClick={e => backToClick(e)} type="button" className="btn-cancel-small"
                                    style={{ height: '1px !important' }}>
                                    Cancel
                                </button>
                                <div className="inline-block">
                                    {sampleData.sampleCheckoutId === 0 &&
                                        <button id="btnSampleCheckout" type="submit" className="btn-submit !mr-0"
                                            onClick={(e) => btnSubmitClick(e, false)} //disabled={submitDisabled}                                        
                                        >
                                            Checkout
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}