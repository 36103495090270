import React, { useState, useEffect } from 'react';

export const CashContent = ({
    paymentAmount,
    balanceDue,
    cashTendered,
    handleCashTenderedChange,
    changeDue
}) => {
    const [balance, setBalance] = useState('0.00');
    const [change, setChange] = useState('0.00');

    useEffect(() => {
        if (!cashTendered) {
            handleCashTenderedChange({ target: { value: balanceDue } });
        }
    }, [balanceDue, cashTendered, handleCashTenderedChange, paymentAmount]);

    useEffect(() => {
        // Remaining balance
        const payment = parseFloat(paymentAmount) || 0;
        const remaining = balanceDue - payment;
        setBalance(Math.max(remaining, 0).toFixed(2));

        // Change due
        const tendered = parseFloat(cashTendered) || 0;
        const changeAmount = tendered - payment;
        setChange((changeAmount > 0 ? changeAmount : 0).toFixed(2));
    }, [paymentAmount, cashTendered, balanceDue]);

    return (
        <div>
            <div className="grid grid-cols-2 gap-3">
                <div className="relative">
                    <label className="label">
                        <span className="label-text font-semibold">Cash Tendered:</span>
                    </label>
                    <span className="absolute left-0 top-9 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-4 h-8">
                        $
                    </span>
                    <input
                        type="number"
                        step="0.01"
                        value={cashTendered}
                        onChange={handleCashTenderedChange}
                        className="w-full payment-input !pl-6 mr-4"
                        placeholder="0.00"
                    />
                </div>
                {parseFloat(cashTendered) > parseFloat(balanceDue) && (
                    <div className="relative">
                        <label className="label">
                            <span className="label-text font-semibold">Change Due:</span>
                        </label>
                        <span className="absolute left-0 top-9 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-4 h-8">
                            $
                        </span>
                        <input
                            type="text"
                            value={changeDue}
                            className="w-full payment-input !pl-6"
                            placeholder="0.00"
                            readOnly
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
