import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { get, post } from '../../services/apiService';
import { SetPageTitle } from '../../js/helpers.js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export const CardMachines = () => {
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [searchText, setSearchText] = useState('');
    const [stores, setStores] = useState([]);

    const [editingRows, setEditingRows] = useState(new Set());

    const gridRef = useRef();

    const createColumnDefs = (stores) => {
        const customComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
            const inactiveA = nodeA.data.inactive;
            const inactiveB = nodeB.data.inactive;

            if (inactiveA !== inactiveB) {
                return inactiveA ? 1 : -1;
            }

            if (valueA === valueB) return 0;
            return valueA > valueB ? 1 : -1;
        };

        return [
            {
                headerName: 'Name',
                field: 'name',
                editable: true,
                sortable: true,
                filter: true,
                flex: 2,
                minWidth: 150,
                comparator: customComparator
            },
            {
                headerName: 'Description',
                field: 'description',
                editable: true,
                sortable: true,
                filter: true,
                flex: 2,
                minWidth: 150,
                comparator: customComparator
            },
            {
                headerName: 'Serial Number',
                field: 'serialNumber',
                editable: true,
                sortable: true,
                flex: 1.5,
                minWidth: 120,
                comparator: customComparator
            },
            {
                headerName: 'GMID',
                field: 'gmid',
                editable: true,
                sortable: true,
                flex: 2,
                minWidth: 100,
                comparator: customComparator
            },
            {
                headerName: 'GTID',
                field: 'gtid',
                editable: true,
                sortable: true,
                flex: 2,
                minWidth: 100,
                comparator: customComparator
            },
            {
                headerName: 'GMPW',
                field: 'gmpw',
                editable: true,
                sortable: true,
                flex: 2,
                minWidth: 100,
                comparator: customComparator
            },
            {
                headerName: 'Store',
                field: 'storeId',
                editable: true,
                sortable: true,
                flex: 1.5,
                minWidth: 120,
                comparator: customComparator,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: stores.map(store => store.storeId),
                    formatValue: (value) => {
                        const store = stores.find(s => s.storeId === value);
                        return store ? store.displayName : '';
                    }
                },
                valueFormatter: (params) => {
                    const store = stores.find(s => s.storeId === params.value);
                    return store ? store.displayName : '';
                }
            },
            {
                headerName: 'Default',
                field: 'isDefault',
                editable: true,
                sortable: true,
                flex: 1,
                minWidth: 80,
                comparator: customComparator,
                cellRenderer: params => (
                    <input
                        type="checkbox"
                        checked={params.value}
                        onChange={() => handleDefaultChange(params)}
                        className="border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                    />
                )
            },
            {
                headerName: 'Active',
                field: 'inactive',
                editable: true,
                sortable: true,
                flex: 1,
                minWidth: 80,
                cellRenderer: params => (
                    <input
                        type="checkbox"
                        checked={!params.value}
                        onChange={() => handleActiveChange(params)}
                        className="border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                    />
                )
            },
            {
                headerName: 'Delete',
                field: '',                
                cellRenderer: params => {
                    if (params.data.cardMachineId !== 0) {
                        return (
                            <button
                                onClick={() => handleDeleteMachine(params.data)}
                                className="btn-grid-red"
                                title="Delete card machine"
                            >
                                Delete
                            </button>
                        );
                    }
                    else if (params.data.cardMachineId === 0) {
                        return (
                            <div>
                                <button
                                    onClick={() => handleSaveNewMachine(params)}
                                    className="btn-grid table-cell mr-2">Save</button>
                                <button
                                    onClick={() => handleCancelEdit(params)}
                                    className="btn-grid-red table-cell">Cancel</button>
                            </div>
                        );
                    }
                    return null;
                }
            },
            //{
            //    headerName: '',
            //    field: 'actions',
            //    flex: 1,
            //    minWidth: 140,
            //    cellRenderer: params => {
            //        if (params.data.cardMachineId === 0) {
            //            return (
            //                <div className="flex gap-2">
            //                    <button
            //                        onClick={() => handleSaveNewMachine(params)}
            //                        className="btn-grid"
            //                    >
            //                        Save
            //                    </button>
            //                    <button
            //                        onClick={() => handleCancelEdit(params)}
            //                        className="btn-grid-red"
            //                    >
            //                        Cancel
            //                    </button>
            //                </div>
            //            );
            //        }
            //        return null;
            //    }
            //}
        ];
    }

    const [columnDefs, setColumnDefs] = useState(createColumnDefs([]));

    const defaultColDef = {
        resizable: true,
        suppressMovable: true,
    };

    const loadData = async () => {
        SetPageTitle("Credit Card Machines");
        setMessage('');
        setError('');

        try {
            const [machinesResponse, storesResponse] = await Promise.all([
                get('/cardmachines/GetAll'),
                get('/store/GetAllStores')
            ]);            
            setStores(storesResponse ?? []);

            if (gridRef.current.api) {
                gridRef.current.api.setRowData(machinesResponse);
                gridRef.current.api.refreshCells({ force: true });
            }
        } catch (err) {
            setMessage('');
            setError('Failed to load data');
            console.error('Error loading data:', err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (stores.length > 0) {
            setColumnDefs(createColumnDefs(stores));
            if (gridRef.current?.api) {
                gridRef.current.api.refreshCells({ force: true });
            }
        }
    }, [stores]);

    const onCellValueChanged = async (params) => {
        const isNewRow = params.data.cardMachineId === 0;
        if (isNewRow) {
            setEditingRows(prev => new Set(prev).add(params.data.cardMachineId));
        } else {
            try {
                await post('/cardmachines/Update', params.data);
                await loadData();
                setError('');
                setMessage('Updated successfully');
            } catch (err) {
                setMessage('');
                setError('Update failed');
                console.error('Error:', err);
                loadData();
            }
        }
    };

    const handleRowFocused = async (params) => {
        if (!params.previousNode) return; 

        const previousRowId = params.previousNode.data.cardMachineId;
        if (editingRows.has(previousRowId)) {
            try {
                const rowData = params.previousNode.data;
                const requiredFields = ['name', 'serialNumber', 'gmid', 'gtid', 'gmpw'];
                const isComplete = requiredFields.every(field => rowData[field]);

                if (isComplete) {
                    await post('/cardmachines/Create', rowData);
                    setEditingRows(prev => {
                        const newSet = new Set(prev);
                        newSet.delete(previousRowId);
                        return newSet;
                    });
                    await loadData();
                    setError('');
                    setMessage('Machine added successfully');
                }
            } catch (err) {
                setMessage('');
                setError('Creation failed');
                console.error('Error:', err);
                loadData();
            }
        }
    };

    const handleCancelEdit = (params) => {
        if (params.data.cardMachineId === 0) {
            const currentRows = gridRef.current.api.getModel().rowsToDisplay
                .filter(row => row.data.cardMachineId !== 0)
                .map(row => row.data);
            gridRef.current.api.setRowData(currentRows);
            setEditingRows(prev => {
                const newSet = new Set(prev);
                newSet.delete(0);
                return newSet;
            });
            loadData();
            setMessage('');
            setError('');
        } else {
            loadData();
        }
    };

    const handleSaveNewMachine = async (params) => {
        try {
            const rowData = params.data;
            const requiredFields = ['name', 'serialNumber', 'gmid', 'gtid', 'gmpw'];
            const isComplete = requiredFields.every(field => rowData[field]);

            if (!isComplete) {
                setMessage('');
                setError('Please fill in all required fields');
                return;
            }

            await post('/cardmachines/Create', rowData);
            setEditingRows(prev => {
                const newSet = new Set(prev);
                newSet.delete(rowData.cardMachineId);
                return newSet;
            });
            await loadData();
            setError('');
            setMessage('Created successfully');
        } catch (err) {
            setError('Creation failed');
            setMessage('');
            console.error('Error:', err);
        }
    };

    const handleAddMachine = () => {
        const existingNewRow = gridRef.current.api.getModel().rowsToDisplay
            .find(row => row.data.cardMachineId === 0);

        if (existingNewRow) {
            setError('Please save or complete the current new entry first');
            setMessage('');
            return;
        }

        const newMachine = {
            cardMachineId: 0,
            name: '',
            description: '',
            serialNumber: '',
            gmid: '1110103015',     // TODO: Remove after testing sandbox  
            gtid: 'GT1120044571',   // TODO: Remove after testing sandbox
            gmpw: 'GMPW3010022469', // TODO: Remove after testing sandbox
            storeId: null,
            isDefault: false,
            inactive: false
        };

        const currentRows = gridRef.current.api.getModel().rowsToDisplay;
        const updatedRows = [newMachine, ...currentRows.map(row => row.data)];
        gridRef.current.api.setRowData(updatedRows);

        setTimeout(() => {
            const rowNode = gridRef.current.api.getRowNode(0);
            if (rowNode) {
                gridRef.current.api.startEditingCell({
                    rowIndex: 0,
                    colKey: 'name'
                });
            }
        }, 100);
    };

    const handleActiveChange = async (params) => {
        const updatedValue = !params.value;
        params.node.setDataValue('inactive', updatedValue);

        if (params.data.cardMachineId > 0) {
            try {
                const updatedMachine = {
                    ...params.data,
                    inactive: updatedValue
                };
                await post('/cardmachines/Update', updatedMachine);
                await loadData();
                setError('');
                setMessage('Updated successfully');
            } catch (err) {
                setError('Update failed');
                setMessage('');
                console.error('Error:', err);
                loadData();
            }
        }
    };

    const handleDefaultChange = async (params) => {
        const updatedValue = !params.value;
        params.node.setDataValue('isDefault', updatedValue);

        if (params.data.cardMachineId > 0) {
            try {
                const updatedMachine = {
                    ...params.data,
                    isDefault: updatedValue
                };
                await post('/cardmachines/Update', updatedMachine);
                await loadData();
                setMessage('Updated successfully');
                setError('');
            } catch (err) {
                setMessage('');
                setError('Failed to update default status');
                console.error('Update error:', err);
                loadData();
            }
        }
    };

    const handleDeleteMachine = async (machine) => {
        if (!machine || !machine.cardMachineId) return;

        if (window.confirm(`Are you sure you want to delete this card machine: ${machine.name}?`)) {
            try {
                const response = await get(`/cardmachines/delete/${machine.cardMachineId}`);
                console.log('Delte:', response);
                loadData(); 
            } catch (err) {
                console.error('Error deleting card machine:', err);
                setError('Failed to delete card machine');
            }
        }
    };

    const getRowClass = (params) => {
        return params.data.inactive ? 'inactive-row' : '';
    };

    const postSort = (params) => {
        const nodes = params.nodes;
        nodes.sort((a, b) => {
            if (a.data.inactive !== b.data.inactive) {
                return a.data.inactive ? 1 : -1; // Active first
            }
            return 0; // Existing sort
        });
    };

    const onQuickFilterChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(searchText);
    }, [searchText]);

    return (
        <div className="p-4">
            <div className="mb-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                        <input
                            type="text"
                            placeholder="Quick search..."
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            onInput={onQuickFilterChanged}
                            className="input input-bordered w-64"
                        />
                    </div>

                    <div className="flex items-center gap-4">
                        {error && <div className="text-red-500">{error}</div>}
                        {message && <div className="text-green-500">{message}</div>}
                        <button
                            onClick={handleAddMachine}
                            className="btn btn-submit btn-sm"
                        >
                            Add Card Machine
                        </button>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine ag-grid-act h-[600px] w-full">
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressRowClickSelection={true}
                    stopEditingWhenCellsLoseFocus={true}
                    onCellValueChanged={onCellValueChanged}
                    defaultSortModel={[
                        { colId: 'inactive', sort: 'asc' }
                    ]}
                    getRowClass={getRowClass}
                    postSort={postSort}
                    onRowFocused={handleRowFocused}
                />
            </div>
        </div>
    );
};