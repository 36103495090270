import React, { Component } from 'react';
import { initFlowbite } from "flowbite";

import { AccountWrapper } from './AccountWrapper';

//import { AccountSalesNotes } from './AccountSalesNotes';
//import { AccountCreditNotes } from './AccountCreditNotes';
//import { AccountOrders } from './AccountOrders';
//import { AccountQuotes } from './AccountQuotes';
//import { AccountAddEdit } from './AccountAddEdit';

//import './_accountCustom.css';

//import { GetLocationStateId } from "../_reactHelpers/ReactHelpers";
//import { ClickById } from '../../js/helpers.js';
//import { SwitchFbTab } from '../../js/formHelpers';

export class AccountParent extends Component {
    static displayName = AccountParent.name;    

    componentDidMount() {
        initFlowbite();        
    }

    render() {
        return (
            <div>
                <AccountWrapper></AccountWrapper>
            </div>
        );
    }
}