import React, {useEffect, useState} from "react";

import {get, post} from "../../../services/apiService";
import {MdAttachEmail, MdOutlineFileDownload} from "react-icons/md";
import {AiFillPrinter} from "react-icons/ai";
import { Base64ToArrayBuffer, Exists, IdExists, NotEmpty, NotStringEmpty } from "../../../js/formHelpers"; 

export default function CheckRunDetails({ printCheckId }) {
    const [checkRunDetails, setCheckRunDetails] = useState(null);
    const [numberRemittanceAdvice, setNumberRemittanceAdvice] = useState(0);
    const [numberRemittanceAdviceEmail, setNumberRemittanceAdviceEmail] = useState(0);
    const [loading, setLoading] = useState(true);

    async function getCheckRunDetails() {
        try {
            if (NotStringEmpty(printCheckId)) {
                const response = await get(`checkpayment/GetPrintCheckById/${printCheckId}`);
                setCheckRunDetails(response);

                const countPrint = response.filter(item => item.printRemittance);
                const countEmail = response.filter(item => item.emailRemittance);

                setNumberRemittanceAdvice(countPrint.length);
                setNumberRemittanceAdviceEmail(countEmail.length);
            }
            else {
                setNumberRemittanceAdvice(0);
                setNumberRemittanceAdviceEmail(0);
            }
        } catch (error) {
            console.error("Error fetching check run details:", error);
        } finally {
            setLoading(false);
        }
    }

    async function sendEmailTest() {
        const response = await get(`checkpayment/SendRemittanceAdviceByEmail/${printCheckId}`);

        if (NotStringEmpty(response?.fileName)) {
            const url = Base64ToArrayBuffer(response.fileBytes, "application/zip", response.contentLength);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.fileName + `.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    async function getCsvFile() {
        const response = await get(`checkpayment/GetCsvFileByPrintCheckId/${printCheckId}`);
        const url = Base64ToArrayBuffer(response.fileBytes, "application/xlsx", response.contentLength);
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.fileName + `.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function printRemittanceAdvice() {
        const response = await get(`checkpayment/PrintRemittanceAdvice/${printCheckId}`);
        const url = Base64ToArrayBuffer(response.fileBytes, "application/zip", response.contentLength);                        

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.fileName + `.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function printCheckStubs() {
        const checkPaymentIds = checkRunDetails.map(item => item.checkPaymentId);
        const response = await post('checkpayment/PrintPdfCheckStubs', checkPaymentIds);
        const url = Base64ToArrayBuffer(response.fileBytes, "application/zip", response.contentLength);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.fileName + `.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        getCheckRunDetails();
    }, []);

    if (loading) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    //console.log(checkRunDetails)
    return (
        <>
            {checkRunDetails ? (
                <div className="w-[650px]">
                    <div className="flex">
                        <div className="flex flex-col gap-2 px-4 w-[55%]">
                            <div className="flex items-center justify-between">
                                <dt className="text-sm font-bold text-gray-500">Number Print Checks</dt>
                                <dd className="text-base font-semibold">{checkRunDetails.length}</dd>
                            </div>
                            <div className="flex items-center justify-between">
                                <dt className="text-sm font-bold text-gray-500">Number Remittance Advice</dt>
                                <dd className="text-base font-semibold">{numberRemittanceAdvice}</dd>
                            </div>
                            <div className="flex items-center justify-between">
                                <dt className="text-sm font-bold text-gray-500">Number Remittance Advices to Email</dt>
                                <dd className="text-base font-semibold">{numberRemittanceAdviceEmail}</dd>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 px-4">
                            <button
                                className="flex gap-3 items-center justify-center px-3 py-0 text-white text-sm font-medium rounded-md w-4/5"
                                style={{ background: '#045ca4' }}
                                onClick={() => printCheckStubs()}>
                                <AiFillPrinter />
                                Send to Printer
                            </button>

                            <button
                                className="flex gap-3 items-center justify-center px-3 py-0 text-white text-sm font-medium rounded-md w-4/5"
                                style={{ background: '#045ca4' }}
                                onClick={() => printRemittanceAdvice()}>
                                <AiFillPrinter />
                                Send Remittance to Printer
                            </button>

                            <button
                                className="flex gap-3 items-center justify-center px-3 py-0 text-white text-sm font-medium rounded-md w-4/5"
                                style={{ background: '#045ca4' }}
                                onClick={() => sendEmailTest()}>
                                <MdAttachEmail />
                                Email Electronic Remittance
                            </button>

                            <button
                                className="flex gap-3 items-center justify-center px-3 py-0 text-white text-sm font-medium rounded-md w-4/5"
                                style={{ background: '#045ca4' }}
                                onClick={() => getCsvFile()}>
                                <MdOutlineFileDownload />
                                Download Positive Pay Report
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center text-xl mt-[10%]">
                    <span className="pr-1">No data available</span>
                </div>
            )}
        </>
    );
}