import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Modal, Button } from 'react-daisyui';

import {
    IsEmail, IsPhone, formatToPhone, enforcePhoneFormat, NotEmpty, IsTrue,
    IsEmpty, IsFalse, Exists, IdExists, ParseIntSafe, PreventDefault, defaultErrMsg,
    NotExists,
    NotStringEmpty,
    CheckEnterKeyPress,
    StringEmpty
} from '../../../js/formHelpers.js';
import {
    GetStoreSelectList, GetUserSelectList, IsSuccessStandardResponseAndDataPOST,
} from "../../../js/dataHelpers";
import { SaveOrderPost } from '../_js/_orderDataHelpers.js';

import { ClickById } from "../../../js/helpers";
import { Equals, GreaterThan, GreaterThanEq, NotEquals } from '../../../js/calculationHelpers.js';
import { AddAccountModal } from './AddAccountModal.js';
import { createAccountModel } from '../_js/_orderHelpers.js';
import { get } from '../../../services/apiService.js';

export const OrderJobModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const orderId = props.orderId ?? 0;
    const orderStatus = props.orderStatus ?? 0;
    const orderType = props.orderType ?? "";
    const orderTypeStr = props.orderTypeStr ?? "";
    const billingGroupId = props.billingGroupId ?? 0;
    const jobAddressStoreId = 777;

    const [orderData, setOrderData] = useState(null);
    //console.log({ orderData });

    const [idStore, setIdStore] = useState("");
    const [jobData, setJobData] = useState({
        jobStoreId: 0,
        jobName: '',        
        jobAddress1: '',
        jobCity: '',
        jobState: '',
        jobZip: '',
        jobNotes: '',
        jobPlacedByName: '',
        jobPlacedByEmail: '',
        jobPlacedByPhone: '',
        jobMaterialNotificationEmails: [],
        jobMaterialNotificationPhones: []
    });

    const [accountData, setAccountData] = useState({
        accountId: 0,
        accountName: '',
        accountAddress1: '',
        accountCity: '',
        accountState: '',
        accountZip: '',
    });

    const [vendorData, setVendorData] = useState({
        vendorId: 0,
        vendorName: '',
        vendorAddress1: '',
        vendorCity: '',
        vendorState: '',
        vendorZip: '',
    });

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openAccountModal, setOpenAccountModal] = useState(false);

    const [materialEmailInput, setMaterialEmailInput] = useState('');
    const [materialPhoneInput, setMaterialPhoneInput] = useState('');
    const [materialEmailsToShow, setMaterialEmailsToShow] = useState([]);
    const [materialPhonesToShow, setMaterialPhonesToShow] = useState([]);

    const [userList, setUserList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [taxStores, setTaxStores] = useState([]);

    const [message, setMessage] = useState("");

    async function createUserList() {
        let list = [];
        //list.push(
        //    <option key="0" value="0">None</option>
        //);

        const dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    async function createStoreList() {
        var list = [];
        var taxList = [];
        taxList.push({ value: jobAddressStoreId, text: 'Job Address' });

        var dataList = await GetStoreSelectList(1);        
        //console.log({ dataList })

        dataList.forEach((item, index) => {
            taxList.push({ value: item.value, text: item.text});
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setTaxStores(taxList);
        setStoreList(list);
    }

    useEffect(() => {
        if (props.jobData) {
            setJobData(prevData => ({
                ...prevData,
                ...props.jobData,
            }));
        }

        let matEmails = props.jobData?.jobMaterialNotificationEmails ?? [];
        matEmails = matEmails.filter(e => e !== '');
        setMaterialEmailsToShow(matEmails);

        let matPhones = props.jobData?.jobMaterialNotificationPhones ?? [];
        matPhones = matPhones.filter(e => e !== '');
        setMaterialPhonesToShow(matPhones);
    }, []);

    useEffect(() => {
        if (props.orderData) {
            setOrderData(prevData => ({
                ...prevData,
                ...props.orderData,
                followUpDate: props.orderData.followUpDate ?
                    new Date(props.orderData.followUpDate).toISOString().split('T')[0] :
                    new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0]
            }));
        }
    }, []);


    useEffect(() => {
        if (props.accountData) {
            setAccountData(prevData => ({
                ...prevData,
                ...props.accountData,
                accountAddress1: props.accountData.address1,
                accountCity: props.accountData.city,
                accountState: props.accountData.state,
                accountZip: props.accountData.zip
            }));
        }
    }, [props.accountData, orderData?.orderId])

    useEffect(() => {
        if (props.vendorData) {
            setVendorData(prevData => ({
                ...prevData,
                ...props.vendorData,
                vendorId: props.vendorData.vendorId,
                vendorName: props.vendorData.name,
                vendorAddress1: props.vendorData.address1,
                vendorCity: props.vendorData.city,
                vendorState: props.vendorData.state,
                vendorZip: props.vendorData.zip
            }));
        }
    }, [props.vendorData])

    function loadModalContent() {
        createUserList();
        createStoreList();

        // create Order
        if (!IdExists(orderId)) {
            if (isInventory()) {
                saveAndCloseModal();
            }
            else if (isClaim()) {
                ClickById("openAddVendorModal");
            }
            else {
                if (!IdExists(props?.orderData?.accountId)) {
                    // open Account search
                    setOpenAccountModal(true);
                }
                else {
                    // get Account data from passed in accountId
                    getAccountModel(props?.orderData?.accountId, props.orderData);
                }
            }
        }
        // edit Order Info
        else {
            setOpenInfoModal(true);
        }
    }

    useEffect(() => {
        loadModalContent();
    }, []);

    useLayoutEffect(() => {
        setTimeout(() => document.getElementsByName("jobName")[0]?.focus(), 0);
    }, []);

    const handleAddEmail = (email) => {
        var isOpb = true;
        if (NotExists(email)) {
            isOpb = false;
            email = materialEmailInput;
        }

        if (IsEmail(email)) {
            if (!materialEmailsToShow.includes(email)) {
                setMaterialEmailsToShow(prevData => ([
                    ...prevData,
                    email
                ]));
                setMaterialEmailInput('');
                setMessage("");
            }
            else {
                if (IsFalse(isOpb)) {
                    setMessage("This email is already in the list.");
                }
            }
        }
        else {
            if (IsTrue(isOpb)) {
                setMessage("OPB email must be valid.");
            }
            else {
                setMessage("Please enter a valid email address.");
            }
        }
    };

    const handleAddPhone = () => {
        if (IsPhone(materialPhoneInput)) {
            if (!materialPhonesToShow.includes(materialPhoneInput)) {
                setMaterialPhonesToShow(prevData => ([
                    ...prevData,
                    materialPhoneInput
                ]));
                setMaterialPhoneInput('');
                setMessage("");
            }
            else {
                setMessage("This phone number is already in the list.");
            }
        }
        else {
            setMessage("Please enter a valid phone number.");
        }
    };

    const handleRemoveEmail = (email) => {
        const newEmailsToShow = materialEmailsToShow.filter(e => e !== email);
        setMaterialEmailsToShow(newEmailsToShow);
    };

    const handleRemovePhone = (phone) => {
        const newPhonesToShow = materialPhonesToShow.filter(p => p !== phone);
        setMaterialPhonesToShow(newPhonesToShow);
    };

    const handleChangeJobData = (e) => {
        const { name, value } = e.target;
        setJobData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleChangeOrderData = (e) => {
        const { name, value } = e.target;

        if (name === 'taxTypeId') {
            if (value === '1') {
                setOrderData(prevData => ({ ...prevData, "taxLocationStoreId": jobData.jobStoreId }));
            }
            else if (value === '0' || value === '2' || value === '3') {
                setOrderData(prevData => ({ ...prevData, "taxLocationStoreId": 0 }));
            }
        }
        setOrderData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleCheckInactive = (e) => {
        const { name, checked } = e.target;
        setOrderData(prevData => ({ ...prevData, [name]: checked }));
    }

    function validateData() {
        let valid = true;

        if (IsFalse(isInventory())) {

            // Order,Claim
            if (accountData.accountId === 0) {
                valid = false;

                if (IsTrue(isClaim())) {
                    setMessage("Claim For Account is required.");
                }
                else {
                    setMessage("Account is required.");
                }
            }

            // Order
            if (IsFalse(isClaim())) {
                if (NotEmpty(jobData.jobPlacedByPhone) && !IsPhone(jobData.jobPlacedByPhone)) {
                    setMessage("OPB phone must be valid.");
                    valid = false;
                }
                if (NotEmpty(jobData.jobPlacedByEmail) && !IsEmail(jobData.jobPlacedByEmail)) {
                    setMessage("OPB email must be valid.");
                    valid = false;
                }
                if (IsEmpty(jobData.jobPlacedByName)) {
                    valid = false
                    setMessage("OPB Name is required.");
                }
                if (Number(orderData.taxTypeId) === 3 && IsFalse(accountData.isValidResellerPermitAndTaxNumber)) {
                    valid = false;
                    setOrderData(prevData => ({ ...prevData, "taxTypeId": 1, "taxLocationStoreId": Number(orderData.storeId) }));
                    setMessage("Tax Status cannot be set to Resale for this Account.")
                }
                if (Number(orderData.taxTypeId) === 0) {
                    valid = false;
                    setMessage("Tax Status is required.");
                }
                if (Number(orderData.taxTypeId) === 1 && Number(orderData.taxLocationStoreId) === 0) {
                    valid = false;
                    setMessage("Tax Location is required for 'Tax Payable'.");
                }
                if (Number(orderData.taxLocationStoreId) === jobAddressStoreId && StringEmpty(jobData.jobZip)) {
                    valid = false;
                    setMessage("Zip is required for 'Job Address' Tax Location.");
                }
                if (IsEmpty(jobData.jobName)) {
                    valid = false;
                    setMessage("Job Name is required.");
                }
            }
        }
        return valid;
    }

    function isQuote(isQuoteType) {
        let isQuote = false;
        if (Exists(isQuoteType)) {
            isQuote = IsTrue(isQuoteType);
        }
        else if (orderType === 2) {
            isQuote = true;
        }
        else if (orderData?.orderType === 2) {
            isQuote = true;
        }
        else if (orderData) {
            isQuote = IsTrue(orderData.isQuoteType);
        }
        return isQuote;
    }

    function isClaim(isClaimType) {
        let isClaim = false;
        if (Exists(isClaimType)) {
            isClaim = IsTrue(isClaimType);
        }
        else if (orderType === 3) {
            isClaim = true;
        }
        else if (orderData?.orderType === 3) {
            isClaim = true;
        }
        else if (orderData) {
            isClaim = IsTrue(orderData.isClaimType);
        }
        return isClaim;
    }

    function isInventory(isInventoryType) {
        let isInventory = false;
        if (Exists(isInventoryType)) {
            isInventory = IsTrue(isInventoryType);
        }
        else if (orderType === 5) {
            isInventory = true;
        }
        else if (orderData?.orderType === 5) {
            isInventory = true;
        }
        else if (orderData) {
            isInventory = IsTrue(orderData.isInventoryType);
        }
        return isInventory;
    }

    // Account Modal --------------------------------------
    async function onCloseAccountModal(e, selectedAccountId) {
        PreventDefault(e);

        if (selectedAccountId) {
            getAccountModel(selectedAccountId, null);
        }
        else {
            setOpenAccountModal(false);
        }
    }

    //function saveOrderAccountId(selectedAccountId, orderDataModel) {
    //    if (selectedAccountId) {
    //        saveAccountAndSetAccountData(selectedAccountId, orderDataModel);
    //    }
    //}

    async function getAccountModel(accountId, orderDataModel) {
        if (IdExists(accountId)) {
            const endpoint = 'orders/GetAccountModelForOrder/' + accountId;
            try {
                const data = await get(endpoint);
                if (IdExists(data.accountId)) {
                    var acctModel = createAccountModel(null, data);
                    setAccountData(acctModel);

                    if (orderId === 0) {
                        // used for create new Order from Account
                        // return OrderModel with Account data
                        var orderModel = orderData;
                        if (!orderModel && orderDataModel) {
                            orderModel = orderDataModel;
                        }

                        orderModel.accountId = acctModel.accountId;
                        orderModel.samplesAccount = acctModel.samplesAccount;
                        // Terms - default to Cash
                        orderModel.termsId = 1;
                        if (IdExists(acctModel.termsId)) {
                            orderModel.termsId = acctModel.termsId;
                        }
                        // BC PriceLevel
                        if (IdExists(acctModel.defaultPriceLevelId)) {
                            orderModel.priceLevelId = acctModel.defaultPriceLevelId;
                        }
                        // fields not needed for claim
                        if (IsFalse(isClaim())) {
                            //set autofill values from Account on new Order
                            if (NotStringEmpty(acctModel.materialNotificationEmail)) {
                                setMaterialEmailsToShow(prevData => ([
                                    ...prevData,
                                    acctModel.materialNotificationEmail
                                ]));
                            }

                            if (NotStringEmpty(acctModel.materialNotificationPhone)) {
                                setMaterialPhonesToShow(prevData => ([
                                    ...prevData,
                                    acctModel.materialNotificationPhone
                                ]));
                            }
                        }

                        if (GreaterThan(Number(acctModel.taxTypeId), 0)) {
                            orderModel.taxTypeId = acctModel.taxTypeId;
                        }
                        else {
                            // default Taxable
                            orderModel.taxTypeId = 1;
                        }

                        if (orderModel.taxTypeId === 1) {
                            orderModel.taxLocationStoreId = Number(orderModel.storeId);
                        }

                        //set page data
                        setOrderData(orderModel);
                        setOpenInfoModal(true);
                    }
                    // close modal
                    setOpenAccountModal(false);
                }
                else {
                    alert(defaultErrMsg());
                }
            } catch (error) {
                console.log('Error: ' + error);
            }
        }
    }

    async function saveJobData() {
        var success = false;
        var response = null;

        jobData.jobMaterialNotificationEmails = materialEmailsToShow ?? [];
        jobData.jobMaterialNotificationPhones = materialPhonesToShow ?? [];

        try {
            const postModel = {
                userId: props?.userId,
                orderId: orderId,
                orderType: orderType,
                storeId: jobData.jobStoreId,
                accountId: accountData.accountId,
                billingGroupId: billingGroupId,
                jobModel: jobData,
                jobNotes: orderData.jobNotes,
                priceLevelId: orderData.priceLevelId,
                placedByName: jobData.jobPlacedByName,
                placedByEmail: jobData.jobPlacedByEmail,
                placedByPhone: jobData.jobPlacedByPhone,
                poNumber: orderData.poNumber,
                termsId: orderData.termsId,
                salesperson1Id: orderData.salesperson1Id,
                salesperson2Id: orderData.salesperson2Id,
                claimAgainstVendorId: vendorData?.vendorId,
                taxTypeId: orderData.taxTypeId,
                inactive: orderData.inactive,
                followUpDate: orderData.followUpDate,
                taxLocationStoreId: orderData.taxLocationStoreId,
                // TODO: dev - remove
                rfmsOrderNumber: orderData.rfmsOrderNumber,
            }

            if (!IdExists(orderId)) {
                if (isClaim()) {
                    //set for POST validation
                    postModel.jobModel.jobName = "Claim";
                }
                if (isInventory()) {
                    //set for POST validation
                    postModel.jobModel.jobName = "Inventory";
                    postModel.salesperson1Id = props?.orderData?.salesperson1Id ?? 0;
                }
            }
            response = await SaveOrderPost(postModel);
            if (IsSuccessStandardResponseAndDataPOST(response)) {
                const iOrderId = ParseIntSafe(response.orderId);
                if (IdExists(iOrderId)) {
                    postModel.orderId = iOrderId
                    success = true;
                    return postModel;
                }
            }
        } catch (error) {
            console.log('Error: ' + error);
        }

        if (success === false) {
            console.error("saveJobData() - error: ", JSON.stringify(response));
        }
        return null;
    }

    async function saveAndCloseModal(e) {
        PreventDefault(e);
        setMessage("");

        let valid = validateData();
        if (valid === true) {
            let postModel = await saveJobData();
            if (IdExists(postModel?.orderId)) {
                onCloseModal(e, postModel);
            }
            else {
                alert(defaultErrMsg());
            }
        }
    }

    return (
        <div>
            {openInfoModal && 
                <div className="modal-wrapper">
                    <div className="modal-content !max-w-[855px]">
                        <div className="w-[800px]">
                            <span className="font-bold text-xl text-secondary underline">{orderTypeStr} Information</span>

                            <div className="grid grid-cols-1 gap-6 mt-3">
                                <div className="w-full">
                                    <div className="flex gap-4 w-full">
                                        <div className="w-1/2">
                                            <div className="flex gap-2 pb-1">
                                                <h2 className="font-bold text-lg text-secondary">
                                                    {IsFalse(isClaim()) ? "Account" : "Claim For"}
                                                </h2>
                                                <button type="button" id="openAddAccountModal"
                                                    onClick={() => setOpenAccountModal(prev => !prev)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor" className="w-5 h-5 font-bold text-[#3c5ca8]">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className="grid grid-cols-1 gap-2">
                                                <input
                                                    readOnly
                                                    type="text"
                                                    name="accountName"
                                                    className="w-full sales-input"
                                                    value={accountData.accountName}
                                                    placeholder="Account Name"
                                                />

                                                <input
                                                    readOnly
                                                    type="text"
                                                    name="accountAddress1"
                                                    className="w-full sales-input"
                                                    value={accountData.accountAddress1}
                                                    placeholder="Account Address"
                                                />

                                                <div className="flex gap-2">
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        name="accountCity"
                                                        className="w-full sales-input"
                                                        value={accountData.accountCity}
                                                        placeholder="City"
                                                    />

                                                    <input
                                                        readOnly
                                                        type="text"
                                                        name="accountState"
                                                        className="w-24 sales-input"
                                                        value={accountData.accountState}
                                                        placeholder="State"
                                                    />

                                                    <input
                                                        readOnly
                                                        type="text"                                                        
                                                        name="accountZip"
                                                        className="w-16 sales-input"
                                                        value={accountData.accountZip}
                                                        placeholder="Zip"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-1/2">
                                            {IsFalse(isClaim()) &&
                                                <div>
                                                    <div className="flex gap-5">
                                                        <div className="w-[100%]">
                                                            <h2 className="font-bold text-lg text-secondary pb-1">Pricing</h2>
                                                            <select className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                                name="priceLevelId"
                                                                disabled={orderStatus === 2}
                                                                value={orderData?.priceLevelId}
                                                                onChange={handleChangeOrderData}>
                                                                <option value={0}>BC</option>
                                                                <option value={1}>BC1 (10%)</option>
                                                                <option value={2}>BC2 (15%)</option>
                                                                <option value={3}>BC3 (20%)</option>
                                                                <option value={5}>BC5 (5%)</option>
                                                            </select>

                                                            <h2 className="font-bold text-lg text-secondary pb-1">Ship to store</h2>
                                                            {IsFalse(isClaim()) &&
                                                                <select
                                                                    id="jobStoreId" name="jobStoreId"
                                                                    className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                                    disabled={orderStatus === 2}
                                                                    value={jobData.jobStoreId}
                                                                    onChange={handleChangeJobData}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {storeList}
                                                                </select>
                                                            }
                                                        </div>

                                                        {IsTrue(isQuote()) &&
                                                            <div className="w-[60%]">
                                                                <h2 className="font-bold text-lg text-secondary pb-1">Follow Up Date</h2>

                                                                <div className="flex gap-5">
                                                                    <input
                                                                        name="followUpDate"
                                                                        className="w-[55%] border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                                        type="date"
                                                                        disabled={orderStatus === 2}
                                                                        value={orderData?.followUpDate}
                                                                        onChange={handleChangeOrderData}
                                                                    />

                                                                    <div className="flex gap-2 items-center">
                                                                        <input
                                                                            name="inactive" type="checkbox"
                                                                            className="border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                                            disabled={orderStatus === 2}
                                                                            checked={orderData?.inactive}
                                                                            onChange={handleCheckInactive}
                                                                        />
                                                                        <p>Inactive</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <input id="rfmsOrderNumber" name="rfmsOrderNumber"
                                                        type="text" className="sales-input mt-[46px]"
                                                        maxLength="20"
                                                        placeholder="RFMS Order #"
                                                        disabled={orderStatus === 2}
                                                        onChange={handleChangeOrderData}
                                                        value={orderData?.rfmsOrderNumber}
                                                    />
                                                </div>
                                            }
                                            {IsTrue(isClaim()) &&
                                                <div>
                                                    <div className="flex gap-2 pb-1">
                                                        <h2 className="font-bold text-lg text-secondary">Claim Against</h2>

                                                        <button type="button" id="openAddVendorModal"
                                                            onClick={() => props.setOpenAddVendorModal(prev => !prev)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor" className="w-5 h-5 font-bold text-[#3c5ca8]">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                            </svg>
                                                        </button>
                                                    </div>

                                                    <div className="grid grid-cols-1 gap-2">
                                                        <input
                                                            readOnly
                                                            type="text"
                                                            name="accountName"
                                                            className="w-full sales-input"
                                                            value={vendorData.vendorName}
                                                            placeholder="Account Name"
                                                        />

                                                        <input
                                                            readOnly
                                                            type="text"
                                                            name="accountAddress1"
                                                            className="w-full sales-input"
                                                            value={vendorData.vendorAddress1}
                                                            placeholder="Account Address"
                                                        />

                                                        <div className="flex gap-2">
                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="accountCity"
                                                                className="w-full sales-input"
                                                                value={vendorData.vendorCity}
                                                                placeholder="City"
                                                            />

                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="accountState"
                                                                className="w-24 sales-input"
                                                                value={vendorData.vendorState}
                                                                placeholder="State"
                                                            />

                                                            <input
                                                                readOnly
                                                                type="text"
                                                                name="accountZip"
                                                                className="w-16 sales-input"
                                                                value={vendorData.vendorZip}
                                                                placeholder="Zip"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full">
                                    <div className="flex gap-4 w-full">
                                        <div className="w-1/2">
                                            <h2 className="font-bold text-lg text-secondary pb-1">Job</h2>

                                            {IsFalse(isClaim()) &&
                                                <div className="grid grid-cols-1 gap-2">
                                                    <input
                                                        type="text"
                                                        name="jobName"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={jobData.jobName}
                                                        onChange={handleChangeJobData}
                                                        placeholder="Job Name"
                                                    />

                                                    <input
                                                        type="text"
                                                        name="jobAddress1"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={jobData.jobAddress1}
                                                        onChange={handleChangeJobData}
                                                        placeholder="Address"
                                                    />

                                                    <div className="flex gap-2">
                                                        <input
                                                            type="text"
                                                            name="jobCity"
                                                            className="w-full sales-input"
                                                            disabled={orderStatus === 2}
                                                            value={jobData.jobCity}
                                                            onChange={handleChangeJobData}
                                                            placeholder="City"
                                                        />

                                                        <input
                                                            type="text"
                                                            name="jobState"
                                                            className="w-24 sales-input"
                                                            disabled={orderStatus === 2}
                                                            value={jobData.jobState}
                                                            onChange={handleChangeJobData}
                                                            placeholder="State"
                                                        />

                                                        <input type="number" name="jobZip"
                                                            className="w-16 sales-input"
                                                            disabled={orderStatus === 2}
                                                            value={jobData.jobZip}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 5) {
                                                                    handleChangeJobData(e)
                                                                }
                                                            }}
                                                            required={orderData.taxLocationStoreId === 'Job Address'}
                                                            placeholder="Zip"
                                                            maxLength="5"
                                                        />
                                                    </div>

                                                    <input type="text" name="jobNotes"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={orderData?.jobNotes}
                                                        onChange={handleChangeOrderData}
                                                        placeholder="Detail"
                                                    />

                                                    <input
                                                        type="text"
                                                        name="poNumber"
                                                        className="w-full mb-2 sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={orderData?.poNumber}
                                                        onChange={handleChangeOrderData}
                                                        placeholder="PO"
                                                    />
                                                </div>
                                            }

                                            {IsFalse(isClaim()) &&
                                                <div>

                                                    <h2 className="font-bold text-lg text-secondary pb-1">Tax Status</h2>
                                                    <select className="w-full border-[#cccccc]  rounded px-2 py-1 cursor-pointer"
                                                        name="taxTypeId"
                                                        disabled={orderStatus === 2}
                                                        value={orderData.taxTypeId}
                                                        onChange={handleChangeOrderData}
                                                        required >
                                                        <option value={0}>-- Select --</option>
                                                        <option value={1}>Taxable</option>
                                                        <option value={2}>Exempt</option>
                                                        <option value={3}>Resale</option>
                                                    </select>
                                                </div>
                                            }
                                            {IsTrue(isClaim()) &&
                                                <div className="grid grid-cols-1 gap-2">
                                                    <input type="text" name="jobNotes"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={orderData?.jobNotes}
                                                        onChange={handleChangeOrderData}
                                                        placeholder="Detail"
                                                    />
                                                </div>
                                            }

                                        </div>

                                        <div className="w-1/2">
                                            <h2 className="font-bold text-lg text-secondary pb-1">Details</h2>
                                            <div className="grid grid-cols-1 gap-2">

                                                {IsFalse(isClaim()) &&
                                                    <input type="text" name="jobPlacedByName"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={jobData.jobPlacedByName}
                                                        onChange={handleChangeJobData}
                                                        placeholder="Placed by Name"
                                                    />
                                                }
                                                {IsFalse(isClaim()) &&

                                                    <input type="text" name="jobPlacedByPhone"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        onKeyDown={e => enforcePhoneFormat(e)}
                                                        value={jobData.jobPlacedByPhone}
                                                        onChange={(e) => setJobData(prevData => ({
                                                            ...prevData,
                                                            jobPlacedByPhone: formatToPhone(e)
                                                        }))}
                                                        placeholder="Placed by Phone"
                                                    />
                                                }
                                                {IsFalse(isClaim()) &&
                                                    <input type="email" name="jobPlacedByEmail"
                                                        className="w-full sales-input"
                                                        disabled={orderStatus === 2}
                                                        value={jobData.jobPlacedByEmail}
                                                        onChange={handleChangeJobData}
                                                        onBlur={(e) => handleAddEmail(e.target.value)}
                                                        placeholder="Placed by Email"
                                                    />
                                                }


                                                <select className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                    name="salesperson1Id"
                                                    disabled={orderStatus === 2}
                                                    value={orderData?.salesperson1Id}
                                                    onChange={handleChangeOrderData}
                                                >
                                                    <option value="0">-- Salesperson 1 --</option>
                                                    {userList}
                                                </select>

                                                <select className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                                    name={"salesperson2Id"}
                                                    disabled={orderStatus === 2}
                                                    value={orderData?.salesperson2Id}
                                                    onChange={handleChangeOrderData}
                                                >
                                                    <option value="0">-- Salesperson 2 --</option>
                                                    {userList}
                                                </select>

                                                {IsFalse(isClaim()) &&
                                                    <div>
                                                        <h2 className="font-bold text-lg text-secondary pb-1">Tax Location</h2>                                                        
                                                        <select id="taxLocationStoreId" name="taxLocationStoreId"
                                                            className={`w-full border-[#cccccc] rounded 
                                                                ${(Number(orderData.taxTypeId) === 0 ||
                                                                    Number(orderData.taxTypeId) === 2 ||
                                                                    Number(orderData.taxTypeId) === 3)  && "bg-gray"}  px-2 py-1 cursor-pointer`}
                                                            disabled={Number(orderData.taxTypeId) === 0 || 
                                                                Number(orderData.taxTypeId) === 2 ||
                                                                Number(orderData.taxTypeId) === 3 ||
                                                                orderStatus === 2}
                                                            value={orderData.taxLocationStoreId}
                                                            onChange={handleChangeOrderData}
                                                        >
                                                            <option value={0}></option>                                                            
                                                            {taxStores.map((store, index) => (
                                                                <option key={index} value={store.value}>{store.text}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {IsFalse(isClaim()) &&
                                    <div className="w-full">
                                        <h2 className="font-bold text-lg text-secondary pb-1">Material Arrival Notifications</h2>
                                        <div className="flex gap-4 w-full">

                                            <div className="w-1/2">
                                                <div className="grid grid-cols-1 gap-2 w-full">
                                                    <div className="flex items-center gap-2">
                                                        <input type="email"
                                                            className="w-full sales-input"
                                                            disabled={orderStatus === 2}
                                                            value={materialEmailInput}
                                                            onChange={(e) => setMaterialEmailInput(e.target.value)}
                                                            onKeyUp={(e) => CheckEnterKeyPress(e, handleAddEmail)}
                                                            placeholder="Email"
                                                        />
                                                        <button type="button"
                                                            className="px-2 py-1 bg-secondary text-white rounded text-sm"
                                                            disabled={orderStatus === 2}
                                                            onClick={e => handleAddEmail()}
                                                        >+</button>
                                                    </div>

                                                    <div className="text-primary pl-[1px] pr-[7px]">
                                                        {materialEmailsToShow.map((email, index) => (
                                                            <div key={index} className="flex items-center justify-between">
                                                                <span>{email}</span>
                                                                <button type="button"
                                                                    className="text-red text-sm font-bold"
                                                                    disabled={orderStatus === 2}
                                                                    onClick={() => handleRemoveEmail(email)}
                                                                >✕</button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-1/2">
                                                <div className="grid grid-cols-1 gap-2 w-full">
                                                    <div className="flex items-center gap-2">

                                                        <input type="text" className="w-full border-[#cccccc] rounded px-2 py-1"
                                                            disabled={orderStatus === 2}
                                                            onKeyDown={(e) => enforcePhoneFormat(e)}
                                                            onKeyUp={(e) => CheckEnterKeyPress(e, handleAddPhone)}
                                                            value={materialPhoneInput}
                                                            onChange={(e) => setMaterialPhoneInput(formatToPhone(e))}
                                                            placeholder="Phone / Text"
                                                        />
                                                        <button type="button"
                                                            className="px-2 py-1 bg-secondary text-white rounded text-sm"
                                                            disabled={orderStatus === 2}
                                                            onClick={handleAddPhone}
                                                        >+</button>
                                                    </div>
                                                    <div className="text-primary pl-[1px] pr-[7px]">
                                                        {materialPhonesToShow.map((phone, index) => (
                                                            <div key={index} className="flex items-center justify-between">
                                                                <span>{phone}</span>
                                                                {
                                                                    <button type="button"
                                                                        className="text-red text-sm font-bold"
                                                                        disabled={orderStatus === 2}
                                                                        onClick={() => handleRemovePhone(phone)}
                                                                    >✕</button>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* Error or Success Message */}
                            {message && (
                                <div className={'p-2 rounded-md bg-red-100 text-red-700 my-4'}>
                                    {message}
                                </div>
                            )}
                            <div className="text-right mt-2">
                                <button type="button" className="btn-cancel" onClick={props.onCloseModal}>
                                    Cancel
                                </button>
                                {NotEquals(orderStatus, 2) &&
                                    <button type="button" className="btn-submit" onClick={(e) => saveAndCloseModal(e)}>
                                        Save & Close
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal open={openAccountModal} className="w-auto max-w-full max-h-full pt-4" style={{ zIndex: 1000 }}>
                <Modal.Body>
                    <AddAccountModal
                        orderId={orderId} isClaim={isClaim()} isQuote={isQuote()}
                        onCloseModal={onCloseAccountModal} setOpenAddAccountModal={setOpenAccountModal}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}